import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Modal, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";

class PromoterRoute extends React.Component {
    constructor(props) {
        super(props);
        const queryParameters = new URLSearchParams(window.location.search)
        let message = queryParameters.get("m")
        let permissionKey = "manage_promoter_routes"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            clientsData: [],
            columns: [],
            editPromoterRouteForm: false,
            commonAlert: !!message,
            commonAlertVariant: message ?? "",
            commonAlertMessage: message ? i18next.t("promoter_route_saved_successfully") : "",
            handleDeletePopup: false,
            dataLoader: true,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.handleDeletePromoterRoute = this.handleDeletePromoterRoute.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        this.loadAllPromoterRoutes();
    }

    loadAllPromoterRoutes() {
        let formData = {
            client_uuid: this.state.sessionData.uuid,
        }
        PostData("/frontCall/promoter_routes/get_all_promoter_routes", formData)
            .then(result => {
                this.setState({
                    clientsData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("title")}</h6>,
                            cell: (row) => row.title,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("start_location_name")}</h6>,
                            cell: (row) => JSON.parse(row.path_array)[0].address,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("end_location_name")}</h6>,
                            cell: (row) => {
                                let parsedData = JSON.parse(row.path_array);
                                return parsedData[parsedData.length - 1].address;
                            },
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("stop_points")}</h6>,
                            cell: (row) => JSON.parse(row.path_array).length,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("route_url")}</h6>,
                            cell: (row) => <a href={row.maps_url} target={"_blank"} rel="noreferrer">{i18next.t("open")}</a>,
                            sortable: false
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("created_on")}</h6>,
                            cell: (row) => row.created,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">
                                {(this.state.granularPermissions.includes('u'))
                                    ? i18next.t("edit") : i18next.t("view")}
                            </h6>,
                            cell: (data) => <Link to={"/edit_promoter_route?route_uuid=" + data.uuid} className="btn btn-primary my-2">
                                <em className={(this.state.granularPermissions.includes('u'))
                                    ? "flaticon-381-edit" : "flaticon-381-view-2"}/>
                            </Link>
                        },
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    deletingUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>
                        }] : [],
                    ],
                })
            })
    }

    handleClose() {
        this.setState({
            handleDeletePopup: false,

        })
    }

    handleDeletePromoterRoute() {
        PostData("/frontCall/promoter_routes/removePromoterRoute", {uuid: this.state.deletingUuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.handleClose()
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("promoter_route_removed_successfully"),
                    })
                    this.loadAllPromoterRoutes()
                }
            })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Link to={"/add_new_promoter_route"} className="btn btn-primary mb-2">
                                {i18next.t("add_promoter_route")}
                            </Link>
                        </div>}
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose} centered backdrop="static"
                               keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("sure_to_delete_promoter_route")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.handleDeletePromoterRoute}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                               className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.clientsData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default PromoterRoute;