import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'
import i18next from 'i18next'

const lang = document.cookie.split('; ').find((row) => row.startsWith('lang=')) || "ru";
i18next.changeLanguage(lang.replace('lang=', ''))
const root = ReactDOM.createRoot(document.getElementById('main-wrapper'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
