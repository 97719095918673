export function LoginData(type, loginDetails) {
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginDetails)
        };
        fetch("/admin/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                // Check if the API response contains tokens
                if(responseJSON.status === true) {
                    let response = responseJSON.response;
                    if (response.accessToken && response.refreshToken) {
                        // Store tokens securely in localStorage
                        localStorage.setItem('accessToken', response.accessToken);
                        localStorage.setItem('refreshToken', response.refreshToken);
                        localStorage.setItem('sharedKey', response.sharedKey);
                    }
                }
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}