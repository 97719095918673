import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

class Promoters extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_promoters"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            promoterData: [],
            columns: [],
            commonAlert: false,
            commonAlertVariant: "",
            commonAlertMessage: "",
            newPromoterAlert: {
                show: false,
                variant: "",
                message: ""
            },
            assignAlert: {
                show: false,
                variant: "",
                message: "",
            },
            handleDeletePopup: false,
            addNewPromoterModal: false,
            editPromoterForm: false,
            dataLoader: true,
            inlinePreloader: false,
            fullName: "",
            fullNameErr: "",
            email: "",
            emailErr: "",
            phone: "",
            phoneErr: "",
            telegram: "",
            telegramErr: "",
            hourlyRate: "",
            hourlyRateErr: "",
            password: "",
            passwordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
            assignPromoterModal: false,
            backpackOptions: [],
            selectedBackpackOption: "",
            selectedBackpackOptionErr: "",
            startDate: "",
            startDateErr: "",
            endDate: "",
            endDateErr: "",
            salaryStartDate: "",
            salaryStartDateErr: "",
            salaryEndDate: "",
            salaryEndDateErr: "",
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.handleDeletePromoter = this.handleDeletePromoter.bind(this)
        this.addNewPromoter = this.addNewPromoter.bind(this)
        this.showHidePassword = this.showHidePassword.bind(this)
        this.showAssignedDevices = this.showAssignedDevices.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        PostData('/frontCall/devices/getAll')
            .then((result) => {
                let backpackOptions = result.response.map(x => ({'value': x.uuid, 'label': x.device_name}));
                this.setState({backpackOptions: backpackOptions})
            })

        this.loadAllPromoterRoutes();
    }

    loadAllPromoterRoutes() {
        let formData = {
            uuid: this.state.sessionData.uuid,
        }
        PostData("/frontCall/promoters/get_all", formData)
            .then(result => {
                this.setState({
                    promoterData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("full_name")}</h6>,
                            cell: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("email")}</h6>,
                            cell: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("phone")}</h6>,
                            cell: (row) => row.phone,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("telegram")}</h6>,
                            cell: (row) => row.telegram_handle,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("hourly_rate")}</h6>,
                            cell: (row) => row.hourly_rate,
                            sortable: false
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("calculate_salary")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    calculateSalaryModal: true,
                                    salaryUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-database"/>
                            </Button>,
                            center: true,
                        },
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("assign")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    uuid: row.uuid,
                                    assignPromoterModal: true,
                                })
                                this.showAssignedDevices(row.uuid)
                            }}>
                                <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 300 200" style={{width: "20px"}}>
                                    <path style={{fill: "#ffffff"}}
                                        d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z"
                                        transform="translate(-93 -126.49)"/>
                                    <path style={{fill: "#ffffff"}}
                                        d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z"
                                        transform="translate(-93 -126.49)"/>
                                    <path className="cls-2" style={{fill: "#0B2A97"}}
                                          d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z"
                                          transform="translate(-93 -126.49)"/>
                                    <circle className="cls-2" style={{fill: "#0B2A97"}} cx="168.47" cy="102.83"
                                            r="51.59"/>
                                    <path style={{fill: "#ffffff"}}
                                        d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z"
                                        transform="translate(-93 -126.49)"/>
                                    <circle style={{fill: "#ffffff"}} cx="168.47" cy="91.49" r="27.78"/>
                                    <circle className="cls-2" style={{fill: "#0B2A97"}} cx="157.7" cy="70.51"
                                            r="9.07"/>
                                    <circle className="cls-1" style={{fill: "#ff1d25"}} cx="178.11" cy="76.18"
                                            r="4.54"/>
                                </svg>
                            </Button>,
                            center: true,
                        }] : [],
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    editPromoterForm: true,
                                    addNewPromoterModal: true,
                                    uuid: row.uuid,
                                    fullName: row.full_name,
                                    email: row.email,
                                    phone: row.phone,
                                    telegram: row.telegram_handle,
                                    hourlyRate: row.hourly_rate,
                                })
                            }}>
                                <em className={"flaticon-381-edit"}/>
                            </Button>,
                            center: true,
                        }] : [],
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    deletingUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>,
                            center: true,
                        }] : []
                    ],
                })
            })
    }

    addNewPromoter() {
        if (this.state.fullName === "") {
            this.setState({fullNameErr: i18next.t("enter_full_name")})
        } else {
            this.setState({fullNameErr: ""})
        }
        if (this.state.email === "") {
            this.setState({emailErr: i18next.t("enter_email")})
        } else if (!this.state.email.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({emailErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({emailErr: ""})
        }
        if (this.state.phone === "") {
            this.setState({phoneErr: i18next.t("enter_phone")})
        } else {
            this.setState({phoneErr: ""})
        }
        if (this.state.telegram === "") {
            this.setState({telegramErr: i18next.t("enter_telegram")})
        } else {
            this.setState({telegramErr: ""})
        }
        if (this.state.hourlyRate === "") {
            this.setState({hourlyRateErr: i18next.t("enter_hourly_rate")})
        } else {
            this.setState({hourlyRateErr: ""})
        }
        /*if (this.state.password === "") {
            this.setState({passwordErr: i18next.t("enter_password")})
        } else {
            this.setState({passwordErr: ""})
        }
        if (this.state.confirmPassword === "") {
            this.setState({confirmPasswordErr: i18next.t("enter") + " " + i18next.t("confirm_password")})
        } else if (this.state.confirmPassword !== this.state.password) {
            this.setState({confirmPasswordErr: i18next.t("new_and_confirm_are_same")})
        } else {
            this.setState({confirmPasswordErr: ""})
        }*/

        let formData = {
            full_name: this.state.fullName,
            email: this.state.email,
            phone: this.state.phone,
            telegram_handle: this.state.telegram,
            hourly_rate: this.state.hourlyRate,
            password: this.state.password,
            confirm_password: this.state.confirmPassword,
        }

        if (this.state.editPromoterForm) {formData.uuid = this.state.uuid;}

        if (this.state.fullName !== "" && this.state.email !== "" && this.state.phone !== "" &&
            this.state.telegram !== "" && this.state.hourlyRate !== "" /*&& this.state.password !== "" &&
            this.state.confirmPassword !== "" && this.state.confirmPassword === this.state.password*/ ) {
            this.setState({inlinePreloader: true})
            let postApi = this.state.editPromoterForm ? "/frontCall/promoters/update" : "/frontCall/promoters/add";
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newPromoterAlert: {
                                show: false,
                                variant: "",
                                message: ""
                            },
                            commonAlert: true,
                            commonAlertVariant: "success",
                            commonAlertMessage: result.response
                        })
                        this.loadAllPromoterRoutes()
                        this.handleClose()
                        setTimeout(() => this.setState({commonAlert: false}), 5000);
                    } else {
                        this.setState({
                            newPromoterAlert: {
                                show: true,
                                variant: "danger",
                                message: result.messages.message
                            }
                        })
                    }
                    this.setState({inlinePreloader: false})
                })
        }

    }

    showHidePassword(controlId) {
        let password = document.querySelector(controlId)
        let iconId = document.querySelector("[data-iconId='"+controlId+"']")
        if(password.type === "password") {
            password.type = "text"
            iconId.classList.remove("flaticon-381-view-2")
            iconId.classList.add("flaticon-381-hide")
        } else {
            password.type = "password"
            iconId.classList.remove("flaticon-381-hide")
            iconId.classList.add("flaticon-381-view-2")
        }
    }

    handleDeletePromoter() {
        this.setState({inlinePreloader: true})
        PostData("/frontCall/promoters/remove", {uuid: this.state.deletingUuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.handleClose()
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("promoter_route_removed_successfully"),
                    })
                    this.loadAllPromoterRoutes()
                }
                this.setState({inlinePreloader: false})
            })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    showAssignedDevices(showUuid) {
        PostData("/frontCall/promoters/assigned_devices", {uuid: showUuid})
            .then(result => {
                this.setState({
                    assignedPromoterData: result.response,
                    assignedPromoterColumn: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("device_name_map")}</h6>,
                            selector: (row) => row.device_name,
                            sortable: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("start")}</h6>,
                            selector: (row) => row.start_datetime,
                            sortable: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("end")}</h6>,
                            selector: (row) => row.end_datetime,
                            sortable: true,
                        },
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleAssignedDeleteModal: true,
                                    deletingUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>,
                            center: true,
                        }] : []
                    ],
                    userDataPreloader: false,
                })
            })
    }

    handleClose() {
        this.setState({
            editPromoterForm: false,
            handleDeletePopup: false,
            fullName: "",
            fullNameErr: "",
            email: "",
            emailErr: "",
            phone: "",
            phoneErr: "",
            telegram: "",
            telegramErr: "",
            hourlyRate: "",
            hourlyRateErr: "",
            password: "",
            passwordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
            newPromoterAlert: {
                show: false,
                variant: "",
                message: ""
            },
            assignAlert: {
                show: false,
                variant: "",
                message: "",
            },
            addNewPromoterModal: false,
            assignPromoterModal: false,
            selectedBackpackOption: "",
            selectedBackpackOptionErr: "",
            startDate: "",
            startDateErr: "",
            endDate: "",
            endDateErr: "",
            salaryStartDate: "",
            salaryStartDateErr: "",
            salaryEndDate: "",
            salaryEndDateErr: "",
            calculateSalaryModal: false,
            assignedPromoterData: [],
            salaryCalculation: "",
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2" onClick={() => {
                                this.setState({addNewPromoterModal: true})
                            }}>{i18next.t("add_promoter")}</Button>
                        </div>}
                        <Modal show={this.state.addNewPromoterModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editPromoterForm ? i18next.t("edit") + " " + i18next.t("promoters") : i18next.t("add_promoter")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <Alert show={this.state.newPromoterAlert.show}
                                           variant={this.state.newPromoterAlert.variant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.newPromoterAlert.message}</h6>
                                    </Alert>}
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>{i18next.t("full_name")}</Form.Label>
                                                <Form.Control type="text" name="fullName"
                                                              value={this.state.fullName}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("full_name")}/>
                                                {this.state.fullNameErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.fullNameErr}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>{i18next.t("email")}</Form.Label>
                                                <Form.Control type="text" name="email"
                                                              value={this.state.email}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("email")}/>
                                                {this.state.emailErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.emailErr}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="phone">
                                                <Form.Label>{i18next.t("phone")}</Form.Label>
                                                <Form.Control type="text" name="phone"
                                                              value={this.state.phone}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("phone")}/>
                                                {this.state.phoneErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.phoneErr}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="telegram">
                                                <Form.Label>{i18next.t("telegram")}</Form.Label>
                                                <Form.Control type="text" name="telegram"
                                                              value={this.state.telegram}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("telegram")}/>
                                                {this.state.telegramErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.telegramErr}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="hourlyRate">
                                                <Form.Label>{i18next.t("hourly_rate")}</Form.Label>
                                                <Form.Control type="text" name="hourlyRate"
                                                              value={this.state.hourlyRate}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("hourly_rate")}/>
                                                {this.state.hourlyRateErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.hourlyRateErr}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col style={{display: "none"}} xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Label>{i18next.t("password")}</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Control type="password" name="password"
                                                                  value={this.state.password}
                                                                  onChange={this.onChangeInput}
                                                                  placeholder={i18next.t("password")}/>
                                                    <Button variant={"primary"} className={"ml-1"} onClick={() => {
                                                        this.showHidePassword("#password")
                                                    }}><i data-iconId={"#password"} className="flaticon-381-view-2"></i></Button>
                                                </div>
                                                {this.state.passwordErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1 d-block">{this.state.passwordErr}</span>}
                                                <Button variant={"primary"} className={"mt-2"} onClick={() => {
                                                    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                                                    let passwordLength = 8;
                                                    let password = "";
                                                    for (let i = 0; i <= passwordLength; i++) {
                                                        let randomNumber = Math.floor(Math.random() * chars.length);
                                                        password += chars.substring(randomNumber, randomNumber + 1);
                                                    }
                                                    this.setState({password: password, confirmPassword: password,})
                                                }}>{i18next.t("generate_password")}</Button>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{display: "none"}} xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="confirmPassword">
                                                <Form.Label>{i18next.t("confirm_password")}</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Control type="password" name="confirmPassword"
                                                                  value={this.state.confirmPassword}
                                                                  onChange={this.onChangeInput}
                                                                  placeholder={i18next.t("confirm_password")}/>
                                                    <Button variant={"primary"} className={"ml-1"} onClick={() => {
                                                        this.showHidePassword("#confirmPassword")
                                                    }}><i data-iconId={"#confirmPassword"}
                                                          className="flaticon-381-view-2"></i></Button>
                                                </div>
                                                {this.state.confirmPasswordErr &&
                                                    <span
                                                        className="text-danger pl-3 pt-1">{this.state.confirmPasswordErr}</span>}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} onClick={() => {
                                    this.addNewPromoter()
                                }}>{i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <Modal.Title>{i18next.t("sure_to_delete_promoter")}</Modal.Title>}
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    this.handleDeletePromoter()
                                }}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.assignPromoterModal} onHide={this.handleClose}
                               centered backdrop={"static"} keyboard={false} size={"xl"}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("assign_device_to_promoter")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{backgroundColor: "#F9F9F9"}}>
                                <Alert show={this.state.assignAlert.show} variant={this.state.assignAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.assignAlert.message}</h6>
                                </Alert>
                                <Row>
                                    {(this.state.granularPermissions.includes('c')) && <><Col xs={12} md={3}>
                                        <Form.Group className="mb-3" controlId="deviceUuid">
                                            <Form.Label>{i18next.t("select_device")}</Form.Label>
                                            <Select value={this.state.selectedBackpackOption}
                                                    onChange={(selectedOption) => {
                                                        this.setState({selectedBackpackOption: selectedOption})
                                                    }}
                                                    options={this.state.backpackOptions}
                                            />
                                            {this.state.selectedBackpackOptionErr &&
                                                <span
                                                    className="text-danger pl-3 pt-1">{this.state.selectedBackpackOptionErr}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" controlId="startDate">
                                            <Form.Label>{i18next.t("start")}</Form.Label>
                                            <DatePicker className="form-control"
                                                        selected={this.state.startDate}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        placeholderText={"dd/mm/yyyy HH:MM"}
                                                        timeFormat="HH:mm"
                                                        showTimeSelect
                                                        onChange={(date) => this.setState({startDate: date})}
                                            />
                                            {this.state.startDateErr &&
                                                <span className="text-danger pl-3 pt-1">{this.state.startDateErr}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" controlId="endDate">
                                            <Form.Label>{i18next.t("end")}</Form.Label>
                                            <DatePicker className="form-control"
                                                        selected={this.state.endDate}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        placeholderText={"dd/mm/yyyy HH:MM"}
                                                        timeFormat="HH:mm"
                                                        showTimeSelect
                                                        onChange={(date) => this.setState({endDate: date})}
                                            />
                                            {this.state.endDateErr &&
                                                <span className="text-danger pl-3 pt-1">{this.state.endDateErr}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3} className={"align-self-end"}>
                                        <Form.Group className="mb-3">
                                            <Button variant={"primary"} className={"w-100"} onClick={() => {
                                                this.setState({inlinePreloader: true})
                                                let formData = {
                                                    uuid: this.state.uuid,
                                                    device_uuid: this.state.selectedBackpackOption.value,
                                                    start_datetime: moment(this.state.startDate).format("yyyy-MM-DD HH:mm:ss"),
                                                    end_datetime: moment(this.state.endDate).format("yyyy-MM-DD HH:mm:ss"),
                                                }
                                                PostData("/frontCall/promoters/assign", formData)
                                                    .then(result => {
                                                        if (result.status === true) {
                                                            this.setState({
                                                                assignAlert: {
                                                                    show: true,
                                                                    variant: "success",
                                                                    message: result.response,
                                                                }
                                                            })
                                                            setTimeout(() => {
                                                                this.setState({assignAlert: {show: false}})
                                                            }, 2000);
                                                            this.showAssignedDevices(this.state.uuid)
                                                        } else if (result.status === 400) {
                                                            this.setState({
                                                                assignAlert: {
                                                                    show: true,
                                                                    variant: "danger",
                                                                    message: result.messages.message,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState({
                                                                assignAlert: {
                                                                    show: true,
                                                                    variant: "danger",
                                                                    message: result.messages.message,
                                                                }
                                                            })
                                                        }
                                                        this.setState({inlinePreloader: false})
                                                    })
                                            }}>{i18next.t("assign")}</Button>
                                        </Form.Group>
                                    </Col></>}
                                    <Col xs={12}>
                                        <Card>
                                            <Card.Body>
                                                {this.state.inlinePreloader ?
                                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                                    <DataTable
                                                        columns={this.state.assignedPromoterColumn}
                                                        data={this.state.assignedPromoterData}
                                                        paginationComponentOptions={{ rowsPerPageText: i18next.t("rows_per_page")}}
                                                        pagination
                                                        noDataComponent={i18next.t("no_records_to_display")}
                                                    />}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleAssignedDeleteModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <Modal.Title>{i18next.t("sure_to_delete_assigned_device")}</Modal.Title>}
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    this.setState({inlinePreloader: true})
                                    PostData("/frontCall/promoters/unassign", {uuid: this.state.deletingUuid})
                                        .then(result => {
                                            if (result.status === 400) {
                                                this.setState({
                                                    assignAlert: {
                                                        show: true,
                                                        variant: "danger",
                                                        message: result.messages.message,
                                                    },
                                                })
                                            } else if (result.status === true) {
                                                this.setState({
                                                    assignAlert: {
                                                        show: true,
                                                        variant: "success",
                                                        message: result.response,
                                                    },
                                                    handleAssignedDeleteModal: false,
                                                })
                                                this.showAssignedDevices(this.state.uuid)
                                            }
                                            this.setState({inlinePreloader: false})
                                        })
                                }}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({handleAssignedDeleteModal: false})
                                }}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.calculateSalaryModal} onHide={this.handleClose}
                               centered backdrop={"static"} keyboard={false} size={"xl"}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("calculate_salary")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{backgroundColor: "#F9F9F9"}}>
                                <Alert show={this.state.assignAlert.show} variant={this.state.assignAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.assignAlert.message}</h6>
                                </Alert>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="mb-3" controlId="salaryStartDate">
                                            <Form.Label>{i18next.t("start")}</Form.Label>
                                            <DatePicker className="form-control"
                                                        selected={this.state.salaryStartDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText={"dd/mm/yyyy"}
                                                        onChange={(date) => this.setState({salaryStartDate: date})}
                                            />
                                            {this.state.salaryStartDateErr &&
                                                <span className="text-danger pl-3 pt-1">{this.state.salaryStartDateErr}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="mb-3" controlId="salaryEndDate">
                                            <Form.Label>{i18next.t("end")}</Form.Label>
                                            <DatePicker className="form-control"
                                                        selected={this.state.salaryEndDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText={"dd/mm/yyyy"}
                                                        onChange={(date) => this.setState({salaryEndDate: date})}
                                            />
                                            {this.state.salaryEndDateErr &&
                                                <span className="text-danger pl-3 pt-1">{this.state.salaryEndDateErr}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} className={"align-self-end"}>
                                        <Form.Group className="mb-3">
                                            <Button variant={"primary"} className={"w-100"} onClick={() => {
                                                if (this.state.salaryStartDate === "") {
                                                    this.setState({salaryStartDateErr: i18next.t("start_date")})
                                                } else {
                                                    this.setState({salaryStartDateErr: ""})
                                                }
                                                if (this.state.salaryEndDate === "") {
                                                    this.setState({salaryEndDateErr: i18next.t("end_date")})
                                                } else {
                                                    this.setState({salaryEndDateErr: ""})
                                                }

                                                let formData = {
                                                    uuid: this.state.salaryUuid,
                                                    start_date: moment(this.state.salaryStartDate).format("yyyy-MM-DD"),
                                                    end_date: moment(this.state.salaryEndDate).format("yyyy-MM-DD"),
                                                }
                                                if (this.state.salaryStartDate !== "" && this.state.salaryEndDate !== "") {
                                                    this.setState({inlinePreloader: true})
                                                    PostData("/frontCall/promoters/salary", formData)
                                                        .then(result => {
                                                            if (result.status === true) {
                                                                this.setState({
                                                                    salaryCalculation: <>
                                                                        <Card className={"overflow-hidden mx-auto mb-0 h-100"} style={{maxWidth: "320px"}}>
                                                                            <div className="text-center p-3 overlay-box">
                                                                                <h3 className="m-0 text-white">{i18next.t("salary")}</h3>
                                                                            </div>
                                                                            <h2 className={"my-2 text-success"}>
                                                                                <em className="flaticon-381-database"/>
                                                                            </h2>
                                                                            <h2 className={"m-0 pb-3"}>
                                                                                {(result.response.salary === null) ? 0 : result.response.salary}
                                                                            </h2>
                                                                        </Card>
                                                                    </>,
                                                                    assignAlert: {
                                                                        show: false,
                                                                        variant: "",
                                                                        message: "",
                                                                    },
                                                                    salaryStartDate: "",
                                                                    salaryEndDate: "",
                                                                })
                                                            } else if (result.status === 400) {
                                                                this.setState({
                                                                    assignAlert: {
                                                                        show: true,
                                                                        variant: "danger",
                                                                        message: result.messages.message,
                                                                    }
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    assignAlert: {
                                                                        show: true,
                                                                        variant: "danger",
                                                                        message: result.messages.message,
                                                                    }
                                                                })
                                                            }
                                                            this.setState({inlinePreloader: false})
                                                        })
                                                }
                                            }}>{i18next.t("calculate_salary")}</Button>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className={"text-center"}>
                                        {this.state.inlinePreloader
                                            && <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/>}
                                        {this.state.salaryCalculation}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {this.state.inlinePreloader ?
                                            <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                            (this.state.granularPermissions.includes('r')) && <DataTable
                                                columns={this.state.columns}
                                                data={this.state.promoterData}
                                                noDataComponent={i18next.t("no_records_to_display")}
                                            />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Promoters;