import React from 'react'
import './style.css';
import $ from "jquery";
import i18next from "i18next"

class Tariffs extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("light-page").removeClass("dark-page")
        $("#main-sections").attr("style", "padding-top: " + (document.getElementById("sticky-top").offsetHeight + 16) + "px")
        $("#sticky-top").addClass("sticky-top")
        $(".header-bar").removeClass("active-nav").addClass("container")
        $(".burger-menu").removeClass("pointer-opacity")
    }

    render() {
        return (
            <section id="tariffs" className="section tariffs-section container">
                <h1 className="about-format-title tariffs-title">{i18next.t("tariffs")}</h1>
                <div className="tariffs-plan-content">
                    <div className="first-tariffs-section">
                        <h5 className="tariffs-plans-title">{i18next.t("minimum")}</h5>
                        <ul className="tariffs-plans-detail">
                            <li className="tariffs-plans-detail-item">{i18next.t("from_1_backpack")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("from_4_hours")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("impressions_from_1_day")}</li>
                        </ul>
                    </div>
                    <div className="main-tariffs-section">
                        <h5 className="tariffs-plans-title">{i18next.t("base")}</h5>
                        <ul className="tariffs-plans-detail">
                            <li className="tariffs-plans-detail-item">{i18next.t("from_3_backpacks")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("from_6_hours_of_impressions")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("from_3_days")}</li>
                        </ul>
                    </div>
                    <div className="last-tariffs-section">
                        <h5 className="tariffs-plans-title">{i18next.t("maximum")}</h5>
                        <ul className="tariffs-plans-detail">
                            <li className="tariffs-plans-detail-item">{i18next.t("from_5_backpacks")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("from_4_hours_of_impressions")}</li>
                            <li className="tariffs-plans-detail-item">{i18next.t("from_5_days")}</li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default Tariffs;