import {decryptData, encryptData} from "../Utils/cryptoUtils";
import {v4 as uuid} from 'uuid';

const MAX_UPLOAD_SIZE_BYTES = 90 * 1024 * 1000;//90MB

async function uploadChunksIfNeeded(frmData, headers, type, requestOptions, reject) {
    if (frmData instanceof FormData) {
        //Check if any file is there in the upload.
        for (const pair of frmData.entries()) {
            if (pair[1] instanceof File) {
                //given object is file. 
                let file = pair[1];
                if (file.size > MAX_UPLOAD_SIZE_BYTES) {
                    let start = 0;
                    let end = MAX_UPLOAD_SIZE_BYTES;
                    let chunks = [];
                    let chunkName = uuid();
                    while (start < file.size) {
                        let chunk = file.slice(start, end);
                        chunks.push(chunk);
                        start = end;
                        end = start + MAX_UPLOAD_SIZE_BYTES;
                    }
                    for (const chunk of chunks) {
                        const index = chunks.indexOf(chunk);
                        let formData = new FormData();
                        formData.append('chunk_name', chunkName);
                        formData.append('file', chunk);
                        formData.append('name', file.name);
                        formData.append('index', index.toString());
                        formData.append('size', chunks.length);
                        const chunkRequestOptions = {
                            method: 'POST',
                            headers: headers,
                            body: encryptData(formData)
                        };
                        await fetch("/admin/api/" + type, chunkRequestOptions)
                            .then((response) => checkForAuthorization(response, requestOptions, type, reject))
                            .catch((error) => {
                                reject(error)
                            })
                    }
                    frmData.delete(pair[0]);//Remove the file from the actual request.
                    frmData.append(pair[0], new Blob());//Add empty blob to bypass is_uploaded_file hack.
                    frmData.append('chunk_' + pair[0], chunkName);//Append the chunked file name.
                }
            }
        }
    }
}

async function processRequest(headers, encryptedData, type, reject, resolve) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: encryptedData
    };
    await uploadChunksIfNeeded(encryptedData, headers, type, requestOptions, reject);
    fetch("/admin/api/" + type, requestOptions)
        .then((response) => checkForAuthorization(response, requestOptions, type, reject))
        .then((responseJSON) => {
            resolve(responseJSON)
        })
        .catch((error) => {
            reject(error)
        })
}

function handleRequest(formData, headers, type, reject, resolve, isRaw = false) {
    if (formData != null && !isRaw) {
        let encryptedPayload = encryptData(formData);
        processRequest(headers, encryptedPayload, type, reject, resolve);
    } else {
        if (formData != null) {
            formData = JSON.stringify(formData);
        }
        processRequest(headers, formData, type, reject, resolve);
    }
}

export function PostData(type, formData) {
    return new Promise((resolve, reject) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Add Authorization header if needed
        }
        handleRequest(formData, headers, type, reject, resolve);
    })
}

export function PostDataRaw(type, formData) {
    return new Promise((resolve, reject) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        handleRequest(formData, headers, type, reject, resolve, true);
    })
}

export function uploadFiles(type, formData) {
    return new Promise((resolve, reject) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Add Authorization header if needed
        };
        handleRequest(formData, headers, type, reject, resolve);
    })
}

export function checkForAuthorization(response, requestOptions, type, reject) {
    return response.text().then(data => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            data = JSON.parse(decryptData(data));
            if (data.error && data.error === 401) {
                // Token expired, attempt to refresh the access token using refreshToken function
                return refreshToken()
                    .then(newAccessToken => {
                        // Update the authorization header with the new access token
                        requestOptions.headers.Authorization = `Bearer ${newAccessToken}`;
                        // Retry the original request with the new access token
                        return fetch("/admin/api/" + type, requestOptions)
                            .then(response => response.json()) // Parse the response as JSON;
                    })
                    .catch(error => {
                        // Handle refresh token expiration (log out the user)
                        localStorage.removeItem('loginDetails');
                        window.location = '/login';
                        console.error('Unauthorized:', error); // Log the error message to the console
                        throw error; // Re-throw the error to be caught by the outer catch block
                    });
            } else {
                return data; // Resolve the promise with the response data
            }
        } else {
            data = decryptData(data);
            return data; // Resolve the promise with the response data in case of html response
        }
    }).catch(error => {
        // Handle JSON parsing error or other errors during response processing
        reject(error);
        throw error; // Re-throw the error to be caught by the outer catch block if needed
    });
}


export function refreshToken() {
    return fetch("/admin/api/" + 'frontCall/refresh', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({refresh_token: localStorage.getItem('refreshToken')})

    })
        .then(response => response.json())
        .then(data => {
            const {accessToken, refreshToken} = data.response; // The response contains both accessToken and refreshToken
            // Save the new tokens to localStorage
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            return accessToken; // Return the new access token
        });
}