import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner, Dropdown, Tooltip, OverlayTrigger} from "react-bootstrap";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import SortableList, {SortableItem} from "react-easy-sort";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {PostData, uploadFiles} from "../../api_key/PostData";
import $ from "jquery";
import {secondsToHms} from "../../Common/Utils"
import arrayMove from "array-move";
import ProgressBar from 'react-bootstrap/ProgressBar';
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Select from "react-select";

class AdCampaigns extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_campaigns"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            clientsData: [],
            columns: [],
            createCampaignModal: false,
            mediaUploadPopup: false,
            commonAlert: false,
            commonAlertVariant: "",
            commonAlertMessage: "",
            imageVideoArray: [],
            filesArray: [],
            dataLoader: true,
            userDataPreloader: false,
            clientsFullName: '',
            clientRepeatCampaign: 0,
            shuffleMediaCheck: false,
            isMarketingCheck: false,
            handleDeletePopup: false,
            deletingUuid: null,
            campaignViewing: false,
            downloadReportModal: false,
            downloadReportError: false,
            uploadedFileViewModal: false,
            promotionsListOptions: [],
            selectedOptionImage: [],
            selectedOptionVideo: [],
            qr_position_value_x: [
                {value: 1, label: i18next.t("left")},
                {value: 2, label: i18next.t("center")},
                {value: 3, label: i18next.t("right")}
            ],
            qr_position_value_y: [
                {value: 1, label: i18next.t("top")},
                {value: 2, label: i18next.t("center")},
                {value: 3, label: i18next.t("bottom")}
            ],
            qrSize: [
                {value: 1, label: i18next.t("size_default")},
                {value: 2, label: i18next.t("size_small")},
                {value: 3, label: i18next.t("size_tiny")}
            ],
            qr_position_x: [],
            qr_position_y: [],
            reportDownloadProgress: false,
            archiveConfirmationData: [],
            archiveConfirmation: false,
            statisticsModal: false,
            // showStatisticsCardTable: false,
            filteredDropdown: "",
            select_backpack: [],
            backpackOptionsList: [],
            assignDeviceModal: false,
            deviceOption: [],
            deviceListOptions: [],
            deviceOptionErr: "",
            inlinePreloader: false,
            isRemoveAssignedDevices: false,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.loadAllImageControls = this.loadAllImageControls.bind(this)
        this.loadAllVideoControls = this.loadAllVideoControls.bind(this)
        this.adCampaignData = this.adCampaignData.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.handleDeleteCampaign = this.handleDeleteCampaign.bind(this)
        this.loadStreamFromJson = this.loadStreamFromJson.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
        this.loadPromotionsListOptions = this.loadPromotionsListOptions.bind(this)
        this.onAssignDevice = this.onAssignDevice.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        this.loadAllCampaigns()
        this.loadPromotionsListOptions()
        PostData("/frontCall/devices/getAll")
            .then(result => {
                this.setState({deviceListOptions: result.response.map(data => ({label: data.device_name, value: data.uuid}))})
            })
    }

    loadPromotionsListOptions() {
        let formData = {}
        formData.uuid = this.state.sessionData.uuid;
        PostData("frontCall/promotions/get_all", formData)
            .then(result => {
                this.setState({promotionsListOptions: result.response.map((data) => ({value: data.uuid, label: data.title}))})
            })
    }

    playPauseCampaign(campaignUuid, isPlay) {
        PostData("/frontCall/campaigns/publishHideCampaign", {uuid: campaignUuid, is_published: isPlay})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_updated_successfully"),
                    })
                    this.loadAllCampaigns()
                    setTimeout(() => this.handleClose(), 3000);
                }
            })
    }

    loadAllCampaigns() {
        PostData("/frontCall/campaigns/get_all_campaigns")
            .then(result => {
                let clientsData = []
                if (this.state.filteredDropdown === ": " + i18next.t("all")) {
                    clientsData = result.response
                } else if (this.state.filteredDropdown === ": " + i18next.t("archive")) {
                    clientsData = result.response.filter(data => data.status === false)
                } else if (this.state.filteredDropdown === ": " + i18next.t("unarchive")) {
                    clientsData = result.response.filter(data => data.status === true)
                } else {
                    clientsData = result.response.filter(data => data.status === true)
                }
                this.setState({
                    clientsAllData: result.response,
                    clientsData: clientsData,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("campaign_name")}</h6>,
                            selector: (row) => !row.status ? <>
                                <OverlayTrigger overlay={<Tooltip>Archived</Tooltip>}>
                                    <em className="flaticon-381-box-1"></em>
                                </OverlayTrigger> {row.title}
                            </> : row.title,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("no_of_images")}</h6>,
                            selector: (row) => row.stream.filter((item) => item.media_type === 2).length,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("no_of_videos")}</h6>,
                            selector: (row) => row.stream.filter((item) => item.media_type === 1).length,
                            sortable: true,
                            center: true,
                        },
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("assign")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    campaign_uuid: row.uuid,
                                    assignDeviceModal: true,
                                    deviceOption: row.assigned_devices.map(data => ({label: data.device_name, value: data.uuid})),
                                })
                            }}>
                                <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 300 200" style={{width: "20px"}}>
                                    <path style={{fill: "#ffffff"}}
                                          d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z"
                                          transform="translate(-93 -126.49)"/>
                                    <path style={{fill: "#ffffff"}}
                                          d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z"
                                          transform="translate(-93 -126.49)"/>
                                    <path className="cls-2" style={{fill: "#0B2A97"}}
                                          d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z"
                                          transform="translate(-93 -126.49)"/>
                                    <circle className="cls-2" style={{fill: "#0B2A97"}} cx="168.47" cy="102.83"
                                            r="51.59"/>
                                    <path style={{fill: "#ffffff"}}
                                          d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z"
                                          transform="translate(-93 -126.49)"/>
                                    <circle style={{fill: "#ffffff"}} cx="168.47" cy="91.49" r="27.78"/>
                                    <circle className="cls-2" style={{fill: "#0B2A97"}} cx="157.7" cy="70.51"
                                            r="9.07"/>
                                    <circle className="cls-1" style={{fill: "#ff1d25"}} cx="178.11" cy="76.18"
                                            r="4.54"/>
                                </svg>
                            </Button>,
                            center: true,
                        }] : [],
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("view")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.loadStreamFromJson(row.stream)
                                this.setState({
                                    createCampaignModal: true,
                                    campaignViewing: true,
                                    clientsFullName: row.title,
                                    shuffleMediaCheck: row.shuffle,
                                    isMarketingCheck: row.is_marketing,
                                    clientRepeatCampaign: row.repeat,
                                    uuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-view-2"/>
                            </Button>,
                            center: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("statistics")}</h6>,
                            maxWidth: "110px",
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    statisticsModal: true,
                                    statisticsModalUuid: row.uuid,
                                    userDataPreloader: true,
                                    statisticsModalTitle: row.title,
                                })
                                PostData("frontCall/campaigns/getCampaignDevices", {uuid: row.uuid})
                                    .then(result => {
                                        if(result.status === 400) {
                                            this.setState({backpackOptionsList: []})
                                        } else if(result.status) {
                                            this.setState({backpackOptionsList: result.response.map((data) => ({value: data.uuid, label: data.device_name}))})
                                        }
                                    })

                                PostData("frontCall/campaigns/campaign_impression_count", {uuid: row.uuid})
                                    .then(result => {
                                        this.setState({
                                            statisticsBackpacksData: result.response[0].backpacks,
                                            statisticsImpressionsData: result.response[0].impressions,
                                            statisticsViewsData: result.response[0].views,
                                        })
                                    })

                                this.loadStatisticsData(row)
                            }}>
                                <em className="fa fa-bar-chart"/>
                            </Button>,
                            center: true,
                        },
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    deletingUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>,
                            center: true,
                        }] : [],
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("pause_play")}</h6>,
                            selector: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.playPauseCampaign(row.uuid, !row.is_published)
                            }}>
                                {row.is_published ? <em className="flaticon-381-pause-1"/> :
                                    <em className="flaticon-381-play-button-1"/>}
                            </Button>,
                            center: true,
                        }] : [],
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("more")}</h6>,
                            cell: (row) => <Dropdown drop={"start"}>
                                <Dropdown.Toggle variant="primary">{i18next.t("more")}</Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {(this.state.granularPermissions.includes('u')) && <Dropdown.Item onClick={() => {
                                        this.setState({
                                            archiveConfirmation: true,
                                            archiveConfirmationData: row,
                                            archiveAlert: false,
                                            archiveAlertVariant: "",
                                            archiveAlertMessage: "",
                                        })
                                    }}>{row.status ? i18next.t("archive") : i18next.t("unarchive")}</Dropdown.Item>}
                                    <Dropdown.Item onClick={() => {
                                        this.setState({
                                            downloadReportModal: true,
                                            uuid: row.uuid,
                                        })
                                    }}>{i18next.t("download_report")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>,
                            center: true,
                        },
                    ],
                })
            })
    }

    loadStatisticsData(campaignDetail) {
        PostData("frontCall/campaigns/campaign_statistics", {uuid: campaignDetail.uuid})
            .then(result => {
                this.setState({
                    statisticsTableData: result.response,
                    statisticsTableColumn: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("name")}</h6>,
                            selector: (row) => row.media_file_display_name,
                            sortable: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("impressions")}</h6>,
                            selector: (row) => row.impressions,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("play_time")}</h6>,
                            selector: (row) => row.play_time,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("duration")}</h6>,
                            selector: (row) => row.duration,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("views")}</h6>,
                            selector: (row) => row.views,
                            sortable: true,
                            center: true,
                        },
                    ],
                    userDataPreloader: false,
                })
            })
    }

    handleClose() {
        this.setState({
            createCampaignModal: false,
            imageVideoArray: [],
            filesArray: [],
            userDataPreloader: false,
            commonAlert: false,
            commonAlertVariant: "",
            commonAlertMessage: "",
            clientRepeatCampaign: 0,
            shuffleMediaCheck: false,
            isMarketingCheck: false,
            handleDeletePopup: false,
            clientsFullName: '',
            deletingUuid: null,
            campaignViewing: false,
            downloadReportModal: false,
            downloadReportError: false,
            uploadedFileViewModal: false,
            selectedOptionImage: [],
            selectedOptionVideo: [],
            qr_position_x: [],
            qr_position_y: [],
            reportDownloadProgress: false,
            archiveConfirmationData: [],
            archiveConfirmation: false,
            statisticsModal: false,
            // showStatisticsCardTable: false,
            backpackOptionsList: [],
            assignDeviceModal: false,
            deviceOption: [],
            deviceOptionErr: "",
            inlinePreloader: false,
        })
    }

    onImageSelected = (event) => {
        let index = parseInt(event.currentTarget.attributes.getNamedItem('index').value)
        let imgPreviewElement = "#campaign-img-preview-" + index
        const [file] = event.target.files
        this.state.filesArray[index] = file
        this.setState({filesArray: this.state.filesArray})
        $(imgPreviewElement).attr("src", URL.createObjectURL(file))
    };

    loadAllImageControls() {
        let imageVideoArrayLength = this.state.imageVideoArray.length;
        let currentIndex = (imageVideoArrayLength > 0 ? imageVideoArrayLength : 0);
        this.state.imageVideoArray.push(<SortableItem key={currentIndex}>
            <div className="d-block mb-3" mediatype={"2"} index={currentIndex} id={"campaign-image-" + currentIndex}
                 style={{zIndex: 99999999}}>
                <div className="image-campaign-card-wrapper">
                    <div className="image-campaign-card mb-2">
                        <em className="flaticon-381-pad"/>
                        <label className="m-0" htmlFor={"image-campaign-upload-control-" + currentIndex}>
                            <img id={"campaign-img-preview-" + currentIndex}
                                 src="/assets/images/no-image-place.png" alt=""
                                 className="image-campaign-card-img"/>
                        </label>
                        <Form.Control type="file" index={currentIndex} accept={"image/*"}
                                      className="image-campaign-card-image-input"
                                      id={"image-campaign-upload-control-" + currentIndex}
                                      onChange={this.onImageSelected}/>
                        <div className={"image-campaign-card-label mr-2"}>
                            <Form.Control type="number" className="form-control mb-2"
                                          id={"image-campaign-duration-control-" + currentIndex}
                                          placeholder={i18next.t("duration_in_seconds")}/>
                            <Select
                                id={"select_image_promotion_" + currentIndex}
                                name={"select_image_promotion_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.promotionsListOptions}
                                isClearable={true}
                                placeholder={i18next.t("select_promotion")}
                            />
                            <Select
                                id={"qr_image_size_" + currentIndex}
                                name={"qr_image_size_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.qrSize}
                                isClearable={true}
                                placeholder={i18next.t("qr_size")}
                                className={"mt-2"}
                            />
                        </div>
                        <div className={"d-flex flex-column"} style={{gap: "8px"}}>
                            <Button index={currentIndex} variant={"danger"} onClick={this.onDeleteImageVideo()}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>
                            <Button index={currentIndex} variant={"primary"} onClick={(event) => {
                                const htmlFileControl = <img alt="" src={event.currentTarget.parentElement.parentElement.children[1].children[0].src} className="uploaded-file-image"/>
                                this.setState({
                                    uploadedFileViewModal: true,
                                    uploadedFileViewModalHtml: htmlFileControl,
                                })
                            }}>
                                <em className="flaticon-381-view"/>
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Select
                            name={"qr_position_x_" + currentIndex}
                            onChange={this.handleChange}
                            options={this.state.qr_position_value_x}
                            isClearable={true}
                            placeholder={i18next.t("qr_position_x")}
                            className={"mr-2 w-50"}
                        />
                        <Select
                            name={"qr_position_y_" + currentIndex}
                            onChange={this.handleChange}
                            options={this.state.qr_position_value_y}
                            isClearable={true}
                            placeholder={i18next.t("qr_position_y")}
                            className={"ml-2 w-50"}
                        />
                    </div>
                </div>
            </div>
        </SortableItem>)
        this.setState({imageVideoArray: this.state.imageVideoArray})
    }

    loadAllVideoControls() {
        let imageVideoArrayLength = this.state.imageVideoArray.length;
        let currentIndex = (imageVideoArrayLength > 0 ? imageVideoArrayLength : 0);
        this.state.imageVideoArray.push(<SortableItem key={currentIndex}>
            <div className="d-block mb-3" mediatype={"1"} index={currentIndex} id={"campaign-video-" + currentIndex}
                 style={{zIndex: 99999999}}>
                <div className="video-campaign-card-wrapper">
                    <div className="video-campaign-card mb-2">
                        <em className="flaticon-381-pad"/>
                        <label className="m-0" htmlFor={"video-campaign-upload-control-" + currentIndex}>
                            <video id={"campaign-video-preview-" + currentIndex}
                                   src="/assets/images/no-video-place.mp4"
                                   className="video-campaign-card-img"/>
                            <Form.Control type="file" index={currentIndex} accept={"video/mp4"}
                                          className="video-campaign-card-video-input"
                                          id={"video-campaign-upload-control-" + currentIndex}
                                          onChange={this.onVideoSelected}/>
                            <Form.Control type="hidden" id={"video-campaign-duration-control-" + currentIndex}/>
                        </label>
                        <div className={"video-campaign-card-label mr-2"}>
                            <label className="mb-2" id={"video-campaign-duration-label-" + currentIndex}>
                                {i18next.t("please_select_mp4_video")}
                            </label>
                            <Select
                                name={"select_video_promotion_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.promotionsListOptions}
                                isClearable={true}
                                placeholder={i18next.t("select_promotion")}
                            />
                            <Select
                                name={"qr_video_size_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.qrSize}
                                isClearable={true}
                                placeholder={i18next.t("qr_size")}
                                className={"mt-2"}
                            />
                        </div>
                        <div className={"d-flex flex-column"} style={{gap: "8px"}}>
                            <Button index={currentIndex} variant={"danger"} onClick={this.onDeleteImageVideo()}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>
                            <Button index={currentIndex} variant={"primary"} onClick={(event) => {
                                const htmlFileControl = <video className="uploaded-file-video" controls autoPlay loop>
                                        <source src={event.currentTarget.parentElement.parentElement.children[1].children[0].src} type="video/mp4"/>
                                    </video>
                                this.setState({
                                    uploadedFileViewModal: true,
                                    uploadedFileViewModalHtml: htmlFileControl,
                                })
                            }}>
                                <em className="flaticon-381-view"/>
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Select
                            name={"qr_position_x_" + currentIndex}
                            onChange={this.handleChange}
                            options={this.state.qr_position_value_x}
                            isClearable={true}
                            placeholder={i18next.t("qr_position_x")}
                            className={"mr-2 w-50"}
                        />
                        <Select
                            name={"qr_position_y_" + currentIndex}
                            onChange={this.handleChange}
                            options={this.state.qr_position_value_y}
                            isClearable={true}
                            placeholder={i18next.t("qr_position_x")}
                            className={"ml-2 w-50"}
                        />
                    </div>
                </div>
            </div>
        </SortableItem>)
        this.setState({imageVideoArray: this.state.imageVideoArray})
    }

    async loadStreamFromJson(streamJson) {
        this.setState({userDataPreloader: true})
        let imageVideoArrayTemp = []
        for (let currentIndex = 0; currentIndex < streamJson.length; currentIndex++) {
            let promotion_title_select = ""
            for (let selectIndex = 0; selectIndex < this.state.promotionsListOptions.length; selectIndex++) {
                if(this.state.promotionsListOptions[selectIndex].label === streamJson[currentIndex].promotion_title) {
                    promotion_title_select = this.state.promotionsListOptions[selectIndex]
                }
            }

            let stream = streamJson[currentIndex];
            let inputControl;
            let durationInput = "";
            if (stream.media_type === 1) {
                inputControl = <video id={"campaign-video-preview-" + currentIndex}
                                      src={stream.media_url}
                                      className="video-campaign-card-img"/>
                const video = await this.loadVideoMetadata(stream.media_url)
                durationInput = <div className={"w-100"}>
                    <label className={"video-campaign-card-label w-100"} id={"video-campaign-duration-label-" + currentIndex}>
                        {"Duration: " + secondsToHms(video.duration)}
                    </label>
                    <Select
                        name={"select_video_promotion_" + currentIndex}
                        defaultValue={promotion_title_select}
                        onChange={this.handleChange}
                        options={this.state.promotionsListOptions}
                        isClearable={true}
                        placeholder={i18next.t("select_promotion")}
                    />
                    <Select
                        name={"qr_video_size_" + currentIndex}
                        defaultValue={this.state.qrSize[stream.qr_size - 1]}
                        onChange={this.handleChange}
                        options={this.state.qrSize}
                        isClearable={true}
                        placeholder={i18next.t("qr_size")}
                        className={"mt-2"}
                    />
                </div>
            } else {
                inputControl = <img id={"campaign-img-preview-" + currentIndex}
                                    src={stream.media_url} alt=""
                                    className="image-campaign-card-img"/>
                durationInput = <div className={"w-100"}>
                    <label className={"image-campaign-card-label w-100"} id={"image-campaign-duration-control-" + currentIndex}>
                        {"Duration: " + secondsToHms(stream.duration_in_seconds)}
                    </label>
                    <Select
                        id={"select_image_promotion_" + currentIndex}
                        name={"select_image_promotion_" + currentIndex}
                        defaultValue={promotion_title_select}
                        onChange={this.handleChange}
                        options={this.state.promotionsListOptions}
                        isClearable={true}
                        placeholder={i18next.t("select_promotion")}
                    />
                    <Select
                        id={"qr_image_size_" + currentIndex}
                        name={"qr_image_size_" + currentIndex}
                        defaultValue={this.state.qrSize[stream.qr_size - 1]}
                        onChange={this.handleChange}
                        options={this.state.qrSize}
                        isClearable={true}
                        placeholder={i18next.t("qr_size")}
                        className={"mt-2"}
                    />
                </div>
            }
            imageVideoArrayTemp[currentIndex] = (
                <div key={currentIndex} className="d-block mb-3"
                     data-media_type={(stream.media_type === 1 ? "1" : "2")}
                     data-uuid={stream.uuid}
                     id={(stream.media_type === 1 ? "campaign-video-" : "campaign-image-") + currentIndex}
                     style={{zIndex: 99999999}}>
                    <div className={stream.media_type === 1 ? "video-campaign-card-wrapper" : "image-campaign-card-wrapper"}>
                        <div className={stream.media_type === 1 ? "video-campaign-card mb-2" : "image-campaign-card mb-2"}>
                            <label className="m-0" htmlFor={(stream.media_type === 1 ? "video-campaign-upload-control-" : "image-campaign-upload-control-") + currentIndex}>
                                {inputControl}
                            </label>
                            {durationInput}
                        </div>
                        <div className="d-flex align-items-center">
                            <Select
                                name={"qr_position_x_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.qr_position_value_x}
                                defaultValue={this.state.qr_position_value_x[stream.qr_position_x - 1]}
                                isClearable={true}
                                placeholder={i18next.t("qr_position_x")}
                                className={"mr-2 w-50"}
                            />
                            <Select
                                name={"qr_position_y_" + currentIndex}
                                onChange={this.handleChange}
                                options={this.state.qr_position_value_y}
                                defaultValue={this.state.qr_position_value_y[stream.qr_position_y - 1]}
                                isClearable={true}
                                placeholder={i18next.t("qr_position_y")}
                                className={"ml-2 w-50"}
                            />
                        </div>
                    </div>
                </div>)
        }
        this.setState({imageVideoArray: imageVideoArrayTemp, userDataPreloader: false})
    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption});
    };

    changeStatisticsBackpack = (select_backpack) => {
        this.setState({select_backpack});
        PostData("frontCall/campaigns/campaign_statistics", {
            uuid: this.state.statisticsModalUuid,
            date: this.state.campaignFilterDate,
            device_uuid: (select_backpack !== null) ? select_backpack.value : ""
        })
            .then(result => {
                this.setState({
                    statisticsTableData: result.response,
                    userDataPreloader: false,
                })
            })
    };

    downloadReport(startDate, endDate) {
        this.setState({reportDownloadProgress: true})
        PostData("/frontCall/campaigns/downloadCampaignReport", {
            uuid: this.state.uuid,
            start_date: startDate,
            end_date: endDate
        })
            .then((result) => {
                if (result.status) {
                    this.setState({
                        downloadReportModal: false,
                        downloadReportError: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("download_success"),
                        reportDownloadProgress: false,
                    })
                } else {
                    this.setState({
                        downloadReportModal: false,
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: i18next.t("download_report_err"),
                        reportDownloadProgress: false,
                    })
                }
            })
    }

    onDeleteImageVideo() {
        return (event) => {
            let index = parseInt(event.currentTarget.attributes.getNamedItem('index').value)
            index = this.state.imageVideoArray.findIndex(x => x.props.children.props.index === index)
            this.state.filesArray.splice(index, 1)
            this.state.imageVideoArray.splice(index, 1)
            this.setState({imageVideoArray: this.state.imageVideoArray, filesArray: this.state.filesArray})
        };
    }

    onVideoSelected = async (event) => {
        let index = event.target.attributes.getNamedItem('index').value;
        let videoPreviewElement = "#campaign-video-preview-" + index
        const [file] = event.target.files
        let src = URL.createObjectURL(file)
        const video = await this.loadVideoMetadata(src)
        $(videoPreviewElement).attr("src", src)
        this.state.filesArray[index] = file
        this.setState({filesArray: this.state.filesArray})
        let videoDurationLabel = $("#video-campaign-duration-label-" + index)
        let videoDurationControl = $("#video-campaign-duration-control-" + index)
        videoDurationControl.val(video.duration)
        videoDurationLabel.html("Duration: " + secondsToHms(video.duration))
    };

    loadVideoMetadata = src => new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video')
            video.preload = 'metadata'

            video.onloadedmetadata = function () {
                resolve(this)
            }

            video.onerror = function () {
                reject("Invalid video. Please select a video file.")
            }

            video.src = src
        } catch (e) {
            reject(e)
        }
    })

    adCampaignData() {
        let formData = {
            title: this.state.clientsFullName,
            shuffle: this.state.shuffleMediaCheck,
            is_marketing: this.state.isMarketingCheck,
            is_published: true,
            repeat: parseInt(this.state.clientRepeatCampaign),
            stream: [],
        }
        let totalControls = this.state.imageVideoArray.length
        for (let i = 0; i < totalControls; i++) {
            let index = this.state.imageVideoArray[i].props.children.props.index
            if (this.state.imageVideoArray[i].props.children.props.mediatype === "1") {
                let videoCampId = "#video-campaign-duration-control-" + index
                let qrPositionX = "[name=qr_position_x_" + index + "]"
                let qrPositionY = "[name=qr_position_y_" + index + "]"
                let promotionUuid = "[name=select_video_promotion_" + index + "]"
                let qrSize = "[name=qr_video_size_" + index + "]"
                formData.stream[i] = {
                    media_type: 1,
                    duration_in_seconds: parseInt($(videoCampId).val()) ?? 0,
                    qr_position_x: $(qrPositionX).val(),
                    qr_position_y: $(qrPositionY).val(),
                    promotion_uuid: $(promotionUuid).val(),
                    qr_size: $(qrSize).val()
                }
            } else {
                let imageCmpId = "#image-campaign-duration-control-" + index
                let qrPositionX = "[name=qr_position_x_" + index + "]"
                let qrPositionY = "[name=qr_position_y_" + index + "]"
                let promotionUuid = "[name=select_image_promotion_" + index + "]"
                let qrSize = "[name=qr_image_size_" + index + "]"
                formData.stream[i] = {
                    media_type: 2,
                    duration_in_seconds: parseInt($(imageCmpId).val()) ?? 0,
                    qr_position_x: $(qrPositionX).val(),
                    qr_position_y: $(qrPositionY).val(),
                    promotion_uuid: $(promotionUuid).val(),
                    qr_size: $(qrSize).val()
                }
            }
        }

        PostData("/frontCall/campaigns/ad_campaign", formData)
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.uploadMedia(result.response)
                    this.setState({
                        commonAlert: false,
                        commonAlertVariant: "",
                        commonAlertMessage: "",
                    })
                }
            })
    }

    updateCampaignData() {
        let formData = {
            uuid: this.state.uuid,
            title: this.state.clientsFullName,
            shuffle: this.state.shuffleMediaCheck,
            is_marketing: this.state.isMarketingCheck,
            is_published: true,
            repeat: parseInt(this.state.clientRepeatCampaign),
            stream: [],
        }

        let totalControls = this.state.imageVideoArray.length
        for (let i = 0; i < totalControls; i++) {
            if ($(this.state.imageVideoArray[i].props).attr("data-media_type") === "1") {
                let qrPositionX = "[name=qr_position_x_" + i + "]"
                let qrPositionY = "[name=qr_position_y_" + i + "]"
                let promotionUuid = "[name=select_video_promotion_" + i + "]"
                let qrSize = "[name=qr_video_size_" + i + "]"
                formData.stream[i] = {
                    media_type: 1,
                    qr_position_x: $(qrPositionX).val(),
                    qr_position_y: $(qrPositionY).val(),
                    promotion_uuid: $(promotionUuid).val(),
                    qr_size: $(qrSize).val(),
                    uuid: $(this.state.imageVideoArray[i].props).attr("data-uuid")
                }
            } else {
                let qrPositionX = "[name=qr_position_x_" + i + "]"
                let qrPositionY = "[name=qr_position_y_" + i + "]"
                let promotionUuid = "[name=select_image_promotion_" + i + "]"
                let qrSize = "[name=qr_image_size_" + i + "]"
                formData.stream[i] = {
                    media_type: 2,
                    qr_position_x: $(qrPositionX).val(),
                    qr_position_y: $(qrPositionY).val(),
                    promotion_uuid: $(promotionUuid).val(),
                    qr_size: $(qrSize).val(),
                    uuid: $(this.state.imageVideoArray[i].props).attr("data-uuid")
                }
            }
        }

        PostData("/frontCall/campaigns/update_campaign", formData)
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.setState({
                        createCampaignModal: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_updated_successfully"),
                    })
                    this.loadAllCampaigns()
                    setTimeout(() => this.handleClose(), 3000);
                }
            })
    }

    handleDeleteCampaign() {
        PostData("/frontCall/campaigns/removeCampaign", {uuid: this.state.deletingUuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.setState({
                        handleDeletePopup: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_removed_successfully"),
                    })
                    this.loadAllCampaigns()
                    setTimeout(() => this.handleClose(), 3000);
                }
            })
    }

    uploadMedia(uuidResponse) {
        const data = new FormData();
        for (const file of this.state.filesArray) {
            data.append('files[]', file, file.name);
        }
        let uuids = uuidResponse.map(x => x.uuid).join(",");
        data.append("uuids", uuids)
        this.setState({mediaUploadPopup: true})
        uploadFiles("frontCall/campaigns/uploadCampaignMedia", data)
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.setState({
                        createCampaignModal: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_created_successfully"),
                    })
                    this.loadAllCampaigns()
                    setTimeout(() => this.handleClose(), 3000);
                }
                this.setState({mediaUploadPopup: false})
            })
    }

    onAssignDevice() {
        let formData = {
            campaign_uuid: this.state.campaign_uuid,
            device_uuids: this.state.deviceOption.map(item => item.value)
        }

        if (formData.device_uuids.length === 0 && this.state.isRemoveAssignedDevices === false) {
            this.setState({isRemoveAssignedDevices: true})
            return;
        } else {
            this.setState({inlinePreloader: true, deviceOptionErr: ""})
        }

        PostData("/frontCall/campaigns/assign_devices", formData)
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else if (result.status === true) {
                    this.setState({
                        assignDeviceModal: false,
                        isRemoveAssignedDevices: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("device_assigned_successfully"),
                    })
                    this.loadAllCampaigns()
                    setTimeout(() => this.handleClose(), 3000);
                }
            })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        <div className="text-right mb-3 d-flex align-items-center flex-wrap justify-content-end">
                            {(this.state.granularPermissions.includes('c')) && <Button variant={"primary"} className="mb-2 ml-2" onClick={() => {
                                this.setState({createCampaignModal: true})
                            }}><em className="fa fa-plus"/> {i18next.t("create_campaign")}</Button>}
                            <Dropdown className={"mb-2 ml-2"} style={{display: "inline-block"}}>
                                <Dropdown.Toggle variant="primary">
                                    <em className="fa fa-filter"/> {i18next.t("filter") + this.state.filteredDropdown}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                        this.setState({
                                            clientsData: this.state.clientsAllData,
                                            filteredDropdown: ": " + i18next.t("all")
                                        })
                                    }}>{i18next.t("all")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        this.setState({
                                            clientsData: this.state.clientsAllData.filter(data => data.status === false),
                                            filteredDropdown: ": " + i18next.t("archive")
                                        })
                                    }}>{i18next.t("archive")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        this.setState({
                                            clientsData: this.state.clientsAllData.filter(data => data.status === true),
                                            filteredDropdown: ": " + i18next.t("unarchive")
                                        })
                                    }}>{i18next.t("unarchive")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Modal show={this.state.archiveConfirmation} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>
                                    <Alert show={this.state.archiveAlert} variant={this.state.archiveAlertVariant} className="rounded-lg w-100">
                                        <h6 className="m-0 font-weight-bold">{this.state.archiveAlertMessage}</h6>
                                    </Alert>
                                    {this.state.archiveConfirmationData.status ?
                                        i18next.t("are_you_sure_archive_this_campaign") :
                                        i18next.t("are_you_sure_unarchive_this_campaign")}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    let archiveApi = this.state.archiveConfirmationData.status ?
                                        "frontCall/campaigns/archive_campaign" : "frontCall/campaigns/unarchive_campaign"
                                    this.setState({dataLoader: true})
                                    PostData(archiveApi, {uuid: this.state.archiveConfirmationData.uuid})
                                        .then(result => {
                                            if(result.status) {
                                                this.handleClose()
                                                this.setState({
                                                    commonAlert: true,
                                                    commonAlertVariant: "success",
                                                    commonAlertMessage: result.response,
                                                    dataLoader: false,
                                                })
                                                this.loadAllCampaigns()
                                            } else {
                                                this.setState({
                                                    archiveAlert: true,
                                                    archiveAlertVariant: "danger",
                                                    archiveAlertMessage: (result.response === "Campaign Status Update Successfully") && i18next.t("campaign_status_updated_successfully"),
                                                    dataLoader: false,
                                                })
                                            }
                                            setTimeout(() => {
                                                this.setState({
                                                    commonAlert: false,
                                                    commonAlertVariant: "",
                                                    commonAlertMessage: "",
                                                    archiveAlert: false,
                                                    archiveAlertVariant: "",
                                                    archiveAlertMessage: "",
                                                })
                                            }, 5000);
                                        })
                                }}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({archiveConfirmation: false})
                                }}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.uploadedFileViewModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("media_preview")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="view-uploaded-file-frame">
                                    <div id="uploaded-file-name">
                                        {this.state.uploadedFileViewModalHtml}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({uploadedFileViewModal: false})
                                }}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.createCampaignModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("create_campaign")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group className="mb-3" controlId="clientFull_Name">
                                        <Form.Label>{i18next.t("campaign_name")}</Form.Label>
                                        <Form.Control type="text" name="clientsFullName"
                                                      value={this.state.clientsFullName} onChange={this.onChangeInput}
                                                      placeholder={i18next.t("enter") + " " + i18next.t("campaign_name")}/>
                                        {this.state.clientsFullNameErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.clientsFullNameErr}</span>}
                                    </Form.Group>

                                    <Form.Group className="mb-3 shuffleMusic" controlId="shuffleMusic">
                                        <BootstrapSwitchButton
                                            checked={this.state.shuffleMediaCheck} width={140} height={40}
                                            onlabel={i18next.t("on")} offlabel={i18next.t("off")}
                                            onChange={(checked) => {
                                                this.setState({shuffleMediaCheck: checked})
                                            }}
                                        />
                                        <Form.Label>
                                            <em className="flaticon-381-shuffle-1 ml-2"/> {i18next.t("shuffle_media")}
                                        </Form.Label>
                                    </Form.Group>

                                    {this.state.granularPermissions.includes('u') && <Form.Group className="mb-3" controlId="backpackMediaCampaign">
                                        <BootstrapSwitchButton
                                            checked={this.state.isMarketingCheck} width={140} height={40}
                                            onlabel={i18next.t("yes")} offlabel={i18next.t("no")}
                                            onChange={(checked) => {
                                                this.setState({isMarketingCheck: checked})
                                            }}
                                        />
                                        <Form.Label>
                                            <em className="flaticon-381-film-strip-1 ml-2"/> {i18next.t("backpack_media_campaign")}
                                        </Form.Label>
                                    </Form.Group>}

                                    <Form.Group className="mb-3" controlId="clientRepeatCampaign">
                                        <Form.Label>{i18next.t("repeat")}</Form.Label>
                                        <Form.Control type="text" name="clientRepeatCampaign"
                                                      value={this.state.clientRepeatCampaign}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("enter") + " " + i18next.t("repeat")}/>
                                        {this.state.clientsFullNameErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.clientsFullNameErr}</span>}
                                    </Form.Group>
                                    {this.state.userDataPreloader
                                        && <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/>}
                                    <div>
                                        <SortableList onSortEnd={(oldIndex, newIndex) => {
                                            this.setState({
                                                imageVideoArray: arrayMove(this.state.imageVideoArray, oldIndex, newIndex),
                                                filesArray: arrayMove(this.state.filesArray, oldIndex, newIndex)
                                            })
                                        }} className="list" draggedItemClassName="dragged" id="add-campaign-image-video">
                                            {this.state.imageVideoArray.map((imageVideoArrayData) => (imageVideoArrayData))}
                                        </SortableList>
                                    </div>
                                    <div hidden={this.state.campaignViewing} className="add-media-card">
                                        <p className="mb-2">{i18next.t("add_media")}</p>
                                        <div className="d-flex justify-content-between">
                                            <Button variant={"primary"} className={"mr-2"} onClick={() => {
                                                this.loadAllImageControls()
                                            }}><em className="fa fa-plus"/> {i18next.t("add_image")}</Button>
                                            <Button variant={"primary"} className={"ml-2"} onClick={() => {
                                                this.loadAllVideoControls()
                                            }}><em className="fa fa-plus"/> {i18next.t("add_video")}</Button>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                {(this.state.granularPermissions.includes('u') || this.state.granularPermissions.includes('c')) && <Button variant={"primary"} onClick={() => {
                                    this.state.campaignViewing
                                        ? this.updateCampaignData()
                                        : this.adCampaignData()
                                }}>{i18next.t("save")}</Button>}
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.downloadReportModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("download_report")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.downloadReportError}
                                       variant={this.state.downloadReportErrorVariant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.downloadReportErrorMessage}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group controlId="deviceType">
                                        <Form.Label>{i18next.t("choose_date_to_download_report")}</Form.Label>
                                        <Row>
                                            <Col>
                                                <DatePicker className="form-control"
                                                            selected={this.state.downloadReportStartDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText={'dd/mm/yyyy'}
                                                            onChange={(date) => {
                                                                this.setState({downloadReportStartDate: date})
                                                            }}/>
                                            </Col>
                                            <Col>
                                                <DatePicker className="form-control"
                                                            selected={this.state.downloadReportEndDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText={'dd/mm/yyyy'}
                                                            minDate={this.state.downloadReportStartDate}
                                                            onChange={(date) => {
                                                                this.setState({downloadReportEndDate: date})
                                                            }}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"primary"} onClick={() => {
                                    this.downloadReport(moment(this.state.downloadReportStartDate).format("YYYY-MM-DD"), moment(this.state.downloadReportEndDate).format("YYYY-MM-DD"))
                                }}>{i18next.t("download_report")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.reportDownloadProgress} centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("please_wait_generating_report")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProgressBar style={{height: 30, fontSize: 15}} animated
                                             label={i18next.t("generating_report")} now={100}/>
                            </Modal.Body>
                        </Modal>

                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("sure_to_delete_campaign")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.handleDeleteCampaign}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.mediaUploadPopup} centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("please_wait_media_is_uploading")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProgressBar style={{height: 30, fontSize: 15}} animated
                                             label={i18next.t("uploading_media")} now={100}/>
                            </Modal.Body>
                        </Modal>

                        <Modal show={this.state.statisticsModal} onHide={this.handleClose}
                               size={"xl"} backdrop={"static"} centered keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("campaign_statistics")} - {this.state.statisticsModalTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{backgroundColor: "#F9F9F9"}}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="deviceType">
                                            <Form.Label>{i18next.t("choose_date_to_filter")}</Form.Label>
                                            <DatePicker className="form-control"
                                                        selected={this.state.campaignFilterDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText={'dd/mm/yyyy'}
                                                        isClearable
                                                        onChange={(date) => {
                                                            this.setState({
                                                                userDataPreloader: true,
                                                                /*showStatisticsCardTable: true, */
                                                                campaignFilterDate: date
                                                            })
                                                            PostData("frontCall/campaigns/campaign_statistics", {
                                                                uuid: this.state.statisticsModalUuid,
                                                                date: date,
                                                                device_uuid: (this.state.select_backpack !== null) ? this.state.select_backpack.value : ""
                                                            })
                                                                .then(result => {
                                                                    this.setState({
                                                                        statisticsTableData: result.response,
                                                                        userDataPreloader: false,
                                                                    })
                                                                })
                                                        }}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} style={{zIndex: 2}}>
                                        <Form.Group className="mb-3" controlId="selectBackpack">
                                            <Form.Label>{i18next.t("select_backpack")}</Form.Label>
                                            <Select
                                                id={"select_backpack"}
                                                name={"select_backpack"}
                                                onChange={this.changeStatisticsBackpack}
                                                options={this.state.backpackOptionsList}
                                                isClearable={true}
                                                placeholder={i18next.t("select_backpack")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        {this.state.userDataPreloader
                                            && <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/>}
                                    </Col>
                                    {!this.state.userDataPreloader && <>
                                        <Col xs={12} lg={4}>
                                            <Card className="avtivity-card">
                                                <Card.Body>
                                                    <div className="media align-items-center">
                                                        <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-36 0 260 136.98">
                                                                <path className="cls-1" style={{fill: "#ed2224"}}
                                                                      d="M317.29,320.65H273.4c-1.82,0-3.94,0-6.3,0-36.1,3.9-54.4,23.22-54.4,57.47v17.08c0,24.27,9.68,40.94,28.79,49.54,16,7.2,34.77,7.18,44.85,7.18H324c15.43-3.6,26.6-19.23,26.6-37.31v-55.9C350.58,337.74,335.64,320.65,317.29,320.65Z"
                                                                      transform="translate(-173.42 -318.51)"/>
                                                                <path className="cls-1" style={{fill: "#ed2224"}}
                                                                      d="M206.65,395.23V378.15c0-30.77,14.06-51.11,40.92-59.64-23.73,2.54-54,12.37-58.66,50.16-8.91,2.13-15.49,9.41-15.49,18s6.5,15.81,15.35,18c4.48,42.23,42.07,49.55,66.61,50.81a79.71,79.71,0,0,1-16.37-5.2C217.53,440.62,206.65,422.09,206.65,395.23ZM182,386.69c0-3.48,2.6-6.57,6.35-8.21V394.9C184.55,393.25,182,390.17,182,386.69Z"
                                                                      transform="translate(-173.42 -318.51)"/>
                                                                <path className="cls-2" style={{fill: "#ffffff"}}
                                                                      d="M334.92,391.32c-.63-9.11-1-19.87-3.76-30.37-3-11.27-9.15-17.44-19.69-17.61-19.18-.31-38.09,2.61-56.62,7.37-10.45,2.68-18.92,8.22-18.8,20.7.13,14.53,2.45,28.78,8,42.27,4.85,11.89,14.9,17.32,27.8,16.23a347.23,347.23,0,0,0,36.77-5.23C328.12,420.87,335,412.68,334.92,391.32Z"
                                                                      transform="translate(-173.42 -318.51)"/>
                                                                <circle className="cls-2" style={{fill: "#ffffff"}} cx="112.02" cy="68.37" r="34.31"/>
                                                                <path className="cls-1" style={{fill: "#ed2224"}}
                                                                      d="M285.44,424.86a38,38,0,1,1,38-38A38,38,0,0,1,285.44,424.86Zm0-68.62a30.64,30.64,0,1,0,30.64,30.64A30.68,30.68,0,0,0,285.44,356.24Z"
                                                                      transform="translate(-173.42 -318.51)"/>
                                                                <circle className="cls-3" style={{fill: "#ee2d30"}} cx="112.02" cy="60.83" r="18.47"/>
                                                                <circle className="cls-2" style={{fill: "#ffffff"}} cx="104.86" cy="46.89" r="6.03"/>
                                                                <circle className="cls-2" style={{fill: "#ffffff"}} cx="118.43" cy="50.66" r="3.02"/>
                                                            </svg>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="fs-14 mb-2 font-weight-bold">{i18next.t("backpacks")}</p>
                                                            <span className="title text-black font-w600">{this.state.statisticsBackpacksData}</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                                <div className="effect bg-danger"></div>
                                            </Card>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <Card className="avtivity-card">
                                                <Card.Body>
                                                    <div className="media align-items-center">
                                                        <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                            <i className="flaticon-381-focus" style={{fontSize: "40px"}}></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="fs-14 mb-2 font-weight-bold">{i18next.t("views")}</p>
                                                            <span className="title text-black font-w600">{this.state.statisticsViewsData}</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                                <div className="effect bg-danger"></div>
                                            </Card>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <Card className="avtivity-card">
                                                <Card.Body>
                                                    <div className="media align-items-center">
                                                        <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                            <i className="flaticon-381-like" style={{fontSize: "40px"}}></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="fs-14 mb-2 font-weight-bold">{i18next.t("impressions")}</p>
                                                            <span className="title text-black font-w600">{this.state.statisticsImpressionsData}</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                                <div className="effect bg-danger"></div>
                                            </Card>
                                        </Col>
                                        <Col xs={12}>
                                            <Card>
                                                <Card.Body>
                                                    <DataTable
                                                        columns={this.state.statisticsTableColumn}
                                                        data={this.state.statisticsTableData}
                                                        paginationComponentOptions={{ rowsPerPageText: i18next.t("rows_per_page")}}
                                                        pagination
                                                        noDataComponent={i18next.t("no_records_to_display")}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={this.state.assignDeviceModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>
                                    {!this.state.isRemoveAssignedDevices
                                        ? i18next.t("assign_device") : i18next.t("sure_to_delete_assigned_device")}
                                </Modal.Title>
                            </Modal.Header>
                            {!this.state.isRemoveAssignedDevices && <Modal.Body>
                                <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                </Alert>

                                <Select isSearchable={true} isMulti={true}
                                        value={this.state.deviceOption}
                                        onChange={(selectedOption) => {
                                            this.setState({deviceOption: selectedOption});
                                        }}
                                        options={this.state.deviceListOptions}
                                />
                                {this.state.deviceOptionErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.deviceOptionErr}</span>}
                            </Modal.Body>}
                            <Modal.Footer>
                                {!this.state.isRemoveAssignedDevices ? <Button variant={"danger"} onClick={this.state.isRemoveAssignedDevices
                                    ? this.setState({isRemoveAssignedDevices: false}) : this.handleClose}>
                                    {this.state.isRemoveAssignedDevices ? i18next.t("no") : i18next.t("close")}
                                </Button> : <Button variant={"danger"} onClick={() => this.setState({isRemoveAssignedDevices: false})}>
                                    {i18next.t("no")}
                                </Button>}
                                <Button variant={"primary"} onClick={this.onAssignDevice} disabled={this.state.inlinePreloader}>
                                    {this.state.inlinePreloader
                                        ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                        : (this.state.isRemoveAssignedDevices ? i18next.t("yes") : i18next.t("assign"))}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                               className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.clientsData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default AdCampaigns;