import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner, ListGroup} from "react-bootstrap";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";
import Select from "react-select";

class Users extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_users"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            dataLoader: true,
            commonAlert: {show: false, variant: "", message: ""},
            newUsersAlert: {show: false, variant: "", message: ""},
            editUsersForm: false,
            addNewUsersModal: false,
            fullName: "",
            fullNameErr: "",
            selectRoles: "",
            selectRolesErr: "",
            email: "",
            emailErr: "",
            phone: "",
            phoneErr: "",
            lastLogin: "",
            lastLoginErr: "",
            selectedOption: "",
            password: "",
            passwordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
            telegramUsername: "",
            telegramUsernameErr: "",
            rolesOptions: [],
            rolesPermissionListModal: false,
            rolesPermissionList: "",
            handleDeletePopup: false,
            inlinePreloader: false,
            cityOptions: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteUsers = this.handleDeleteUsers.bind(this)
        this.showHidePassword = this.showHidePassword.bind(this)
        this.addNewUsers = this.addNewUsers.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        this.loadAllUsers();
    }

    loadAllUsers() {
        PostData("/frontCall/cities/getAll")
            .then(result => {
                this.setState({cityOptions: result.response.map(data => ({label: data.name, value: data.uuid}))})
            })
        PostData("/frontCall/roles/getAll")
            .then(result => {
                this.setState({rolesOptions: result.response.map(data => ({label: data.title, value: data.uuid}))})
            })
        PostData("/frontCall/users/getAll")
            .then(result => {
                if(result.status === true) {
                    let usersList = result.response
                    this.setState({
                        usersData: usersList,
                        columns: [
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("full_name")}</h6>,
                                cell: (row) => row.full_name,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("email")}</h6>,
                                cell: (row) => row.email,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("phone")}</h6>,
                                cell: (row) => row.phone,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("telegram")}</h6>,
                                cell: (row) => row.telegram_handle,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("permissions")}</h6>,
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    let listRolePermissions = []
                                    this.setState({
                                        rolesPermissionListModal: true,
                                        inlinePreloader: true
                                    })
                                    listRolePermissions = this.props.permissionList
                                    PostData("/frontCall/users/get", {uuid: row.uuid})
                                        .then(result => {
                                            let rolesPermissionList = []
                                            for (let i = 0; i < listRolePermissions.length; i++) {
                                                for (let j = 0; j < result.response.role.permissions.length; j++) {
                                                    if (listRolePermissions[i].permission_key === result.response.role.permissions[j].key) {
                                                        let granular_types = result.response.role.permissions[j].granular_types.split('')
                                                        rolesPermissionList.push(
                                                            <ListGroup.Item as={"li"} key={"rolesPermissionList" + i}>
                                                                <p className={"font-weight-bold m-0"}>
                                                                    {(i18next.language === "en")
                                                                        ? listRolePermissions[i].title
                                                                        : listRolePermissions[i].title_ru}
                                                                    ({granular_types.includes('c') && " " + i18next.t("create") + " "}
                                                                    {granular_types.includes('r') && " " + i18next.t("read") + " "}
                                                                    {granular_types.includes('u') && " " + i18next.t("update") + " "}
                                                                    {granular_types.includes('d') && " " + i18next.t("delete") + " "}
                                                                    {granular_types.includes('a') && " " + i18next.t("assign") + " "})
                                                                </p>
                                                                <p className={"m-0"}>{(i18next.language === "en")
                                                                    ? listRolePermissions[i].description
                                                                    : listRolePermissions[i].description_ru}</p>
                                                            </ListGroup.Item>)
                                                    }
                                                }
                                            }
                                            this.setState({
                                                inlinePreloader: false,
                                                rolesPermissionList: rolesPermissionList.length > 0
                                                    ? rolesPermissionList
                                                    : <p className="font-weight-bold m-0 text-center">{i18next.t("list_role_permissions")} 0</p>,
                                            })
                                        })
                                }}>
                                    <em className={"flaticon-381-success-2"}/>
                                </Button>,
                                center: true,
                            },
                            ...(this.state.granularPermissions.includes('u')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    PostData("/frontCall/users/get", {uuid: row.uuid})
                                        .then(result => {
                                            this.setState({
                                                selectedOption: {
                                                    label: result.response.role.title,
                                                    value: result.response.role.uuid
                                                }
                                            })
                                        })
                                    if (row.city_id !== null)
                                        this.setState({cityOptionSelected: {label: row.city.name, value: row.city.uuid}})
                                    this.setState({
                                        editUsersForm: true,
                                        addNewUsersModal: true,
                                        uuid: row.uuid,
                                        fullName: row.full_name,
                                        email: row.email,
                                        phone: row.phone,
                                        telegramUsername: row.telegram_handle,
                                    })
                                }}>
                                    <em className={"flaticon-381-edit"}/>
                                </Button>,
                                center: true,
                            }] : [],
                            ...(this.state.granularPermissions.includes('d')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                                cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                    this.setState({
                                        handleDeletePopup: true,
                                        deletingUuid: row.uuid,
                                    })
                                }}>
                                    <em className="flaticon-381-trash-1"/>
                                </Button>,
                                center: true,
                            }] : []
                        ],
                    })
                }
            })
    }

    addNewUsers() {
        this.setState({inlinePreloader: true})
        let formData = {}
            formData.full_name = formData.email = formData.phone = formData.telegram_handle =
                formData.role_uuid = formData.city_id = formData.password = formData.confirmPassword = ""
        if (this.state.fullName === "") {
            this.setState({fullNameErr: i18next.t("enter_full_name")})
        } else {
            this.setState({fullNameErr: ""})
            formData.full_name = this.state.fullName
        }
        if (this.state.email === "") {
            this.setState({emailErr: i18next.t("enter_email")})
        } else if (!this.state.email.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({emailErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({emailErr: ""})
            formData.email = this.state.email
        }
        if (this.state.phone === "") {
            this.setState({phoneErr: i18next.t("enter_phone")})
        } else {
            this.setState({phoneErr: ""})
            formData.phone = this.state.phone
        }
        if (this.state.telegramUsername === "") {
            this.setState({telegramUsernameErr: i18next.t("enter_telegram_username")})
        } else {
            this.setState({telegramUsernameErr: ""})
            formData.role_uuid = this.state.selectedOption.value
        }
        if (Object.keys(this.state.selectedOption).length === 0) {
            this.setState({selectRolesErr: i18next.t("select_role")})
        } else {
            this.setState({selectRolesErr: ""})
            formData.telegram_handle = this.state.telegramUsername
        }
        if (Object.keys(this.state.cityOptionSelected).length === 0) {
            this.setState({cityOptionsErr: i18next.t("select_city")})
        } else {
            this.setState({cityOptionsErr: ""})
            formData.city_id = this.state.cityOptionSelected.value
        }
        if (this.state.editUsersForm === false) {
            if (this.state.password === "") {
                this.setState({passwordErr: i18next.t("enter_password")})
                return;
            } else {
                this.setState({passwordErr: ""})
                formData.password = this.state.password
            }
            if (this.state.confirmPassword === "") {
                this.setState({confirmPasswordErr: i18next.t("enter") + " " + i18next.t("confirm_password")})
                return;
            } else if (this.state.confirmPassword !== this.state.password) {
                this.setState({confirmPasswordErr: i18next.t("new_and_confirm_are_same")})
                return;
            } else {
                this.setState({confirmPasswordErr: ""})
                formData.confirmPassword = this.state.confirmPassword
            }
        }

        if (this.state.editUsersForm) {formData.uuid = this.state.uuid}

        if (formData.full_name !== "" && formData.email !== "" && formData.phone !== "" && formData.role_uuid !== ""
            && formData.telegram_handle !== "" && formData.city_id !== "") {
            let postApi = this.state.editUsersForm ? "/frontCall/users/updateUser" : "/frontCall/users/add";
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newUsersAlert: {
                                show: true,
                                variant: "success",
                                message: this.state.editUsersForm ? i18next.t("user_data_updated") : i18next.t("user_inserted")
                            },
                        })
                        this.loadAllUsers()
                        setTimeout(() => {
                            this.handleClose()
                        }, 2000);
                    } else {
                        this.setState({
                            newUsersAlert: {
                                show: true,
                                variant: "danger",
                                message: result.messages.message
                            },
                            inlinePreloader: false,
                        })
                    }
                })
        } else {
            this.setState({inlinePreloader: false})
        }
    }

    handleDeleteUsers() {
        this.setState({inlinePreloader: true})
        PostData("/frontCall/users/removeUser", {uuid: this.state.deletingUuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message,
                        },
                        inlinePreloader: false,
                    })
                } else {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: i18next.t("user_removed_successfully"),
                        },
                        handleDeletePopup: false,
                    })
                    setTimeout(() => {
                        this.handleClose()
                    }, 2000);
                    this.loadAllUsers()
                }
            })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    showHidePassword(controlId) {
        let password = document.querySelector(controlId)
        let iconId = document.querySelector("[data-iconId='"+controlId+"']")
        if(password.type === "password") {
            password.type = "text"
            iconId.classList.remove("flaticon-381-view-2")
            iconId.classList.add("flaticon-381-hide")
        } else {
            password.type = "password"
            iconId.classList.remove("flaticon-381-hide")
            iconId.classList.add("flaticon-381-view-2")
        }
    }

    handleClose() {
        this.setState({
            editUsersForm: false,
            fullName: "",
            fullNameErr: "",
            email: "",
            emailErr: "",
            phone: "",
            phoneErr: "",
            password: "",
            passwordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
            telegramUsername: "",
            telegramUsernameErr: "",
            selectRoles: "",
            selectRolesErr: "",
            addNewUsersModal: false,
            newUsersAlert: {show: false, variant: "", message: ""},
            commonAlert: {show: false, variant: "", message: ""},
            selectedOption: "",
            rolesPermissionListModal: false,
            rolesPermissionList: "",
            inlinePreloader: false,
            handleDeletePopup: false,
            cityOptionsErr: "",
            cityOptionSelected: [],
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2" onClick={() => {
                                this.setState({addNewUsersModal: true})
                            }}>{i18next.t("add_users")}</Button>
                        </div>}
                        <Modal show={this.state.addNewUsersModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editUsersForm ? i18next.t("edit") + " " + i18next.t("users") : i18next.t("add_users")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newUsersAlert.show} variant={this.state.newUsersAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newUsersAlert.message}</h6>
                                </Alert>
                                <Form>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>{i18next.t("full_name")}</Form.Label>
                                                <Form.Control type="text" name="fullName"
                                                              value={this.state.fullName}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("full_name")}/>
                                                {this.state.fullNameErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.fullNameErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="telegram_username">
                                                <Form.Label>{i18next.t("telegram_username")}</Form.Label>
                                                <Form.Control type="text" name="telegramUsername"
                                                              value={this.state.telegramUsername}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("telegram_username")}/>
                                                {this.state.telegramUsernameErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.telegramUsernameErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>{i18next.t("email")}</Form.Label>
                                                <Form.Control type="text" name="email"
                                                              value={this.state.email}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("email")}/>
                                                {this.state.emailErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.emailErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="phone">
                                                <Form.Label>{i18next.t("phone")}</Form.Label>
                                                <Form.Control type="text" name="phone"
                                                              value={this.state.phone}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("phone")}/>
                                                {this.state.phoneErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.phoneErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="selectRoles">
                                                <Form.Label>{i18next.t("select_role")}</Form.Label>
                                                <Select value={this.state.selectedOption}
                                                        defaultValue={this.state.selectedOption}
                                                        onChange={(selectedOption) => {
                                                            this.setState({selectedOption}, () =>
                                                                this.state.selectedOption
                                                            );
                                                        }}
                                                        options={this.state.rolesOptions}
                                                />
                                                {this.state.selectRolesErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.selectRolesErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="cityOptions">
                                                <Form.Label>{i18next.t("select_city")}</Form.Label>
                                                <Select value={this.state.cityOptionSelected}
                                                        defaultValue={this.state.cityOptionSelected}
                                                        onChange={(cityOptionSelected) => {
                                                            this.setState({cityOptionSelected}, () =>
                                                                this.state.cityOptionSelected
                                                            );}}
                                                        options={this.state.cityOptions}
                                                />
                                                {this.state.cityOptionsErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.cityOptionsErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        {(this.state.editUsersForm === false) && <>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Label>{i18next.t("password")}</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Control type="password" name="password"
                                                                  value={this.state.password}
                                                                  onChange={this.onChangeInput}
                                                                  placeholder={i18next.t("password")}/>
                                                    <Button variant={"primary"} className={"ml-1"} onClick={() => {
                                                        this.showHidePassword("#password")
                                                    }}><i data-iconId={"#password"} className="flaticon-381-view-2"></i></Button>
                                                </div>
                                                {this.state.passwordErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.passwordErr}</div>}
                                                <Button variant={"primary"} className={"mt-2"} onClick={() => {
                                                    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                                                    let passwordLength = 8;
                                                    let password = "";
                                                    for (let i = 0; i <= passwordLength; i++) {
                                                        let randomNumber = Math.floor(Math.random() * chars.length);
                                                        password += chars.substring(randomNumber, randomNumber +1);
                                                    }
                                                    this.setState({password: password, confirmPassword: password,})
                                                }}>{i18next.t("generate_password")}</Button>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="confirmPassword">
                                                <Form.Label>{i18next.t("confirm_password")}</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Control type="password" name="confirmPassword"
                                                                  value={this.state.confirmPassword}
                                                                  onChange={this.onChangeInput}
                                                                  placeholder={i18next.t("confirm_password")}/>
                                                    <Button variant={"primary"} className={"ml-1"} onClick={() => {
                                                        this.showHidePassword("#confirmPassword")
                                                    }}><i data-iconId={"#confirmPassword"} className="flaticon-381-view-2"></i></Button>
                                                </div>
                                                {this.state.confirmPasswordErr &&
                                                    <div className="text-danger pl-3 pt-1">{this.state.confirmPasswordErr}</div>}
                                            </Form.Group>
                                        </Col>
                                        </>}
                                    </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} onClick={() => {
                                    this.addNewUsers()
                                }} disabled={this.state.inlinePreloader}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("sure_to_delete_users")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    this.handleDeleteUsers()
                                }} disabled={this.state.inlinePreloader}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.rolesPermissionListModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("list_given_permissions")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="justify-content-center">
                                {this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block mx-auto"}/>
                                    : <ListGroup as={"ul"} className="timeline overflow-hidden">
                                        {this.state.rolesPermissionList}
                                </ListGroup>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.usersData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Users;