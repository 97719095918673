import React from 'react';
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Card, Row, Col, Form, Spinner, Alert, Button, Modal, ListGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {PostData} from "../../api_key/PostData";
import moment from "moment/moment";
import ruLocale from '@fullcalendar/core/locales/ru';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import i18next from "i18next";
import Select from "react-select";

class Bookings extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_bookings"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            commonAlert: {show: false, variant: '', message: ''},
            newBookingAlert: {show: false, variant: '', message: ''},
            dataLoader: true,
            inlinePreloader: false,
            addNewBookingModal: false,
            backpackOptions: [],
            campaignOptions: [],
            selectedBackpackOption: [],
            selectedBackpackOptionErr: "",
            selectedCampaignOption: "",
            selectedCampaignOptionErr: "",
            startDate: "",
            startDateErr: "",
            endDate: "",
            endDateErr: "",
            deleteUuid: "",
            calendarKey: 0, // A calendarKey to control refreshing the FullCalendar component
            singleEventData: {device_name: "", title: "", start: "", end: "",},
            cityOptions: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
            backpackOptionsResult: [],
        }
        document.title = this.props.pageTitle;
        this.handleClose = this.handleClose.bind(this)
        this.addNewBooking = this.addNewBooking.bind(this)
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this)
        this.fetchEvents = this.fetchEvents.bind(this)
        this.handleEventMouseEnter = this.handleEventMouseEnter.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
        PostData("/frontCall/campaigns/get_all_campaigns")
            .then((result) => {
                let campaignOptions = result.response.map(x => ({'value': x.uuid, 'label': x.title}));
                this.setState({campaignOptions: campaignOptions})
            })
        PostData('/frontCall/devices/getAll', {booking: true})
            .then((result) => {
                let backpackOptions = result.response.map(x => ({'value': x.uuid, 'label': x.device_name}));
                this.setState({backpackOptions: backpackOptions, backpackOptionsResult: result.response})
            })
        PostData("/frontCall/cities/getAll")
            .then(result => {
                let cityOptions = []
                result.response.map(data => {
                    cityOptions.push({label: data.name, value: data.uuid})
                })
                this.setState({cityOptions: cityOptions})
            })
    }

    fetchEvents = (info, successCallback) => {
        const {start, end} = info;
        PostData("/frontCall/schedules/get", {
            start: moment(start).format('YYYY-MM-DDTHH:mm:ssZ'),
            end: moment(end).format('YYYY-MM-DDTHH:mm:ssZ')
        })
            .then(result => {
                if (result.status === true) {
                    successCallback(result.response);
                }
            })
    };

    handleEventMouseEnter = (info) => {
        const tooltip = document.createElement('div');
        tooltip.className = 'event-tooltip';
        // SVG for the device icon
        const deviceIcon = `<svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200" width="16" height="17"><path d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z" transform="translate(-93 -126.49)"></path><path d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z" transform="translate(-93 -126.49)"></path><path class="cls-2" d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z" transform="translate(-93 -126.49)" style="fill: rgb(255, 255, 255);"></path><circle class="cls-2" cx="168.47" cy="102.83" r="51.59" style="fill: rgb(255, 255, 255);"></circle><path d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z" transform="translate(-93 -126.49)"></path><circle cx="168.47" cy="91.49" r="27.78"></circle><circle class="cls-2" cx="157.7" cy="70.51" r="9.07" style="fill: rgb(255, 255, 255);"></circle><circle class="cls-1" cx="178.11" cy="76.18" r="4.54" style="fill: rgb(255, 29, 37);"></circle></svg>`;

        // Icon elements for the campaign and date icons
        const campaignIcon = '<i class="flaticon-381-promotion"></i>';
        const calendarIcon = '<i class="flaticon-381-calendar-6"></i>';
        const cityIcon = '<i class="flaticon-381-locations"></i>';

        tooltip.innerHTML = `
        ${deviceIcon} <b>${info.event.extendedProps.device_name}</b><br/>
        ${campaignIcon} ${info.event.title}<br/>
        ${cityIcon} ${info.event.extendedProps.city.name} - ${info.event.extendedProps.city.zipcode}<br/>
        ${calendarIcon} Start: ${info.event.start.toLocaleString()}<br/>
        ${calendarIcon} End: ${info.event.end.toLocaleString()}`;
        document.body.appendChild(tooltip);

        if (info.el) {
            const rect = info.el.getBoundingClientRect();
            tooltip.style.top = `${rect.top + window.scrollY}px`;
            tooltip.style.left = `${rect.left + window.scrollX + rect.width}px`;

            // Remove tooltip on mouse leave
            const removeTooltip = () => {
                tooltip.remove();
            };

            info.el.addEventListener('mouseleave', removeTooltip);

            // Remove tooltip on left click
            const removeTooltipOnLeftClick = () => {
                removeTooltip();
            };

            info.el.addEventListener('click', removeTooltipOnLeftClick);

            // Remove tooltip on right click
            const removeTooltipOnRightClick = (event) => {
                if (event.button === 2) {
                    // Right mouse button is clicked
                    removeTooltip();
                }
            };

            info.el.addEventListener('contextmenu', removeTooltipOnRightClick);

            // Clean up event listeners on component unmount or when needed
            const cleanUpListeners = () => {
                info.el.removeEventListener('mouseleave', removeTooltip);
                info.el.removeEventListener('click', removeTooltipOnLeftClick);
                info.el.removeEventListener('contextmenu', removeTooltipOnRightClick);
            };

            // Add clean up function to remove listeners
            info.el.addEventListener('componentWillUnmount', cleanUpListeners);
        }
    };

    addNewBooking() {
        if (Object.keys(this.state.selectedBackpackOption).length === 0) {
            this.setState({selectedBackpackOptionErr: i18next.t("select_device")})
        } else {
            this.setState({selectedBackpackOptionErr: ""})
        }
        if (this.state.selectedCampaignOption === "") {
            this.setState({selectedCampaignOptionErr: i18next.t("select_campaign")})
        } else {
            this.setState({selectedCampaignOptionErr: ""})
        }
        if (Object.keys(this.state.cityOptionSelected).length === 0) {
            this.setState({cityOptionsErr: i18next.t("select_city")})
        } else {
            this.setState({cityOptionsErr: ""})
        }
        if (this.state.startDate === "") {
            this.setState({startDateErr: i18next.t("start_date")})
        } else {
            this.setState({startDateErr: ""})
        }
        if (this.state.endDate === "") {
            this.setState({endDateErr: i18next.t("end_date")})
        } else {
            this.setState({endDateErr: ""})
        }

        let formData = {
            campaign_uuid: this.state.selectedCampaignOption.value,
            start_datetime: moment(this.state.startDate).format(),
            end_datetime: moment(this.state.endDate).format(),
            city_id: this.state.cityOptionSelected.value,
        }

        let device_uuid = []
        this.state.selectedBackpackOption.map((data, index) => {
            device_uuid[index] = data.value
        })
        formData.device_uuid = device_uuid

        if (Object.keys(this.state.selectedBackpackOption).length !== 0 && this.state.selectedCampaignOption !== "" &&
            this.state.startDate !== "" && this.state.endDate !== "" && Object.keys(this.state.cityOptionSelected).length !== 0) {
            this.setState({inlinePreloader: true})
            PostData("/frontCall/schedules/book", formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            addNewBookingModal: false,
                            commonAlert: {
                                show: true,
                                variant: "success",
                                message: result.response,
                            },
                            calendarKey: this.state.calendarKey + 1, // Increment calendarKey to trigger refresh
                        })
                        setTimeout(() => this.handleClose(), 5000);
                    } else if (result.status === 400) {
                        this.setState({
                            newBookingAlert: {
                                show: true,
                                variant: "danger",
                                message: result.messages.message,
                            }
                        })
                    } else {
                        this.setState({
                            newBookingAlert: {
                                show: true,
                                variant: "danger",
                                message: result.response,
                            }
                        })
                    }
                    this.setState({inlinePreloader: false})
                })
        }
    }

    handleDeleteEvent() {
        this.setState({inlinePreloader: true})
        PostData("/frontCall/schedules/remove", {uuid: this.state.deleteUuid})
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: result.response,
                        }
                    })
                }
                this.handleClose()
            })
    }

    handleClose() {
        this.setState({
            commonAlert: {show: false, variant: '', message: ''},
            newBookingAlert: {show: false, variant: '', message: ''},
            addNewBookingModal: false,
            selectedBackpackOption: [],
            selectedBackpackOptionErr: "",
            selectedCampaignOption: "",
            selectedCampaignOptionErr: "",
            startDate: "",
            startDateErr: "",
            endDate: "",
            endDateErr: "",
            handleDeleteModal: false,
            singleEventModal: false,
            inlinePreloader: false,
            singleEventData: {device_name: "", title: "", cityValue: "", start: "", end: "",},
            cityOptionsErr: "",
            cityOptionSelected: [],
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2" onClick={() => {
                                this.setState({addNewBookingModal: true})
                            }}>{i18next.t("add_booking")}</Button>
                        </div>}

                        <Modal show={this.state.addNewBookingModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("book_device")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <Alert show={this.state.newBookingAlert.show}
                                           variant={this.state.newBookingAlert.variant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.newBookingAlert.message}</h6>
                                    </Alert>}
                                <Form>
                                    <Form.Group className="mb-3" controlId="selectCity">
                                        <Form.Label>{i18next.t("select_city")}</Form.Label>
                                        <Select value={this.state.cityOptionSelected}
                                                onChange={(cityOptionSelected) => {
                                                    let backpackOptionsResult = []
                                                    this.state.backpackOptionsResult.map(data => {
                                                        if(data.city.uuid === cityOptionSelected.value) {
                                                            backpackOptionsResult.push({"value": data.uuid, "label": data.device_name})
                                                        }
                                                    })
                                                    this.setState({
                                                        cityOptionSelected: cityOptionSelected,
                                                        backpackOptions: backpackOptionsResult,
                                                    })
                                                }}
                                                options={this.state.cityOptions}
                                        />
                                        {this.state.cityOptionsErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.cityOptionsErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="deviceUuid">
                                        <Form.Label>{i18next.t("select_device")}</Form.Label>
                                        <Select value={this.state.selectedBackpackOption} isMulti
                                                onChange={(selectedOption) => {
                                                    this.setState({selectedBackpackOption: selectedOption})
                                                }}
                                                options={this.state.backpackOptions}
                                        />
                                        {this.state.selectedBackpackOptionErr &&
                                            <span
                                                className="text-danger pl-3 pt-1">{this.state.selectedBackpackOptionErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="campaignUuid">
                                        <Form.Label>{i18next.t("select_campaign")}</Form.Label>
                                        <Select value={this.state.selectedCampaignOption}
                                                onChange={(selectedOption) => {
                                                    this.setState({selectedCampaignOption: selectedOption})
                                                }}
                                                options={this.state.campaignOptions}
                                        />
                                        {this.state.selectedCampaignOptionErr &&
                                            <span
                                                className="text-danger pl-3 pt-1">{this.state.selectedCampaignOptionErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="startDate">
                                        <Form.Label>{i18next.t("start")}</Form.Label>
                                        <DatePicker className="form-control"
                                                    selected={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    placeholderText={"dd/mm/yyyy HH:MM"}
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    onChange={(date) => this.setState({startDate: date})}
                                                    minDate={moment().toDate()}
                                        />
                                        {this.state.startDateErr &&
                                            <span
                                                className="text-danger pl-3 pt-1">{this.state.startDateErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label>{i18next.t("end")}</Form.Label>
                                        <DatePicker className="form-control"
                                                    selected={this.state.endDate}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    placeholderText={"dd/mm/yyyy HH:MM"}
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    onChange={(date) => this.setState({endDate: date})}
                                                    minDate={moment().toDate()}
                                        />
                                        {this.state.endDateErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.endDateErr}</span>}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} onClick={() => {
                                    this.addNewBooking()
                                }}>{i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.singleEventModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("booking_details")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{i18next.t("you_cannot_modify_the_bookings")}</p>
                                <ListGroup className={"list-group-flush"}>
                                    <ListGroup.Item className="d-flex px-0 justify-content-between">
                                        <strong>
                                            <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200" width={16} height={16}>
                                                <path style={{fill: "#7e7e7e"}} d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z" transform="translate(-93 -126.49)"/>
                                                <path style={{fill: "#7e7e7e"}} d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z" transform="translate(-93 -126.49)"/>
                                                <path className="cls-2" style={{fill: "#ffffff"}} d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z" transform="translate(-93 -126.49)"/>
                                                <circle className="cls-2" style={{fill: "#ffffff"}} cx="168.47" cy="102.83" r="51.59"/>
                                                <path style={{fill: "#7e7e7e"}} d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z" transform="translate(-93 -126.49)"/>
                                                <circle style={{fill: "#7e7e7e"}} cx="168.47" cy="91.49" r="27.78"/>
                                                <circle className="cls-2" style={{fill: "#ffffff"}} cx="157.7" cy="70.51" r="9.07"/>
                                                <circle className="cls-1" style={{fill: "#ff1d25"}} cx="178.11" cy="76.18" r="4.54"/>
                                            </svg> {i18next.t("device_name_map")}
                                        </strong>
                                        <span className="mb-0">{this.state.singleEventData.device_name}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex px-0 justify-content-between">
                                        <strong><em className="flaticon-381-promotion"></em> {i18next.t("campaign_name")}</strong>
                                        <span className="mb-0">{this.state.singleEventData.title}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex px-0 justify-content-between">
                                        <strong><em className="flaticon-381-locations"></em> {i18next.t("city")}</strong>
                                        <span className="mb-0">{this.state.singleEventData.cityValue}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex px-0 justify-content-between">
                                        <strong><em className="flaticon-381-calendar-6"></em> {i18next.t("start")}</strong>
                                        <span className="mb-0">{this.state.singleEventData.start}</span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex px-0 justify-content-between">
                                        <strong><em className="flaticon-381-calendar-6"></em> {i18next.t("end")}</strong>
                                        <span className="mb-0">{this.state.singleEventData.end}</span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"primary"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                {(this.state.granularPermissions.includes('d')) && <Button variant={"danger"} onClick={() => {
                                    this.setState({handleDeleteModal: true})
                                }}>{i18next.t("delete")}</Button>}
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeleteModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("sure_to_delete_event")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={this.handleDeleteEvent} disabled={this.state.inlinePreloader}>
                                    {this.state.inlinePreloader ?
                                        <Spinner animation="grow" variant="info" className={"d-block m-0"}/> :
                                        i18next.t("yes")}
                                    </Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>

                        <Row>
                            <Col xl={12} xxl={12}>
                                <Card>
                                    <Card.Body className={"demo-app-calendar"}>
                                        <Alert show={this.state.commonAlert.show}
                                               variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <FullCalendar
                                            key={this.state.calendarKey} // Change key to trigger calendar refresh
                                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                                            initialView="timeGridDay"
                                            displayEventTime={true}
                                            locale={i18next.language === "ru" ? ruLocale : undefined} // Set the Russian locale
                                            firstDay={1} // 0 is Sunday, 1 is Monday, and so on
                                            nowIndicator={true} // Set nowIndicator to true to display the current time line
                                            slotLabelInterval={{minutes: 15}}  // Adjust the interval as needed
                                            eventColor="#0B2A97" // Set a default color using hexadecimal code
                                            slotEventOverlap={false} // Prevent events from overlapping
                                            scrollTime={moment().format('HH:00:00')}
                                            businessHours={[{
                                                daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday to Sunday
                                                startTime: '09:00', // 9 AM
                                                endTime: '21:00', // 9 PM
                                            }]}
                                            selectConstraint="businessHours" // Restrict selection to business hours only
                                            slotLabelFormat={{
                                                hour: 'numeric',
                                                minute: '2-digit',
                                                omitZeroMinute: false,
                                                hour12: false, // Use 24-hour format
                                            }}
                                            buttonText={{
                                                today: i18next.t('today'),
                                                month: i18next.t('month'),
                                                week: i18next.t('week'),
                                                day: i18next.t('day'),
                                                list: i18next.t('list')
                                            }}
                                            headerToolbar={{
                                                left: "prev,next today",
                                                center: "title",
                                                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
                                            }}
                                            selectable={true}
                                            eventClick={(event) => {
                                                this.setState({
                                                    deleteUuid: event.event._def.extendedProps.uuid,
                                                    singleEventModal: true,
                                                    singleEventData: {
                                                        device_name: event.el.fcSeg.eventRange.def.extendedProps.device_name,
                                                        title: event.el.fcSeg.eventRange.def.title,
                                                        cityValue: event.el.fcSeg.eventRange.def.extendedProps.city.name + " - " + event.el.fcSeg.eventRange.def.extendedProps.city.zipcode,
                                                        start: event.el.fcSeg.start.toLocaleString(),
                                                        end: event.el.fcSeg.end.toLocaleString(),
                                                    },
                                                })
                                            }}
                                            select={info => {
                                                let clickedDate = info.start.setHours(0, 0, 0, 0)
                                                let nowDate = new Date().setHours(0, 0, 0, 0)
                                                if (clickedDate >= nowDate) {
                                                    if (this.state.granularPermissions.includes('c')) {
                                                        this.setState({
                                                            startDate: info.start,
                                                            endDate: info.end,
                                                            addNewBookingModal: true,
                                                        })
                                                    }
                                                }
                                            }}
                                            weekends={true}
                                            eventSources={[
                                                {
                                                    events: (info, successCallback) => this.fetchEvents(info, successCallback),
                                                },
                                            ]}
                                            eventMouseEnter={(info) => this.handleEventMouseEnter(info)}
                                            dayMaxEvents={2}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Bookings;