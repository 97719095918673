import React from "react";
import i18next from "i18next"
import {Button, Spinner} from "react-bootstrap";
import {Helmet} from "react-helmet";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoader: true,
            pageTitle: this.props.pageTitle,
        }
    }

    componentDidMount() {
        document.getElementById("footer").style.display = "none"
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    render() {
        return (<>
            <Helmet>
                <title>{this.state.pageTitle}</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {this.state.dataLoader &&
                <div className="api-data-loader">
                    <Spinner animation="grow" variant="info"/>
                </div>}
            <div className="container-fluid">
                <div className="form-input-content text-center error-page">
                    <h1 className="error-text font-weight-bold">404</h1>
                    <h4><i className="fa fa-exclamation-triangle text-warning"></i>{i18next.t("page_not_found")}</h4>
                    <p>{i18next.t("mistyped_address")}</p>
                    <p><Button variant={"primary"} onClick={() => {
                        window.location = "/"
                    }}>Home</Button></p>
                </div>
            </div>
        </>)
    }
}

export default NotFound;