import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { Alert, Badge, Button, Card, Col, Form, ListGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";

class Roles extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_roles"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions: currentPermission.granular_types,
            permissionKeysList: permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            rolesData: [],
            columns: [],
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            newRolesAlert: {
                show: false,
                variant: "",
                message: ""
            },
            dataLoader: true,
            handleDeletePopup: false,
            addNewRolesModal: false,
            editRolesForm: false,
            rolesName: "",
            rolesNameErr: "",
            permissionSwitch: false,
            allPermissionsList: "",
            rolesPermissionListModal: false,
            rolesPermissionList: "",
            assignedPermission: [],
            permissionKeyErr: "",
            assignedCrudPermission: [],
            inlinePreloader: false,
            selectAll: false
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteRoles = this.handleDeleteRoles.bind(this)
        this.addNewRoles = this.addNewRoles.bind(this)
        this.convertGranularTypesToArray = this.convertGranularTypesToArray.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        this.loadAllRoles();
    }

    loadAllPermissions(assignedPermissionsList) {
        let assignedPermission = [];
        let assignedCrudPermission = [];

        if (assignedPermissionsList !== "") {
            assignedPermission = assignedPermissionsList.map(item => item.key);
            assignedCrudPermission = this.convertGranularTypesToArray(assignedPermissionsList);
        }

        const allPermissionsList = this.props.permissionList.map((data, index) => {
            const isPermissionAssigned = assignedPermission.includes(data.permission_key);

            return (
                <Col xs={12} lg={6} className={"p-0"} key={"permission" + index}>
                    <div className="p-2 m-1 border">
                        <div className="w-100 d-flex flex-wrap justify-content-between align-items-center">
                            <Form.Label className={"m-0"}>
                                <OverlayTrigger overlay={<Tooltip>
                                    {(i18next.language === "en") ? data.description : data.description_ru}
                                </Tooltip>}>
                                    <Badge bg="primary" className={"text-white mr-2"}>
                                        <em className="flaticon-381-warning-1"></em>
                                    </Badge>
                                </OverlayTrigger>
                                {(i18next.language === "en") ? data.title : data.title_ru}
                            </Form.Label>
                            <div className={"border-0 bg-transparent rounded"}>
                                <Form.Switch
                                    type="switch"
                                    id={data.permission_key}
                                    className={"custom-switch"}
                                    defaultChecked={this.state.selectAll || isPermissionAssigned}
                                    label={" "}
                                    onChange={(checked) => {
                                        let permission = this.state.assignedPermission;
                                        document.querySelector("." + data.permission_key).classList.toggle("d-none")
                                        if (checked.target.checked) {
                                            permission.push(data.permission_key);
                                        } else {
                                            permission = permission.filter(key => key !== data.permission_key);
                                        }
                                        this.setState({assignedPermission: permission});
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={data.permission_key + " p-2 row m-0 " + (isPermissionAssigned ? "" : "d-none")}>
                            {data.granular === "1" && data.granular_types.includes('c') &&
                                <Form.Group controlId={data.permission_key + "___c"} className={"col-6 pl-0"}>
                                    <Form.Check
                                        label={i18next.t("create")} type={"checkbox"} name={data.permission_key + "___c"}
                                        defaultChecked={!!assignedCrudPermission.includes(data.permission_key + "___c")}
                                        onChange={(checkbox) => {
                                            let crudPermissions = [...this.state.assignedCrudPermission]
                                            if (checkbox.target.checked) {
                                                crudPermissions.push(checkbox.currentTarget.name);
                                            } else {
                                                crudPermissions = crudPermissions.filter(item => item !== checkbox.currentTarget.name);
                                            }
                                            this.setState({assignedCrudPermission: crudPermissions});
                                        }}/>
                                </Form.Group>}
                            {data.granular === "1" && data.granular_types.includes('u') &&
                                <Form.Group controlId={data.permission_key + "___u"} className={"col-6 pl-0"}>
                                    <Form.Check
                                        label={i18next.t("modify")} type={"checkbox"} name={data.permission_key + "___u"}
                                        defaultChecked={!!assignedCrudPermission.includes(data.permission_key + "___u")}
                                        onChange={(checkbox) => {
                                            let crudPermissions = [...this.state.assignedCrudPermission];
                                            if (checkbox.target.checked) {
                                                crudPermissions.push(checkbox.currentTarget.name);
                                            } else {
                                                crudPermissions = crudPermissions.filter(item => item !== checkbox.currentTarget.name);
                                            }
                                            this.setState({assignedCrudPermission: crudPermissions});
                                        }}/>
                                </Form.Group>}
                            <Form.Group controlId={data.permission_key + "___r"} className={"col-6 pl-0"}>
                                <Form.Check
                                    label={i18next.t("view")} type={"checkbox"} name={data.permission_key + "___r"}
                                    defaultChecked={!!assignedCrudPermission.includes(data.permission_key + "___r")}
                                    onChange={(checkbox) => {
                                        let crudPermissions = [...this.state.assignedCrudPermission];
                                        if (checkbox.target.checked) {
                                            crudPermissions.push(checkbox.currentTarget.name);
                                        } else {
                                            crudPermissions = crudPermissions.filter(item => item !== checkbox.currentTarget.name);
                                        }
                                        this.setState({assignedCrudPermission: crudPermissions});
                                    }}/>
                            </Form.Group>
                            {data.granular === "1" && data.granular_types.includes('d') &&
                                <Form.Group controlId={data.permission_key + "___d"} className={"col-6 pl-0"}>
                                    <Form.Check
                                        label={i18next.t("delete")} type={"checkbox"} name={data.permission_key + "___d"}
                                        defaultChecked={!!assignedCrudPermission.includes(data.permission_key + "___d")}
                                        onChange={(checkbox) => {
                                            let crudPermissions = [...this.state.assignedCrudPermission];
                                            if (checkbox.target.checked) {
                                                crudPermissions.push(checkbox.currentTarget.name);
                                            } else {
                                                crudPermissions = crudPermissions.filter(item => item !== checkbox.currentTarget.name);
                                            }
                                            this.setState({assignedCrudPermission: crudPermissions});
                                        }}/>
                                </Form.Group>}
                            {data.granular === "1" && data.granular_types.includes('a') &&
                                <Form.Group controlId={data.permission_key + "___a"} className={"col-6 pl-0"}>
                                    <Form.Check
                                        label={i18next.t("assign")} type={"checkbox"} name={data.permission_key + "___a"}
                                        defaultChecked={!!assignedCrudPermission.includes(data.permission_key + "___a")}
                                        onChange={(checkbox) => {
                                            let crudPermissions = [...this.state.assignedCrudPermission];
                                            if (checkbox.target.checked) {
                                                crudPermissions.push(checkbox.currentTarget.name);
                                            } else {
                                                crudPermissions = crudPermissions.filter(item => item !== checkbox.currentTarget.name);
                                            }
                                            this.setState({assignedCrudPermission: crudPermissions});
                                        }}/>
                                </Form.Group>}
                        </div>
                    </div>
                </Col>
            )
        });

        this.setState({
            assignedCrudPermission: assignedCrudPermission,
            allPermissionsList: allPermissionsList,
            assignedPermission: assignedPermission,
        });
    }

    loadWithSelectedPermissions(selectAll) {
        let assignedPermission = [];
        let assignedCrudPermission = [];

        this.props.permissionList.forEach((data) => {
            assignedPermission.push(data.permission_key);
            document.getElementById(data.permission_key).checked = selectAll
            selectAll
                ? document.querySelector("." + data.permission_key).classList.remove("d-none")
                : document.querySelector("." + data.permission_key).classList.add("d-none")
            if (data.granular === "1" && data.granular_types.includes('c')) {
                assignedCrudPermission.push(data.permission_key + "___c");
                document.getElementById(data.permission_key + "___c").checked = selectAll
            }
            assignedCrudPermission.push(data.permission_key + "___r");
            document.getElementById(data.permission_key + "___r").checked = selectAll
            if (data.granular === "1" && data.granular_types.includes('u')) {
                assignedCrudPermission.push(data.permission_key + "___u");
                document.getElementById(data.permission_key + "___u").checked = selectAll
            }
            if (data.granular === "1" && data.granular_types.includes('d')) {
                assignedCrudPermission.push(data.permission_key + "___d");
                document.getElementById(data.permission_key + "___d").checked = selectAll
            }
            if (data.granular === "1" && data.granular_types.includes('a')) {
                assignedCrudPermission.push(data.permission_key + "___a");
                document.getElementById(data.permission_key + "___a").checked = selectAll
            }

        });

        this.setState({
            selectAll: selectAll,
            assignedCrudPermission: assignedCrudPermission,
            assignedPermission: assignedPermission
        });
    }

    loadAllRoles() {
        PostData("/frontCall/roles/getAll")
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        rolesData: result.response,
                        columns: [
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("role_name")}</h6>,
                                cell: (row) => row.title,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("created_at")}</h6>,
                                cell: (row) => row.created,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("permissions")}</h6>,
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    let listRolePermissions = []
                                    this.setState({
                                        rolesPermissionListModal: true,
                                        inlinePreloader: true
                                    })
                                    listRolePermissions = this.props.permissionList

                                    PostData("/frontCall/roles/get", {uuid: row.uuid})
                                        .then(result => {
                                            let rolesPermissionList = []
                                            for (let i = 0; i < listRolePermissions.length; i++) {
                                                for (let j = 0; j < result.response.permissions.length; j++) {
                                                    if (listRolePermissions[i].permission_key === result.response.permissions[j].key) {
                                                        let granular_types = result.response.permissions[j].granular_types.split('')
                                                        rolesPermissionList.push(
                                                            <ListGroup.Item as={"li"} key={"rolesPermissionList" + i}>
                                                                <p className={"font-weight-bold m-0"}>
                                                                    {(i18next.language === "en")
                                                                        ? listRolePermissions[i].title
                                                                        : listRolePermissions[i].title_ru}
                                                                    ({granular_types.includes('c') && " " + i18next.t("create") + " "}
                                                                    {granular_types.includes('r') && " " + i18next.t("read") + " "}
                                                                    {granular_types.includes('u') && " " + i18next.t("update") + " "}
                                                                    {granular_types.includes('d') && " " + i18next.t("delete") + " "})
                                                                </p>
                                                                <p className={"m-0"}>{(i18next.language === "en")
                                                                    ? listRolePermissions[i].description
                                                                    : listRolePermissions[i].description_ru}</p>
                                                            </ListGroup.Item>)
                                                    }
                                                }
                                            }
                                            this.setState({
                                                inlinePreloader: false,
                                                rolesPermissionList: rolesPermissionList.length > 0
                                                    ? rolesPermissionList
                                                    :
                                                    <p className="font-weight-bold m-0 text-center">{i18next.t("list_role_permissions")} 0</p>,
                                            })
                                        })
                                }}>
                                    <em className={"flaticon-381-success-2"}/>
                                </Button>,
                                sortable: true
                            },
                            ...(this.state.granularPermissions.includes('u')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    PostData("/frontCall/roles/get", {uuid: row.uuid})
                                        .then(result => {
                                            this.loadAllPermissions(result.response.permissions)
                                        })
                                    this.setState({
                                        editRolesForm: true,
                                        addNewRolesModal: true,
                                        rolesName: row.title,
                                        uuid: row.uuid,
                                    })
                                }}>
                                    <em className={"flaticon-381-edit"}/>
                                </Button>,
                                center: true,
                            }] : [],
                            ...(this.state.granularPermissions.includes('d')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                                cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                    this.setState({
                                        handleDeletePopup: true,
                                        deletingUuid: row.uuid,
                                    })
                                }}>
                                    <em className="flaticon-381-trash-1"/>
                                </Button>,
                                center: true,
                            }] : []
                        ],
                    })
                }
            })
    }

    addNewRoles() {
        this.setState({inlinePreloader: true})
        if (this.state.rolesName === "") {
            this.setState({rolesNameErr: i18next.t("enter") + " " + i18next.t("role_name")})
        } else {
            this.setState({rolesNameErr: ""})
        }
        if (Object.keys(this.state.assignedCrudPermission).length === 0) {
            this.setState({permissionKeyErr: i18next.t("select_1_permission")})
            this.setState({inlinePreloader: false})
            return;
        } else {
            this.setState({permissionKeyErr: ""})
        }

        let formData = {title: this.state.rolesName}
        if (Object.keys(this.state.assignedCrudPermission).length > 0) {
            const resultObject = {};
            this.state.assignedCrudPermission.forEach(permission => {
                let [key, action] = permission.split('___');
                if (!resultObject[key]) resultObject[key] = [];
                if (!resultObject[key].includes(action)) resultObject[key].push(action);
            })
            formData.permission_keys = resultObject
        }
        if (this.state.editRolesForm) {
            formData.uuid = this.state.uuid;
        }

        if (formData.title !== "" && Object.keys(formData.permission_keys).length > 0) {
            let postApi = this.state.editRolesForm ? "/frontCall/roles/updateRole" : "/frontCall/roles/add";
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newRolesAlert: {
                                show: true,
                                variant: "success",
                                message: this.state.editRolesForm ? i18next.t("role_data_updated") : i18next.t("role_inserted")
                            },
                        })
                        this.loadAllRoles()
                        setTimeout(() => {
                            this.handleClose()
                        }, 2000);
                    } else {
                        this.setState({
                            newRolesAlert: {
                                show: true,
                                variant: "danger",
                                message: result.messages.message
                            },
                            inlinePreloader: false,
                        })
                    }
                })
        } else {
            this.setState({inlinePreloader: false})
        }
    }

    handleDeleteRoles() {
        this.setState({inlinePreloader: true})
        PostData("/frontCall/roles/removeRole", {uuid: this.state.deletingUuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message
                        },
                        inlinePreloader: false,
                    })
                } else {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: i18next.t("roles_removed_successfully")
                        },
                        handleDeletePopup: false,
                    })
                    setTimeout(() => {
                        this.handleClose()
                    }, 2000);
                    this.loadAllRoles()
                }
            })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    convertGranularTypesToArray(inputArray) {
        const resultArray = [];

        inputArray.forEach(item => {
            const key = item.key;
            const granularTypes = item.granular_types.split('');

            granularTypes.forEach(type => {
                resultArray.push(`${key}___${type}`);
            });
        });

        return resultArray;
    }

    handleClose() {
        this.setState({
            editRolesForm: false,
            allPermissionsList: "",
            rolesName: "",
            rolesNameErr: "",
            addNewRolesModal: false,
            newRolesAlert: {
                show: false,
                variant: "",
                message: ""
            },
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            rolesPermissionListModal: false,
            rolesPermissionList: "",
            assignedPermission: [],
            permissionKeyErr: "",
            assignedCrudPermission: [],
            inlinePreloader: false,
            handleDeletePopup: false,
            selectAll:false
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        <div className="text-right mb-3">
                            {(this.state.granularPermissions.includes('c')) &&
                                <Button variant={"primary"} className="mb-2" onClick={() => {
                                    this.loadAllPermissions("", false, false);
                                    this.setState({addNewRolesModal: true})
                                }}>{i18next.t("add_roles")}</Button>}
                        </div>
                        <Modal show={this.state.addNewRolesModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editRolesForm ? i18next.t("edit") + " " + i18next.t("roles") : i18next.t("add_roles")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newRolesAlert.show} variant={this.state.newRolesAlert.variant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newRolesAlert.message}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group className="mb-3" controlId="rolesName">
                                        <Form.Label>{i18next.t("role_name")}</Form.Label>
                                        <Form.Control type="text" name="rolesName"
                                                      value={this.state.rolesName}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("role_name")}/>
                                        {this.state.rolesNameErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.rolesNameErr}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{i18next.t("permissions")}</Form.Label>
                                        <Row className={"m-0"}>
                                            <Col xs={12}
                                                 className={"p-0 d-flex flex-wrap justify-content-between align-items-center"}>
                                                <Button variant={"primary"} className={"my-2"} onClick={() => {
                                                    this.loadWithSelectedPermissions(true)
                                                }}>{i18next.t("select_all")}</Button>
                                                <Button variant={"danger"} className={"my-2"} onClick={() => {
                                                    this.loadWithSelectedPermissions(false)
                                                }}>{i18next.t("unselect_all")}</Button>
                                            </Col>
                                            {this.state.allPermissionsList}
                                        </Row>
                                        {this.state.permissionKeyErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.permissionKeyErr}</div>}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} onClick={() => {
                                    this.addNewRoles()
                                }} disabled={this.state.inlinePreloader}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("delete_this_role")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    this.handleDeleteRoles()
                                }} disabled={this.state.inlinePreloader}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.rolesPermissionListModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false} size={"lg"}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("list_role_permissions")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="justify-content-center">
                                {this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block mx-auto"}/>
                                    : <ListGroup as={"ul"} className="timeline overflow-hidden">
                                        {this.state.rolesPermissionList}
                                    </ListGroup>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert.show}
                                               variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.rolesData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Roles;