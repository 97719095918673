import React from 'react'
import './style.css';
import $ from "jquery";
import i18next from "i18next"

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("light-page").removeClass("dark-page")
        $("#main-sections").attr("style", "padding-top: " + (document.getElementById("sticky-top").offsetHeight + 16) + "px")
        $("#sticky-top").addClass("sticky-top")
        $(".header-bar").removeClass("active-nav").addClass("container")
        $(".burger-menu").removeClass("pointer-opacity")
    }

    render() {
        return (
            <section id="privacy_policy" className="section promoters-section container">
                <h1 className="about-format-title promoters-title">{i18next.t("privacy_policy")}</h1>
                <div className="promoters-section-content">
                    <div className="promoters-content-left w-100">
                        <h6 className="promoters-content-title">{i18next.t("privacy_policy")}</h6>
                        <p className="your-work-is-as-follows-detail">{i18next.t("as_amended")}</p>
                        <p className="your-work-is-as-follows-detail">{i18next.t("ip_matershov_matvey_andreevich")}</p>
                        <p className="your-work-is-as-follows-detail">{i18next.t("site_means")}</p>

                        <h6 className="promoters-content-title">{i18next.t("1")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("1_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("1_1_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("1_1_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("1_1_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("1_2")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("2")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_4")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_5")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_6")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_7")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_8")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_9")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_10")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_11")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_12")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_13")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("2_2_14")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("3")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_1_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_1_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_1_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_1_4")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_3_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_3_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_3_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_4")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_4_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_4_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_4_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_5")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_6")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_7")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("3_8")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("4")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_1_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_1_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_2_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_2_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("4_2_3")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("5")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_2_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_2_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_2_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("5_3")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("6")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("6_1")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("6_2")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("6_3")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("6_4")}</li>
                        </ul>

                        <h6 className="promoters-content-title">{i18next.t("7")}</h6>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("7_1")}</li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default PrivacyPolicy;