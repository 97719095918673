import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Row, Col, Card, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom"
import {PostData} from "../../api_key/PostData";
import i18next from "i18next"
import {Chart, CategoryScale, LinearScale, PointElement, LineElement} from "chart.js";
import {Line} from 'react-chartjs-2';
import Select from "react-select";
import moment from "moment";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

class Home extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_dashboard"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            totalDevices: '',
            totalOnlineDevices: '',
            totalOfflineDevices: '',
            dataLoader: true,
        }
        document.title = this.props.pageTitle
        this.loadAllDeviceCount = this.loadAllDeviceCount.bind(this)
        this.loadPromotionsData = this.loadPromotionsData.bind(this)
    }

    componentDidMount() {
        this.loadAllDeviceCount()
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    loadAllDeviceCount() {
        if (localStorage.getItem('loginDetails') !== null) {
            let sessionStorageData = {
                uuid: JSON.parse(localStorage.getItem('loginDetails')).uuid
            }

            let formData = {uuid: sessionStorageData.uuid};
            PostData("/frontCall/dashboard/getDashboardCards", formData)
                .then((result) => {
                    result.status && this.setState({
                        totalDevices: result.response.devices,
                        totalOnlineDevices: result.response.online_devices,
                        totalOfflineDevices: result.response.offline_devices,
                        totalCampaigns: result.response.campaigns,
                        totalClients: result.response.clients,
                        totalPromoterRoutes: result.response.promoter_routes,
                        totalPromotionViews: result.response.promotion_views,
                        totalPromotionVisits: result.response.promotion_visits,
                        totalPromotions: result.response.promotions,
                    });
                })
            formData = {uuid: sessionStorageData.uuid, deviceToken: localStorage.getItem('deviceToken')}
            PostData("frontCall/dashboard/subscribeToDeviceTopics", formData);
            PostData("frontCall/promotions/get_all", formData)
                .then(result => {
                    if(result.response.length>0) {
                        this.loadPromotionsData(result.response[result.response.length - 1].uuid)

                        let recentlyCreatedPromotions = result.response.slice(0, 5).map(data =>
                            (<div className="d-flex mb-4 align-items-center" key={data.uuid}>
                            <span className="date-icon mr-3">
                                {data.imageUrl
                                    ? <img src={data.imageUrl} alt=""
                                           style={{width: "48px", height: "48px", objectFit: "contain"}}/>
                                    : <i className="flaticon-381-smartphone-3"></i>}
                            </span>
                                <div className={"recently-created-lists"}>
                                    <h6 className="fs-16">{data.title}</h6>
                                    <span>{moment(data.created, "YYYYMMDD").fromNow()}</span>
                                </div>
                            </div>)
                        )

                        this.setState({
                            promotionSelectOptions: result.response.map(data => ({label: data.title, value: data.uuid})),
                            promotionSelectOptionsSelected: [{
                                label: result.response[result.response.length - 1].title,
                                value: result.response[result.response.length - 1].uuid
                            }],
                            recentlyCreatedPromotionsData: recentlyCreatedPromotions,
                        })
                    }
                })

            PostData('/frontCall/devices/getAll', {
                uuid: this.state.sessionData.uuid
            })
                .then(result => {
                    let recentlyCreatedDevices = result.response.slice(0, 5).map(data =>
                        (<div className="d-flex mb-4 align-items-center" key={data.uuid}>
                            <span className={(data.status === "Offline") ?
                                "date-icon px-2 mr-3 bgl-danger" : "date-icon mr-3 px-2 bgl-success"}>
                                {parseInt(data.device_type) === 1 ? <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 300 200">
                                    <path
                                        d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z"
                                        transform="translate(-93 -126.49)"/>
                                    <path
                                        d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z"
                                        transform="translate(-93 -126.49)"/>
                                    <path className="cls-2" style={{fill: "#ffffff"}}
                                          d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z"
                                          transform="translate(-93 -126.49)"/>
                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="168.47" cy="102.83"
                                            r="51.59"/>
                                    <path
                                        d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z"
                                        transform="translate(-93 -126.49)"/>
                                    <circle cx="168.47" cy="91.49" r="27.78"/>
                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="157.7" cy="70.51"
                                            r="9.07"/>
                                    <circle className="cls-1" style={{fill: "#ff1d25"}} cx="178.11" cy="76.18"
                                            r="4.54"/>
                                </svg> : <em className="flaticon-381-smartphone-4"></em>}
                            </span>
                            <div className={"recently-created-lists"}>
                                <h6 className="fs-16">{data.device_name}</h6>
                                <span>{moment(data.created, "YYYYMMDD").fromNow()}</span>
                            </div>
                        </div>)
                    )
                    this.setState({recentlyCreatedDevicesData: recentlyCreatedDevices})
                })

            PostData("/frontCall/campaigns/get_all_campaigns")
                .then(result => {
                    let recentlyCreatedCampaigns = result.response.slice(0, 5).map(data =>
                        (<>
                            <Link to={"/ad_campaigns"} className="d-flex align-items-center list-row p-3" key={data.uuid}>
                                <div className="list-icon bgl-primary mr-3">
                                    <p className="fs-24 text-primary mb-0 mt-2">
                                        {(data.stream.filter((item) => item.media_type === 2).length) +
                                            (data.stream.filter((item) => item.media_type === 1).length)}
                                    </p>
                                    <span className="fs-14 text-primary">{i18next.t("media")}</span>
                                </div>
                                <div className="info">
                                    <h4 className="fs-20">{data.title}</h4>
                                    <span className="text-danger font-w600">{moment(data.created, "YYYYMMDD").fromNow()}</span>
                                </div>
                            </Link>
                        </>)
                    )
                    this.setState({recentlyCreatedCampaignsData: recentlyCreatedCampaigns})
                })

            PostData("/frontCall/promoter_routes/get_all_promoter_routes", {client_uuid: this.state.sessionData.uuid})
                .then(result => {
                    let recentlyCreatedPromoterRoutes = result.response.slice(0, 5).map(data =>
                        (<>
                            <Link to={"/promoter_routes/promoter_route"} className="d-flex align-items-center list-row p-3" key={data.uuid}>
                                <div className="list-icon bgl-primary mr-3">
                                    <p className="fs-24 text-primary mb-0 mt-2">{JSON.parse(data.path_array).length}</p>
                                    <span className="fs-14 text-primary">{i18next.t("stops")}</span>
                                </div>
                                <div className="info">
                                    <h4 className="fs-20">{data.title}</h4>
                                    <span className="text-danger font-w600">{moment(data.created, "YYYYMMDD").fromNow()}</span>
                                </div>
                            </Link>
                        </>)
                    )
                    this.setState({recentlyCreatedPromoterRoutesData: recentlyCreatedPromoterRoutes})
                })
        }
    }

    loadPromotionsData(selectedPromotion) {
        PostData("frontCall/promotions/promotion_tracking", {uuid: selectedPromotion})
            .then(result => {
                let chartData = {}
                let chartDataLabels = []
                let chartDataData = []
                result.response.promotion_tracking.map((data, index) => {
                    chartDataLabels[index] = data.date
                    chartDataData[index] = parseInt(data.count)
                })

                chartData = {
                    labels: chartDataLabels,
                    datasets: [{
                        label: "First dataset",
                        data: chartDataData,
                        fill: true,
                        backgroundColor: "rgba(75,192,192,0.2)",
                        borderColor: "rgba(75,192,192,1)"
                    }]
                }
                if(result.status === true) {
                    this.setState({
                        statisticsPromotions: result.response.total_campaigns,
                        statisticsVisits: result.response.page_visits,
                        statisticsWebsiteVisit: result.response.vendor_website_visits,
                        statisticsMedia: result.response.total_media,
                        lineChartComponent: <Line data={chartData} />,
                    })
                }
            })
    }

    changePromotionData = (selectedOption) => {
        this.setState({
            promotionSelectOptionsSelected: selectedOption
        })
        this.loadPromotionsData(selectedOption.value)
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/devices"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-dark mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-36 0 260 136.98">
                                                    <path className="cls-1" style={{fill: "#010101"}}
                                                          d="M330.42,346.69H290.89c-1.63,0-3.55,0-5.67,0-32.51,3.51-49,20.91-49,51.76v15.37c0,21.86,8.72,36.88,25.92,44.62,14.4,6.48,31.31,6.47,40.4,6.47h33.89c13.9-3.24,24-17.32,24-33.6V381C360.4,362.08,346.94,346.69,330.42,346.69Z"
                                                          transform="translate(-200.85 -344.76)"/>
                                                    <path className="cls-1" style={{fill: "#010101"}}
                                                          d="M230.78,413.85V398.48c0-27.72,12.66-46,36.85-53.72-21.37,2.3-48.68,11.14-52.83,45.18-8,1.91-14,8.47-14,16.23s5.86,14.24,13.82,16.2c4,38,37.9,44.62,60,45.76a72,72,0,0,1-14.74-4.69C240.57,454.74,230.78,438.05,230.78,413.85Zm-22.24-7.68c0-3.13,2.33-5.92,5.71-7.4v14.79C210.87,412.08,208.54,409.3,208.54,406.17Z"
                                                          transform="translate(-200.85 -344.76)"/>
                                                    <path className="cls-2" style={{fill: "#ffffff"}}
                                                          d="M346.3,410.34c-.57-8.2-.89-17.9-3.39-27.36-2.68-10.14-8.24-15.7-17.73-15.85-17.28-.28-34.31,2.35-51,6.63-9.41,2.42-17,7.41-16.93,18.65a102.46,102.46,0,0,0,7.17,38.07c4.37,10.7,13.43,15.59,25,14.61a310,310,0,0,0,33.11-4.71C340.17,436.94,346.37,429.57,346.3,410.34Z"
                                                          transform="translate(-200.85 -344.76)"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="100.88" cy="61.58" r="30.89"/>
                                                    <path className="cls-1" style={{fill: "#010101"}}
                                                          d="M301.73,440.54a34.2,34.2,0,1,1,34.2-34.2A34.23,34.23,0,0,1,301.73,440.54Zm0-61.79a27.59,27.59,0,1,0,27.59,27.59A27.63,27.63,0,0,0,301.73,378.75Z"
                                                          transform="translate(-200.85 -344.76)"/>
                                                    <circle className="cls-3" cx="100.88" cy="54.79" r="16.64"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="94.43" cy="42.22" r="5.43"/>
                                                    <circle className="cls-2" style={{fill: "#ed2e31"}} cx="106.65" cy="45.62" r="2.72"/>
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("total_devices")}</p>
                                                <span className="title text-black font-w600">{this.state.totalDevices}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-dark"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-dark"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/devices"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-success mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-36 0 260 136.98">
                                                    <path className="cls-1" style={{fill: "#017941"}}
                                                          d="M353.29,354.65H309.4c-1.82,0-3.94,0-6.3,0-36.1,3.9-54.4,23.22-54.4,57.47v17.08c0,24.27,9.68,40.94,28.79,49.54,16,7.2,34.77,7.18,44.85,7.18H360c15.43-3.6,26.6-19.23,26.6-37.31v-55.9C386.58,371.74,371.64,354.65,353.29,354.65Z"
                                                          transform="translate(-209.42 -352.51)"/>
                                                    <path className="cls-1" style={{fill: "#017941"}}
                                                          d="M242.65,429.23V412.15c0-30.77,14.06-51.11,40.92-59.64-23.73,2.54-54,12.37-58.66,50.16-8.91,2.13-15.49,9.41-15.49,18s6.5,15.81,15.35,18c4.48,42.23,42.07,49.55,66.61,50.81a79.71,79.71,0,0,1-16.37-5.2C253.53,474.62,242.65,456.09,242.65,429.23ZM218,420.69c0-3.48,2.6-6.57,6.35-8.21V428.9C220.55,427.25,218,424.17,218,420.69Z"
                                                          transform="translate(-209.42 -352.51)"/>
                                                    <path className="cls-2" style={{fill: "#ffffff"}}
                                                          d="M370.92,425.32c-.63-9.11-1-19.87-3.76-30.37-3-11.27-9.15-17.44-19.69-17.61-19.18-.31-38.09,2.61-56.62,7.37-10.45,2.68-18.92,8.22-18.8,20.7.13,14.53,2.45,28.78,8,42.27,4.85,11.89,14.9,17.32,27.8,16.23a347.23,347.23,0,0,0,36.77-5.23C364.12,454.87,371,446.68,370.92,425.32Z"
                                                          transform="translate(-209.42 -352.51)"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="112.02" cy="68.37" r="34.31"/>
                                                    <path className="cls-1" style={{fill: "#017941"}}
                                                          d="M321.44,458.86a38,38,0,1,1,38-38A38,38,0,0,1,321.44,458.86Zm0-68.62a30.64,30.64,0,1,0,30.64,30.64A30.68,30.68,0,0,0,321.44,390.24Z"
                                                          transform="translate(-209.42 -352.51)"/>
                                                    <circle className="cls-1" style={{fill: "#017941"}} cx="112.02" cy="60.83" r="18.47"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="104.86" cy="46.89" r="6.03"/>
                                                    <circle className="cls-3" style={{fill: "#ed2e31"}} cx="118.43" cy="50.66" r="3.02"/>
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("online_devices")}</p>
                                                <span className="title text-black font-w600">{this.state.totalOnlineDevices}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-success"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-success"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/devices"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-36 0 260 136.98">
                                                    <path className="cls-1" style={{fill: "#ed2224"}}
                                                          d="M317.29,320.65H273.4c-1.82,0-3.94,0-6.3,0-36.1,3.9-54.4,23.22-54.4,57.47v17.08c0,24.27,9.68,40.94,28.79,49.54,16,7.2,34.77,7.18,44.85,7.18H324c15.43-3.6,26.6-19.23,26.6-37.31v-55.9C350.58,337.74,335.64,320.65,317.29,320.65Z"
                                                          transform="translate(-173.42 -318.51)"/>
                                                    <path className="cls-1" style={{fill: "#ed2224"}}
                                                          d="M206.65,395.23V378.15c0-30.77,14.06-51.11,40.92-59.64-23.73,2.54-54,12.37-58.66,50.16-8.91,2.13-15.49,9.41-15.49,18s6.5,15.81,15.35,18c4.48,42.23,42.07,49.55,66.61,50.81a79.71,79.71,0,0,1-16.37-5.2C217.53,440.62,206.65,422.09,206.65,395.23ZM182,386.69c0-3.48,2.6-6.57,6.35-8.21V394.9C184.55,393.25,182,390.17,182,386.69Z"
                                                          transform="translate(-173.42 -318.51)"/>
                                                    <path className="cls-2" style={{fill: "#ffffff"}}
                                                          d="M334.92,391.32c-.63-9.11-1-19.87-3.76-30.37-3-11.27-9.15-17.44-19.69-17.61-19.18-.31-38.09,2.61-56.62,7.37-10.45,2.68-18.92,8.22-18.8,20.7.13,14.53,2.45,28.78,8,42.27,4.85,11.89,14.9,17.32,27.8,16.23a347.23,347.23,0,0,0,36.77-5.23C328.12,420.87,335,412.68,334.92,391.32Z"
                                                          transform="translate(-173.42 -318.51)"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="112.02" cy="68.37" r="34.31"/>
                                                    <path className="cls-1" style={{fill: "#ed2224"}}
                                                          d="M285.44,424.86a38,38,0,1,1,38-38A38,38,0,0,1,285.44,424.86Zm0-68.62a30.64,30.64,0,1,0,30.64,30.64A30.68,30.68,0,0,0,285.44,356.24Z"
                                                          transform="translate(-173.42 -318.51)"/>
                                                    <circle className="cls-3" style={{fill: "#ee2d30"}} cx="112.02" cy="60.83" r="18.47"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="104.86" cy="46.89" r="6.03"/>
                                                    <circle className="cls-2" style={{fill: "#ffffff"}} cx="118.43" cy="50.66" r="3.02"/>
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("offline_devices")}</p>
                                                <span className="title text-black font-w600">{this.state.totalOfflineDevices}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-danger"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-danger"></div>
                                </Link>
                            </Col>
                            {this.state.permissionKeysList.includes('manage_clients') && <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/clients"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-9b59b6 mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <i className="flaticon-381-user-4" style={{fontSize: "48px"}}></i>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("total_clients")}</p>
                                                <span className="title text-black font-w600">{this.state.totalClients}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-9b59b6"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-9b59b6"></div>
                                </Link>
                            </Col>}
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/ad_campaigns"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-972a0b mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <i className="flaticon-381-promotion" style={{fontSize: "48px"}}></i>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("total_campaigns")}</p>
                                                <span className="title text-black font-w600">{this.state.totalCampaigns}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-972a0b"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-972a0b"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/promoter_route"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-0b9794 mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#0b9794" version="1.1" width="48" height="48" viewBox="0 0 24 24">
                                                    <path d="M3,4V12.5L6,9.5L9,13C10,14 10,15 10,15V21H14V14C14,14 14,13 13.47,12C12.94,11 12,10 12,10L9,6.58L11.5,4M18,4L13.54,8.47L14,9C14,9 14.93,10 15.47,11C15.68,11.4 15.8,11.79 15.87,12.13L21,7"></path>
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("promoter_routes")}</p>
                                                <span className="title text-black font-w600">{this.state.totalPromoterRoutes}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-0b9794"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-0b9794"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/promotions"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-00ced1 mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <i className="flaticon-381-smartphone-3" style={{fontSize: "48px"}}></i>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("total_promotions")}</p>
                                                <span className="title text-black font-w600">{this.state.totalPromotions}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-00ced1"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-00ced1"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/promotions"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-20b2aa mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <i className="flaticon-381-focus" style={{fontSize: "48px"}}></i>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("promotion_views")}</p>
                                                <span className="title text-black font-w600">{this.state.totalPromotionViews}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-20b2aa"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-20b2aa"></div>
                                </Link>
                            </Col>
                            <Col xs={12} sm={6} lg={4} xl={3}>
                                <Link to={"/promotions"} className="avtivity-card card">
                                    <Card.Body>
                                        <div className="media align-items-center">
                                            <div className="activity-icon bgl-8a2be2 mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                <i className="flaticon-381-internet" style={{fontSize: "48px"}}></i>
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-14 mb-2">{i18next.t("promotion_visits")}</p>
                                                <span className="title text-black font-w600">{this.state.totalPromotionVisits}</span>
                                            </div>
                                        </div>
                                        <div className="progress" style={{height: '5px'}}>
                                            <div className="progress-bar bg-8a2be2"
                                                 style={{width: '100%', height: '5px'}} role="progressbar"></div>
                                        </div>
                                    </Card.Body>
                                    <div className="effect bg-8a2be2"></div>
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Card>
                                    <Card.Header className={"d-flex flex-wrap"}>
                                        <Card.Title>{i18next.t("promotions")}</Card.Title>
                                        <div style={{zIndex: 2, position: "relative", width: "100%", maxWidth: "320px"}}>
                                            <Select value={this.state.promotionSelectOptionsSelected}
                                                    onChange={this.changePromotionData}
                                                    options={this.state.promotionSelectOptions}
                                            />
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xl={6}>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Card className="avtivity-card">
                                                            <Card.Body>
                                                                <div className="media align-items-center">
                                                                    <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                        <i className="flaticon-381-promotion" style={{fontSize: "40px"}}></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="fs-14 mb-2">{i18next.t("ad_campaigns")}</p>
                                                                        <span className="title text-black font-w600">{this.state.statisticsPromotions}</span>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            <div className="effect bg-danger"></div>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <Card className="avtivity-card">
                                                            <Card.Body>
                                                                <div className="media align-items-center">
                                                                    <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                        <i className="flaticon-381-focus" style={{fontSize: "40px"}}></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="fs-14 mb-2">{i18next.t("visits_scans")}</p>
                                                                        <span className="title text-black font-w600">{this.state.statisticsVisits}</span>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            <div className="effect bg-danger"></div>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <Card className="avtivity-card">
                                                            <Card.Body>
                                                                <div className="media align-items-center">
                                                                    <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                        <i className="flaticon-381-internet" style={{fontSize: "40px"}}></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="fs-14 mb-2">{i18next.t("website_visits")}</p>
                                                                        <span className="title text-black font-w600">{this.state.statisticsWebsiteVisit}</span>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            <div className="effect bg-danger"></div>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <Card className="avtivity-card">
                                                            <Card.Body>
                                                                <div className="media align-items-center">
                                                                    <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                        <i className="flaticon-381-video-clip" style={{fontSize: "40px"}}></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="fs-14 mb-2">{i18next.t("media_items")}</p>
                                                                        <span className="title text-black font-w600">{this.state.statisticsMedia}</span>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            <div className="effect bg-danger"></div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={6}>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>{i18next.t("number_of_visits")}</Card.Title>

                                                        {this.state.lineChartComponent}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>{i18next.t("recently_created_devices")}</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {this.state.recentlyCreatedDevicesData}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>{i18next.t("recently_created_promotions")}</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {this.state.recentlyCreatedPromotionsData}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>{i18next.t("recently_created_campaigns")}</Card.Title>
                                    </Card.Header>
                                    <Card.Body className={"plan-list"}>
                                        {this.state.recentlyCreatedCampaignsData}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>{i18next.t("recently_created_promoter_routes")}</Card.Title>
                                    </Card.Header>
                                    <Card.Body className={"plan-list"}>
                                        {this.state.recentlyCreatedPromoterRoutesData}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;