import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Row, Col, Spinner, Button, Card, Alert, Modal, Form} from "react-bootstrap";
import i18next from "i18next"
import {PostData, uploadFiles} from "../../api_key/PostData";
import {Chart, CategoryScale, LinearScale, PointElement, LineElement} from "chart.js";
import {Line} from 'react-chartjs-2';
import DataTable from "react-data-table-component";
import moment from "moment";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import qrcode from 'qrcode';
import {createCanvas} from 'canvas';
import Select from "react-select";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

class Promotions extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_promotions"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            dataLoader: true,
            addPromotionModal: false,
            editPromotionModal: false,
            showDeletePopup: false,
            statisticsModal: false,
            uuid: "",
            promotionsOpeAlert: {showAlert: false, variant: "", message: "",},
            promotions_logo: "",
            promotions_title: "",
            coupon_code: "",
            website: "",
            expiry_date: "",
            description: "",
            editPromotionUuid: "",
            lineChartData: {},
            statisticsPromotions: "",
            statisticsVisits: "",
            statisticsWebsiteVisit: "",
            statisticsMedia: "",
            lineChartComponent: "",
            backpackMediaLogo: false,
            cityOptions: [],
            cityOptionSelected: [],
        }

        this.onChangeInput = this.onChangeInput.bind(this)
        this.onChangeUploadFile = this.onChangeUploadFile.bind(this)
        this.loadPromotions = this.loadPromotions.bind(this)
        this.loadStatisticsData = this.loadStatisticsData.bind(this)
        this.submitPromotions = this.submitPromotions.bind(this)
        this.deletePromotion = this.deletePromotion.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.downloadQRCode = this.downloadQRCode.bind(this)
        document.title = this.props.pageTitle
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
        this.loadPromotions()
    }

    downloadQRCode(row) {
        let urlValue = window.location.protocol + "//" + window.location.host + "/p/" + row.promotion_code;
        const canvas = createCanvas(300, 300);

        qrcode.toCanvas(canvas, urlValue, {width: 1024, height: 1024}, (error) => {
            if (error) throw error;
            const dataURL = canvas.toDataURL();
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = "Promotion_QR.png";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    loadPromotions() {
        let formData = {}
        formData.uuid = this.state.sessionData.uuid;
        PostData("/frontCall/cities/getAll")
            .then(result => {
                this.setState({cityOptions: result.response.map(data => ({label: data.name, value: data.uuid}))})
            })
        PostData("frontCall/promotions/get_all", formData)
            .then(result => {
                if (result.response[0] !== null) {
                    this.setState({
                        clientsData: result.response,
                        columns: [
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("logo")}</h6>,
                                maxWidth: "60px",
                                selector: (row) => <img src={row.imageUrl} alt="" style={{width: "50px", height: "50px", borderRadius: "4px"}}/>,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("title")}</h6>,
                                maxWidth: "180px",
                                selector: (row) => row.title,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("description")}</h6>,
                                maxWidth: "180px",
                                selector: (row) => row.description,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("coupon_code")}</h6>,
                                maxWidth: "180px",
                                selector: (row) => row.coupon_code,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("website")}</h6>,
                                maxWidth: "180px",
                                selector: (row) => row.website_url,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("expiry_date")}</h6>,
                                maxWidth: "180px",
                                selector: (row) => moment(row.expiry_date).format("YYYY-MM-DD"),
                                sortable: true
                            },
                            {
                                name: <h6
                                    className="font-weight-bold">{i18next.t("download") + " " + i18next.t("qr")}</h6>,
                                maxWidth: "140px",
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    this.downloadQRCode(row)
                                }}>
                                    <em className="flaticon-381-app"/>
                                </Button>,
                                center: true,
                            },
                            ...(this.state.granularPermissions.includes('u')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                                maxWidth: "80px",
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    if (row.city_id !== null)
                                        this.setState({cityOptionSelected: {label: row.city.name, value: row.city.uuid}})
                                    this.setState({
                                        editPromotionModal: true,
                                        addPromotionModal: true,
                                        editPromotionUuid: row.uuid,
                                        coupon_code: row.coupon_code,
                                        description: row.description,
                                        expiry_date: row.expiry_date,
                                        promotions_title: row.title,
                                        backpackMediaLogo: parseInt(row.show_bac_logo) === 1,
                                        website: row.website_url,
                                    })
                                }}>
                                    <em className="flaticon-381-edit-1"/>
                                </Button>,
                                center: true,
                            }] : [],
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("statistics")}</h6>,
                                maxWidth: "110px",
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    this.loadStatisticsData(row.uuid)
                                    this.setState({
                                        statisticsModal: true,
                                    })
                                }}>
                                    <em className="fa fa-bar-chart"/>
                                </Button>,
                                center: true,
                            },
                            ...(this.state.granularPermissions.includes('d')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                                maxWidth: "80px",
                                cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                    this.setState({
                                        showDeletePopup: true,
                                        uuid: row.uuid
                                    })
                                }}>
                                    <em className="flaticon-381-trash-1"/>
                                </Button>,
                                center: true,
                            }] : [],
                        ],
                    })
                }
            })
    }

    loadStatisticsData(promotionUuid) {
        PostData("frontCall/promotions/promotion_tracking", {uuid: promotionUuid})
            .then(result => {
                let chartData = {}
                let chartDataLabels = []
                let chartDataData = []
                result.response.promotion_tracking.map((data, index) => {
                    chartDataLabels[index] = data.date
                    chartDataData[index] = parseInt(data.count)
                })

                chartData = {
                    labels: chartDataLabels,
                    datasets: [{
                        label: "First dataset",
                        data: chartDataData,
                        fill: true,
                        backgroundColor: "rgba(75,192,192,0.2)",
                        borderColor: "rgba(75,192,192,1)"
                    }]
                }
                if (result.status === true) {
                    this.setState({
                        statisticsPromotions: result.response.total_campaigns,
                        statisticsVisits: result.response.page_visits,
                        statisticsWebsiteVisit: result.response.vendor_website_visits,
                        statisticsMedia: result.response.total_media,
                        lineChartComponent: <Line data={chartData}/>,
                    })
                }
            })
    }

    submitPromotions() {
        const formData = new FormData();
        {
            this.state.promotions_logo !== "" && formData.append("logo_image", this.state.promotions_logo)
        }
        formData.append("title", this.state.promotions_title)
        formData.append("description", this.state.description)
        formData.append("coupon_code", this.state.coupon_code)
        formData.append("website_url", this.state.website)
        formData.append("expiry_date", this.state.expiry_date)
        formData.append("show_bac_logo", this.state.backpackMediaLogo ? 1 : 0)
        formData.append("city_id", this.state.cityOptionSelected.value)
        let apiCall = ""
        if (this.state.editPromotionModal === true) {
            apiCall = "frontCall/promotions/update_promotions"
            formData.append("uuid", this.state.editPromotionUuid)
        } else {
            apiCall = "frontCall/promotions/save"
        }
        uploadFiles(apiCall, formData)
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        promotionsOpeAlert: {
                            showAlert: true,
                            variant: "danger",
                            message: result.messages.message,
                        },
                    })
                } else if (result.status === true) {
                    this.setState({
                        addPromotionModal: false,
                        promotionsOpeAlert: {
                            showAlert: true,
                            variant: "success",
                            message: result.response,
                        },
                    })
                    this.loadPromotions()
                    setTimeout(() => this.handleClose(), 5000);
                }
            })
    }

    deletePromotion() {
        PostData("frontCall/promotions/remove_promotions", {uuid: this.state.uuid})
            .then(result => {
                if (result.status === 400) {
                    this.setState({
                        promotionsOpeAlert: {
                            showAlert: true,
                            variant: "danger",
                            message: result.messages.message,
                        },
                    })
                } else if (result.status === true) {
                    this.setState({
                        showDeletePopup: false,
                        promotionsOpeAlert: {
                            showAlert: true,
                            variant: "success",
                            message: result.response,
                        },
                    })
                    this.loadPromotions()
                    setTimeout(() => this.handleClose(), 5000);
                }
            })
    }

    onChangeInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onChangeUploadFile(event) {
        this.setState({
            promotions_logo: event.target.files[0]
        })
    }

    handleClose() {
        this.setState({
            addPromotionModal: false,
            editPromotionModal: false,
            showDeletePopup: false,
            statisticsModal: false,
            uuid: "",
            promotionsOpeAlert: {showAlert: false, variant: "", message: "",},
            promotions_logo: "",
            promotions_title: "",
            coupon_code: "",
            website: "",
            expiry_date: "",
            description: "",
            editPromotionUuid: "",
            lineChartData: {},
            statisticsPromotions: "",
            statisticsVisits: "",
            statisticsWebsiteVisit: "",
            statisticsMedia: "",
            lineChartComponent: "",
            backpackMediaLogo: false,
            cityOptionSelected: [],
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        <div className="text-right mb-3">
                            {(this.state.granularPermissions.includes('c')) && <Button variant={"primary"} className="mb-2 ml-2" onClick={() => {
                                this.setState({
                                    promotionsOpeAlert: {
                                        showAlert: false,
                                        variant: "",
                                        message: "",
                                    },
                                    addPromotionModal: true
                                })
                            }}>
                                <em className="fa fa-plus"/> {i18next.t("add_promotion")}
                            </Button>}
                            <Modal show={this.state.addPromotionModal} onHide={this.handleClose}
                                   backdrop={"static"} centered keyboard={false}>
                                <Modal.Header className="modal-header">
                                    <Modal.Title>{this.state.editPromotionModal ? i18next.t("edit_promotion") : i18next.t("add_promotion")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert show={this.state.promotionsOpeAlert.showAlert}
                                           variant={this.state.promotionsOpeAlert.variant}>
                                        <h6 className="m-0 font-weight-bold">{this.state.promotionsOpeAlert.message}</h6>
                                    </Alert>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="promotions_logo">
                                            <Form.Label>{i18next.t("logo")}</Form.Label>
                                            <Form.Control type="file" name="promotions_logo"
                                                          onChange={this.onChangeUploadFile}
                                                          placeholder={i18next.t("enter") + " " + i18next.t("logo")}
                                                          className={"py-2 h-auto"}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="promotions_title">
                                            <Form.Label>{i18next.t("title")}</Form.Label>
                                            <Form.Control type="text" name="promotions_title"
                                                          value={this.state.promotions_title}
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("enter_title")}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="description">
                                            <Form.Label>{i18next.t("description")}</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="description"
                                                          value={this.state.description}
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("enter_description")}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="coupon_code">
                                            <Form.Label>{i18next.t("coupon_code")}</Form.Label>
                                            <Form.Control type="text" name="coupon_code"
                                                          value={this.state.coupon_code}
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("enter_coupon_code")}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="website">
                                            <Form.Label>{i18next.t("website")}</Form.Label>
                                            <Form.Control type="text" name="website"
                                                          value={this.state.website}
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("enter_website_address")}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="selectCity">
                                            <Form.Label>{i18next.t("select_city")}</Form.Label>
                                            <Select value={this.state.cityOptionSelected}
                                                    onChange={(cityOptionSelected) => {
                                                        this.setState({cityOptionSelected}, () =>
                                                            this.state.cityOptionSelected
                                                        );}}
                                                    options={this.state.cityOptions}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="expiry_date">
                                            <Form.Label>{i18next.t("expiry_date")}</Form.Label>
                                            <Form.Control type="date" name="expiry_date"
                                                          value={this.state.expiry_date}
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("enter") + " " + i18next.t("expiry_date")}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3 shuffleMusic" controlId="shuffleMusic">
                                            <BootstrapSwitchButton
                                                checked={this.state.backpackMediaLogo}
                                                width={140}
                                                height={40}
                                                onlabel={i18next.t("yes")}
                                                offlabel={i18next.t("no")}
                                                onChange={(checked) => {
                                                    this.setState({backpackMediaLogo: checked})
                                                }}
                                            />
                                            <Form.Label
                                                className={"ml-2"}>{i18next.t("backpack_media_logo")}</Form.Label>
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                    <Button variant={"primary"} onClick={this.submitPromotions}>{i18next.t("save_changes")}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.showDeletePopup} onHide={this.handleClose}
                                   backdrop={"static"} centered keyboard={false}>
                                <Modal.Header className="justify-content-center">
                                    <Modal.Title>
                                        <Alert show={this.state.promotionsOpeAlert.showAlert} variant={this.state.promotionsOpeAlert.variant}>
                                            <h6 className="m-0 font-weight-bold">{this.state.promotionsOpeAlert.message}</h6>
                                        </Alert>
                                        {i18next.t("remove_device_modal")}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Footer className="justify-content-center">
                                    <Button variant={"primary"} onClick={this.deletePromotion}>{i18next.t("yes")}</Button>
                                    <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.statisticsModal} onHide={this.handleClose}
                                   size={"xl"} backdrop={"static"} centered keyboard={false}>
                                <Modal.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <Card className="avtivity-card">
                                                        <Card.Body>
                                                            <div className="media align-items-center">
                                                                <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                    <i className="flaticon-381-promotion" style={{fontSize: "40px"}}></i>
                                                                </div>
                                                                <div className="media-body">
                                                                    <p className="fs-14 mb-2">{i18next.t("ad_campaigns")}</p>
                                                                    <span className="title text-black font-w600">{this.state.statisticsPromotions}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        <div className="effect bg-danger"></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Card className="avtivity-card">
                                                        <Card.Body>
                                                            <div className="media align-items-center">
                                                                <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                    <i className="flaticon-381-focus" style={{fontSize: "40px"}}></i>
                                                                </div>
                                                                <div className="media-body">
                                                                    <p className="fs-14 mb-2">{i18next.t("visits_scans")}</p>
                                                                    <span className="title text-black font-w600">{this.state.statisticsVisits}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        <div className="effect bg-danger"></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Card className="avtivity-card">
                                                        <Card.Body>
                                                            <div className="media align-items-center">
                                                                <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                    <i className="flaticon-381-internet" style={{fontSize: "40px"}}></i>
                                                                </div>
                                                                <div className="media-body">
                                                                    <p className="fs-14 mb-2">{i18next.t("website_visits")}</p>
                                                                    <span className="title text-black font-w600">{this.state.statisticsWebsiteVisit}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        <div className="effect bg-danger"></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Card className="avtivity-card">
                                                        <Card.Body>
                                                            <div className="media align-items-center">
                                                                <div className="activity-icon bgl-danger mr-md-4 mr-3 d-flex align-items-center justify-content-center">
                                                                    <i className="flaticon-381-video-clip" style={{fontSize: "40px"}}></i>
                                                                </div>
                                                                <div className="media-body">
                                                                    <p className="fs-14 mb-2">{i18next.t("media_items")}</p>
                                                                    <span className="title text-black font-w600">{this.state.statisticsMedia}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        <div className="effect bg-danger"></div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>{i18next.t("number_of_visits")}</Card.Title>

                                                    {this.state.lineChartComponent}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.promotionsOpeAlert.showAlert} variant={this.state.promotionsOpeAlert.variant}>
                                            <h6 className="m-0 font-weight-bold">{this.state.promotionsOpeAlert.message}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.clientsData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Promotions;