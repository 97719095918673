// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBG7TCobbomGm8tGu01XFKtBGVJzHRruRQ",
    authDomain: "backpackmedia.firebaseapp.com",
    projectId: "backpackmedia",
    storageBucket: "backpackmedia.appspot.com",
    messagingSenderId: "438695302697",
    appId: "1:438695302697:web:4ee9b6ff0cda223d536e38",
    measurementId: "G-C7V8HBZ5LE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging()
export const requestForToken = () => {
    try {
        if (messaging) {
            Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        registerServiceWorker();
                    } else {
                        console.log('Notification permission denied.');
                    }
                }
            );
        }
    } catch (err) {
        console.error('Failed to initialize Firebase Messaging', err);
    }

    function registerServiceWorker() {
        if (messaging) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                scope: '/'
            }) // Path to your firebase-messaging-sw.js file
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                    return getToken(messaging, {
                        vapidKey: "BK1v67ZxDsAIo-dr01b78PjrFAOupCVT3Zw1gJ5ZLzLFVCKZ7XJ9qR7Hu2vVrT5hoQPCIDnO7Yq9LNrQ96tvaog",
                        serviceWorkerRegistration: registration,
                    }).then((currentToken) => {
                        if (currentToken) {
                            console.log('token:', currentToken);
                            localStorage.setItem('deviceToken', currentToken);
                            return currentToken;
                        } else {
                            console.log('No registration token available after permission granted.');
                            return null;
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                        return null;
                    });
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }
};
//foreground message
onMessage(messaging, (payload) => {
    if (payload) {
        showNotification(payload);
    }
});

const showNotification = (payload) => {
    if (payload && payload.data.data_key === "status_changed") {
        let dataValue = JSON.parse(payload.data.data_value);
        let status = dataValue.status.toString().toLowerCase();
        let sound = new Audio(`/assets/sounds/${status}.wav`);
        sound.play();
        const channel = new BroadcastChannel('backpack-media-fcm-push');
        // Send the message to all clients listening on this channel
        channel.postMessage(dataValue);
    }
}

