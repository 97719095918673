import React from 'react';
import { NavLink } from 'react-router-dom';

const LeftHeaderLogo = () => {
    const toggleSidebar = () => {
        document.getElementById("main-wrapper").classList.toggle("menu-toggle")
        document.getElementById("hamburger").classList.toggle("is-active")
    }

    return (
        <div className="nav-header">
            <NavLink to="/dashboard" className="brand-logo">
                <img className="logo-abbr web" src="/assets/images/logo.svg" alt=""/>
                <img className="logo-abbr mobile" src="/assets/images/logo-responsive.png" alt=""/>
                <img className="logo-compact" src="/assets/images/logo-text.svg" alt=""/>
                <img className="brand-title" src="/assets/images/logo-text.svg" alt=""/>
            </NavLink>

            <div className="nav-control" onClick={toggleSidebar}>
                <div className="hamburger" id="hamburger">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
        </div>
    )
}

export default LeftHeaderLogo;