import React from "react";
import {Card, Button, Modal, Form, Alert, Spinner, ProgressBar} from "react-bootstrap";
import Dropzone from "react-dropzone";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import i18next from "i18next";
import {PostData, uploadFiles} from "../../api_key/PostData";

class APKUpload extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_apk"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            opeAlert: false,
            opeAlertVariant: "",
            opeAlertMessage: "",
            uploadAplAlert: false,
            uploadAplAlertVariant: "",
            uploadAplAlertMessage: "",
            accepted: [],
            rejected: [],
            uploadFileContent: [],
            dataLoader: true,
            uploadApkModal: false,
            noFileUploaded: "",
            mediaUploadPopup: false,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.submitApk = this.submitApk.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        PostData("/frontCall/apk_uploads/get")
            .then(result => {
                if(result.status === false) {
                    this.setState({
                        noFileUploaded: true,
                        noFileUploadedMessage: <p className={"mb-2 text-danger"}>{result.response}</p>,
                    })
                } else {
                    this.setState({
                        noFileUploaded: false,
                        noFileUploadedMessage: <>
                            <p className={"mb-2"}>Version : {result.response.version_code}</p>
                            <p className={"mb-2"}>
                                Uploaded on <span className="font-weight-bold">{result.response.created}</span>.
                            </p>
                        </>
                    })
                }
            })
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    onDrop = (accepted, rejected) => {
        accepted.filter(file => file.type === 'application/vnd.android.package-archive').length > 0
            ? this.setState({
                dropUploadFile: "",
                accepted: accepted.filter(file => file.type === 'application/vnd.android.package-archive'),
                rejected: rejected,
                uploadFileContent: accepted[0],
            })
            : this.setState({
                dropUploadFile: i18next.t("upload_only_apk_file"),
                accepted: [],
                rejected: [],
                uploadFileContent: undefined,
            })
    }

    submitApk() {
        this.setState({dropUploadFile: "", uploadAplAlert: false, mediaUploadPopup: true})
        if(this.state.uploadFileContent === undefined) {
            this.setState({dropUploadFile: i18next.t("drop_upload_file"), mediaUploadPopup: false})
        } else {
            const formData = new FormData();
            formData.append("apk_file", this.state.uploadFileContent)
            uploadFiles("/frontCall/apk_uploads/upload", formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            uploadAplAlert: true,
                            uploadAplAlertVariant: "success",
                            uploadAplAlertMessage: result.response
                        })
                        setTimeout(() => {
                            this.handleClose()
                        }, 2000);
                    } else if (result.status === 400) {
                        this.setState({
                            uploadAplAlert: true,
                            uploadAplAlertVariant: "danger",
                            uploadAplAlertMessage: result.messages.message,
                            noFileUploaded: false,
                        })
                        setTimeout(() => {
                            // this.handleClose()
                        }, 2000);
                    }else {
                        this.setState({
                            uploadAplAlert: true,
                            uploadAplAlertVariant: "danger",
                            uploadAplAlertMessage: result.message,
                            noFileUploaded: false,
                        })
                        setTimeout(() => {
                            // this.handleClose()
                        }, 2000);
                    }
                    this.setState({mediaUploadPopup: false})
                    window.location.reload()
                })
        }
    }

    handleClose() {
        this.setState({
            opeAlert: false,
            opeAlertVariant: "",
            opeAlertMessage: "",
            uploadAplAlert: false,
            uploadAplAlertVariant: "",
            uploadAplAlertMessage: "",
            accepted: [],
            rejected: [],
            uploadApkModal: false,
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        <Modal show={this.state.uploadApkModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Upload new APK file</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="w-100">
                                    <Alert show={this.state.uploadAplAlert} variant={this.state.uploadAplAlertVariant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.uploadAplAlertMessage}</h6>
                                    </Alert>
                                    <Form name="uploadApkForm" id="uploadApkForm">
                                        <Dropzone
                                            onDrop={this.onDrop}
                                            multiple={false}
                                            accept=".apk"
                                        >
                                            {({getRootProps, getInputProps, isDragActive}) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} name="fileUpload" id="fileUpload"/>
                                                    {isDragActive
                                                        ? <>Allow only ONE file to upload. <br/> Allow only APK files to upload.</>
                                                        : "Click me or drag a file to upload!"}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Form>
                                    {this.state.dropUploadFile &&
                                        <div className="text-danger pt-1">{this.state.dropUploadFile}</div>}
                                    {(this.state.accepted.length > 0) && <aside className={"mt-3"}>
                                        <h4>File</h4>
                                        <ul>
                                            {this.state.accepted.map(file => (
                                                <li className={"d-flex align-items-center justify-content-between"} key={file.path}>
                                                    <span>{file.path} - {file.size} bytes</span>
                                                    <Button variant={"danger"} onClick={() => {
                                                        this.setState({accepted: [], uploadFileContent: undefined})
                                                    }}><em className="flaticon-381-trash-1"/></Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </aside>}
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="text-center">
                                <Button variant="primary" className="line-height-1" onClick={this.submitApk}>Upload</Button>
                                <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={this.state.mediaUploadPopup} centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("please_wait_app_is_uploading")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProgressBar style={{height: 30, fontSize: 15}} animated
                                             label={i18next.t("uploading_media")} now={100}/>
                            </Modal.Body>
                        </Modal>

                        <Alert show={this.state.opeAlert} variant={this.state.opeAlertVariant} className="rounded-lg">
                            <h6 className="m-0 font-weight-bold">{this.state.opeAlertMessage}</h6>
                        </Alert>

                        <Card className={"text-center"}>
                            <Card.Body>
                                <h4 className="mb-3 mx-auto" style={{maxWidth: "576px"}}>
                                    {i18next.t("upload_apk_message")}
                                </h4>
                                <img src="/assets/images/apk-extractor.svg" alt="" className={"mb-2"}/>
                                {this.state.noFileUploadedMessage}
                                <Button variant="primary" className="mb-2 line-height-1" onClick={() => {
                                    this.setState({uploadApkModal: true})
                                }}>{this.state.noFileUploaded === true ? i18next.t("upload_new") : i18next.t("replace")}</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default APKUpload;