import React from 'react'
import './style.css';
import $ from "jquery";
import i18next from "i18next"
import {PostDataRaw} from "../api_key/PostData";
import {Alert, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import ButtonMailto from "../Common/ButtonMailto";
import {Helmet} from "react-helmet";

class Contacts extends React.Component {
    constructor(props) {
        let cityCookie = {}
        if (localStorage.getItem('selectedCity') !== null) {
            cityCookie = JSON.parse(localStorage.getItem('selectedCity'));
        }
        super(props);
        this.state = {
            cityCookieValue: cityCookie,
            get_an_offer_nameErr: "",
            get_an_offer_telephoneErr: "",
            get_an_offer_emailErr: "",
            contactSuccess: {
                show: false,
                variant: "",
                message: "",
            }
        }

        this.getAnOffer = this.getAnOffer.bind(this)
        this.handleSubmitContactForm = this.handleSubmitContactForm.bind(this)
    }


    handleSubmitContactForm() {
        let get_an_offer_name = $("#get_an_offer-name")
        let get_an_offer_telephone = $("#get_an_offer-telephone")
        let get_an_offer_email = $("#get_an_offer-email")

        if (get_an_offer_name.val() === "" || get_an_offer_telephone.val() === "" || get_an_offer_email.val() === "") {
            if (get_an_offer_name.val() === "") {
                this.setState({get_an_offer_nameErr: "Enter Name"})
            } else {
                this.setState({get_an_offer_nameErr: ""})
            }
            if (get_an_offer_telephone.val() === "") {
                this.setState({get_an_offer_telephoneErr: "Enter Telephone"})
            } else {
                this.setState({get_an_offer_telephoneErr: ""})
            }
            if (get_an_offer_email.val() === "") {
                this.setState({get_an_offer_emailErr: "Enter Email"})
            } else {
                this.setState({get_an_offer_emailErr: ""})
            }
        } else {
            let formData = {
                name: get_an_offer_name.val(),
                phone: get_an_offer_telephone.val(),
                email: get_an_offer_email.val(),
                city_uuid: this.state.cityCookieValue.uuid,
                city_name: this.state.cityCookieValue.name,
                zipcode: this.state.cityCookieValue.zipcode,
                contact_us_email: this.state.cityCookieValue.contact_us_email,
                contact_us_phone: this.state.cityCookieValue.contact_us_phone,
                latitude: this.state.cityCookieValue.latitude,
                longitude: this.state.cityCookieValue.longitude,
            }

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LdWWfUjAAAAADRniczJganZpLrcafarzJHbSqlz', {action: 'submit'}).then(token => {
                    PostDataRaw('/frontCall/contactus', {
                        ...formData,
                        googleRecaptchaResponse: token
                    })
                        .then(result => {
                            if(result.response === "Email sent successfully.") {
                                get_an_offer_name.val('')
                                get_an_offer_telephone.val('')
                                get_an_offer_email.val('')
                                this.setState({
                                    get_an_offer_nameErr: "",
                                    get_an_offer_telephoneErr: "",
                                    get_an_offer_emailErr: "",
                                    contactSuccess: {
                                        show: true,
                                        variant: "success",
                                        message: i18next.t("email_sent_successfully"),
                                    }
                                })
                                setTimeout(() => {
                                    this.setState({
                                        contactSuccess: {
                                            show: false,
                                            variant: "",
                                            message: "",
                                        }
                                    })
                                }, 3000);
                            }
                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            });
        }
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("light-page").removeClass("dark-page")
        $("#main-sections").attr("style", "padding-top: " + (document.getElementById("sticky-top").offsetHeight + 16) + "px")
        $("#sticky-top").addClass("sticky-top")
        $(".header-bar").removeClass("active-nav").addClass("container")
        $(".burger-menu").removeClass("pointer-opacity")
    }

    getAnOffer() {
        let get_an_offer_name = $("#get_an_offer-name")
        let get_an_offer_telephone = $("#get_an_offer-telephone")
        let get_an_offer_email = $("#get_an_offer-email")
        if (get_an_offer_name.val() === "") {
            this.setState({get_an_offer_nameErr: "Enter Name"})
        } else {
            this.setState({get_an_offer_nameErr: ""})
        }
        if (get_an_offer_telephone.val() === "") {
            this.setState({get_an_offer_telephoneErr: "Enter Telephone"})
        } else {
            this.setState({get_an_offer_telephoneErr: ""})
        }
        if (get_an_offer_email.val() === "") {
            this.setState({get_an_offer_emailErr: "Enter Email"})
        } else {
            this.setState({get_an_offer_emailErr: ""})
        }
        if (get_an_offer_name.val() !== "" && get_an_offer_telephone.val() !== "" && get_an_offer_email.val() !== "") {
            $(".get-an-offer-button").removeAttr("disabled")
        } else if (get_an_offer_name.val() !== "" || get_an_offer_telephone.val() !== "" || get_an_offer_email.val() !== "") {
            $(".get-an-offer-button").addClass("filling-data").attr("disabled", "disabled")
        } else {
            $(".get-an-offer-button").removeClass("filling-data").attr("disabled", "disabled")
        }
    }

    render() {
        return (<>
            <Helmet>
                <title>{this.props.pageTitle}</title>
                <meta name="description"
                      content="Наша компания предлагает услуги размещения рекламы на рюкзаках с FULL HD экранами. Это инновационное решение позволит вашей рекламе быть видимой и запоминающейся, ведь она будет всегда на виду у прохожих. Свяжитесь с нами уже сегодня, и мы поможем вам создать эффективную рекламную кампанию!."/>
            </Helmet>
            <section id="contacts" className="section contact-section container">
                <h1 className="about-format-title contact-title">{i18next.t("contact_page_h1")}</h1>
                <div className="contact-section-content">
                    <div className="contact-content-left">
                        <h6 className="promoters-content-title" style={{margin: "0 0 8px 0"}}>{i18next.t("draw_attention_to_your_brand")}</h6>
                        <h6 className="promoters-content-title" style={{margin: "0 0 8px 0"}}>{i18next.t("our_company_offers_advertising_services")}</h6>
                        <h6 className="promoters-content-title" style={{margin: "0 0 8px 0"}}>{i18next.t("allow_advertising_to_visible_and_memorable")}</h6>
                        <h6 className="promoters-content-title" style={{margin: "0 0 8px 0"}}>{i18next.t("don_t_miss_the_chance_to_attract_new_customers")}</h6>
                        <h6 className="promoters-content-title" style={{margin: "0 0 24px 0"}}>{i18next.t("contact_us_today")}</h6>
                        <h2 className="contact-content-title">backpackmedia.ru</h2>
                        <h2 className="contact-content-title">
                            <ButtonMailto
                                label={(Object.keys(this.state.cityCookieValue).length > 0)
                                    ? this.state.cityCookieValue.contact_us_email
                                    : "info@backpackmedia.ru"}
                                mailto={(Object.keys(this.state.cityCookieValue).length > 0)
                                    ? "mailto:" + this.state.cityCookieValue.contact_us_email
                                    : "mailto:info@backpackmedia.ru"}/>
                        </h2>
                        <h2 className="contact-content-title">
                            <ButtonMailto
                                label={(Object.keys(this.state.cityCookieValue).length > 0)
                                    ? this.state.cityCookieValue.contact_us_phone
                                    : "+7 495 789-39-37"}
                                mailto={(Object.keys(this.state.cityCookieValue).length > 0)
                                    ? "tel:" + this.state.cityCookieValue.contact_us_phone
                                    : "tel:+7 495 789-39-37"} />
                        </h2>
                    </div>
                    <div className="contact-content-right">
                        <Alert show={this.state.contactSuccess.show} variant={this.state.contactSuccess.variant} className="rounded-lg">
                            <h6 className="m-0 font-weight-bold">{this.state.contactSuccess.message}</h6>
                        </Alert>
                        <Form className="get-an-offer-form">
                            <div className="get-an-offer-form-group">
                                <label htmlFor="get_an_offer-name" className="get-an-offer-form-label">{i18next.t("name")}</label>
                                <label htmlFor="get_an_offer-name" className="get_an_offer-error_label">{this.state.get_an_offer_nameErr && i18next.t("name")}</label>
                                <input type="text" id="get_an_offer-name" name="get_an_offer-name" className="get-an-offer-form-control"
                                       placeholder={i18next.t("name")} onKeyUp={this.getAnOffer}/>
                            </div>
                            <div className="get-an-offer-form-group">
                                <label htmlFor="get_an_offer-telephone" className="get-an-offer-form-label">{i18next.t("telephone")}</label>
                                <label htmlFor="get_an_offer-name" className="get_an_offer-error_label">{this.state.get_an_offer_telephoneErr && i18next.t("telephone")}</label>
                                <input type="text" id="get_an_offer-telephone" name="get_an_offer-telephone" className="get-an-offer-form-control"
                                       placeholder={i18next.t("telephone")} onKeyUp={this.getAnOffer}/>
                            </div>
                            <div className="get-an-offer-form-group">
                                <label htmlFor="get_an_offer-email" className="get-an-offer-form-label">Email</label>
                                <label htmlFor="get_an_offer-name" className="get_an_offer-error_label">{this.state.get_an_offer_emailErr && i18next.t("email")}</label>
                                <input type="text" id="get_an_offer-email" name="get_an_offer-email" className="get-an-offer-form-control"
                                       placeholder={i18next.t("email")} onKeyUp={this.getAnOffer}/>
                            </div>
                            <div className="form-button-block">
                                <button type={"button"} className="get-an-offer-button" onClick={this.handleSubmitContactForm}>{i18next.t("get_an_offer")}</button>
                            </div>
                            <p className={"text-center mt-3"}>{i18next.t("agree_processing_of_personal_data")} <Link to={"/privacy_policy"} style={{textDecoration: "underline"}}>{i18next.t("privacy_policy")}</Link></p>
                            <GoogleReCaptchaProvider
                                reCaptchaKey="6LdWWfUjAAAAADRniczJganZpLrcafarzJHbSqlz"
                                scriptProps={{
                                    async: false, // optional, default to false,
                                    defer: false, // optional, default to false
                                    appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                    nonce: undefined // optional, default undefined
                                }}
                            ></GoogleReCaptchaProvider>
                        </Form>

                    </div>
                </div>
            </section>
        </>)
    }
}

export default Contacts;