import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Row, Col, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom"
import i18next from "i18next";
import {PostData} from "../../api_key/PostData";

class AddPromoterRoute extends React.Component {
    constructor(props) {
        super(props);
        const queryParameters = new URLSearchParams(window.location.search)
        let permissionKey = "manage_promoter_routes"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            dataLoader: true,
            route_uuid: queryParameters.get("route_uuid"),
            iframeContent: "",
        }
        document.title = this.props.pageTitle
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        PostData(this.state.route_uuid ? ('/frontCall/promoter_routes/promoter_route/' + i18next.language + '?uuid=' + this.state.route_uuid + '&isEdit=' + this.props.isEdit) : '/frontCall/promoter_routes/promoter_route/' + i18next.language + '?isEdit=' + this.props.isEdit, {uuid: this.state.sessionData.uuid})
            .then(result => {
                this.setState({iframeContent: result})
            });
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid h-100">
                        <Link to={"/promoter_route"} className="mb-2">
                            <em className="fa fa-arrow-left"></em>
                        </Link>
                        <Row style={{height: "calc(100% - 36px)"}}>
                            <Col xs={12}>
                                <iframe
                                    srcDoc={this.state.iframeContent}
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    title='video'
                                    className="w-100 h-100"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default AddPromoterRoute;