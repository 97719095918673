import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import $ from "jquery";
import i18next from "i18next"
import {Helmet} from "react-helmet";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
            },
            offset: 0,
        }
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("light-page").removeClass("dark-page")
        $("#sticky-top").addClass("sticky-top")
        $(".header-bar").removeClass("active-nav").addClass("container")
        $(".burger-menu").removeClass("pointer-opacity")
    }

    render() {
        return (<>
            <Helmet>
                <title>{this.props.pageTitle}</title>
                <meta name="description"
                      content="Baсkpaсk Mеdiа – рекламa на pюкзакаx со встpоенными Full HD экpaнaми. Подробная статистика онлайн по показам, ПРОСМОТРАМ и передвижению промоутера. B любой локации : наши промоутеры мoгут наxoдиться вeздe, гдe может прoйти челoвeк. Нoвый фоpмат pеклaмы пpивлeкaет внимание, ee снимают и делятся в соцсетяx."/>
            </Helmet>
            <section id="about_advertising_format" className="section about-format-section container">
                <h1 className="about-format-title">{i18next.t("about_page_h1")}</h1>
                <div className="afs-content">
                    <div className="afs-content-left">
                        <h6 className="afs-content-title">{i18next.t("backpackmedia_innovative_urban_advertising")}</h6>
                        <h6 className="afs-content-title">{i18next.t("backpacks_built_in_screens_to_broadcast")}</h6>
                        <div className="afs-content-right-image-section">
                            <div className="about-content-right-slider">
                                <Slider {...this.state.settings}>
                                    <div>
                                        <img src="/assets/web-images/about-slider-1.jpg" alt=""/>
                                    </div>
                                    <div>
                                        <img src="/assets/web-images/about-slider-2.jpg" alt=""/>
                                    </div>
                                    <div>
                                        <img src="/assets/web-images/about-slider-3.jpg" alt=""/>
                                    </div>
                                    <div>
                                        <img src="/assets/web-images/about-slider-4.jpg" alt=""/>
                                    </div>
                                    <div>
                                        <img src="/assets/web-images/about-slider-5.jpg" alt=""/>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="afs-content-detail">
                            <ul className="afs-content-detail-list">
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-1.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("the_viral_effect_of_advertising")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-2.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("ability_to_view_in_real_time_the_location")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-3.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("ability_to_change_advertising_content_online")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-4.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("one_backpack_one_advertiser")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-5.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("measured_offline_advertising")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-6.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("choice_of_an_individual_route")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-7.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("high_image_quality")}
                                    </span>
                                </li>
                                <li className="afs-content-list-item">
                                    <img src="/assets/web-images/about-ad-8.svg" alt=""/>
                                    <span className="afs-content-list-text">
                                        {i18next.t("quick_launch_of_an_advertising_campaign")}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="afs-content-right">
                        <div className="about-content-right-slider">
                            <Slider {...this.state.settings}>
                                <div>
                                    <img src="/assets/web-images/about-slider-1.jpg" alt=""/>
                                </div>
                                <div>
                                    <img src="/assets/web-images/about-slider-2.jpg" alt=""/>
                                </div>
                                <div>
                                    <img src="/assets/web-images/about-slider-3.jpg" alt=""/>
                                </div>
                                <div>
                                    <img src="/assets/web-images/about-slider-4.jpg" alt=""/>
                                </div>
                                <div>
                                    <img src="/assets/web-images/about-slider-5.jpg" alt=""/>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}

export default About;