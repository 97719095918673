import CryptoJS from 'crypto-js';

function logout() {
    localStorage.removeItem('loginDetails');
    window.location = '/login'
}

// Function to encrypt data using the provided key
const encryptData = (data) => {
    let encKey = localStorage.getItem('sharedKey')
    if (encKey) {
        // Convert the key from a string to a WordArray
        const keyWordArray = CryptoJS.enc.Base64.parse(encKey);
        // Generate an initialization vector (IV)
        const iv = CryptoJS.lib.WordArray.random(16); // 128 bits
        //Check if data has files.
        if (data instanceof FormData) {
            const newData = new FormData();
            for (const [key, value] of data.entries()) {
                if (value instanceof File) {
                    newData.append(key, value);
                } else {
                    // Assuming `encryptData` is a function that encrypts the data
                    newData.append(key, encryptData(value));
                }
            }
            data = newData;
        } else {
            if (data instanceof Object) {
                data = JSON.stringify(data);
            }
            // Encrypt the data using AES-256-CBC mode
            const encryptedBytes = CryptoJS.AES.encrypt(data, keyWordArray, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            // Combine the IV and encrypted data for storage or transmission
            data = iv.concat(encryptedBytes.ciphertext).toString(CryptoJS.enc.Base64);
        }


        return data;
    } else {
        logout();
    }
}
const decryptData = (encryptedData) => {
    if (isNotJsonString(encryptedData)) {
        let encKey = localStorage.getItem('sharedKey');
        if (encKey) {
            // Convert the key from a string to a WordArray
            const keyWordArray = CryptoJS.enc.Base64.parse(encKey);

            // Convert the parsed base64 data to a WordArray
            const encryptedDataWordArray = CryptoJS.enc.Base64.parse(encryptedData);

            // Extract the IV from the beginning of the encrypted data
            const ivSize = 16; // 128 bits
            const iv = encryptedDataWordArray.clone();
            iv.sigBytes = ivSize;
            iv.clamp();

            // Clone the encryptedDataWordArray and manipulate it to get encryptedDataWithoutIv
            const encryptedDataWithoutIv = encryptedDataWordArray.clone();
            encryptedDataWithoutIv.words.splice(0, ivSize / 4);
            encryptedDataWithoutIv.sigBytes -= ivSize;

            try {
                // Decrypt the data using AES-256-CBC mode
                const decryptedBytes = CryptoJS.AES.decrypt(
                    {ciphertext: encryptedDataWithoutIv},
                    keyWordArray,
                    {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
                );

                // Convert the decrypted data to a string
                return decryptedBytes.toString(CryptoJS.enc.Utf8);
            } catch (error) {
                console.error('Decryption error:', error.message);
                throw error;
            }
        } else {
            logout();
        }
    } else {
        return encryptedData;//Return as it is because it's already decrypted.
    }
};

function isNotJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return true;
    }
    return false;
}

export {encryptData, decryptData};
