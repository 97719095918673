import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import english_lang from './translate/en.json'
import russian_lang from './translate/ru.json'

const resources = {
    en: {
        translation: english_lang
    },
    ru: {
        translation: russian_lang
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ru',
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;