import React from 'react'
import {Link, NavLink, Outlet} from "react-router-dom";
import './style.css';
import $ from "jquery";
import i18next from "i18next"
import {Button, Form, Modal} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import Select from "react-select";

class Website extends React.Component {
    constructor(props) {
        let cityCookie = {}
        if (localStorage.getItem('selectedCity') !== null) {
            cityCookie = JSON.parse(localStorage.getItem('selectedCity'));
        }
        super(props);
        this.state = {
            settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
            },
            offset: 0,
            cityCookieValue: cityCookie,
            chooseCityModal: false,
            zipcodeOptions: [],
            zipcodeSelected: "",
            zipcodeErr: "",
        }

        document.title = this.props.pageTitle
        this.openSidebar = this.openSidebar.bind(this)
        this.hideSidebar = this.hideSidebar.bind(this)
        this.chooseCity = this.chooseCity.bind(this)
        this.allowLocation = this.allowLocation.bind(this)
    }

    componentDidMount() {
        window.dataLayer = window.dataLayer || [];
        function gtag() {window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-1S3947VMDW');

        $("#footer").remove()
        $(".header-bar").removeClass("active-nav")
        PostData("/frontCall/getAllCities")
            .then(result => {
                let zipcodeOptions = []
                result.response.map(data => {
                    zipcodeOptions.push({label: data.name, value: data.uuid})
                    if (this.state.cityCookieValue.uuid === data.uuid) {
                        if(this.state.cityCookieValue.contact_us_email !== data.contact_us_email ||
                            this.state.cityCookieValue.contact_us_phone !== data.contact_us_phone ||
                            this.state.cityCookieValue.latitude !== data.latitude ||
                            this.state.cityCookieValue.longitude !== data.longitude ||
                            this.state.cityCookieValue.name !== data.name ||
                            this.state.cityCookieValue.zipcode !== data.zipcode) {
                            this.allowLocation()
                        }
                    }
                })
                this.setState({zipcodeOptions: zipcodeOptions, allCities: result.response})
            })
        if (localStorage.getItem('selectedCity') === null) {
            this.allowLocation()
        }
    }

    openSidebar() {
        $(".header-bar").addClass("active-nav")
    }

    hideSidebar() {
        $(".header-bar").removeClass("active-nav")
    }

    allowLocation() {
        navigator.geolocation.getCurrentPosition((position) => {
            PostData("/frontCall/getAllCities")
                .then(result => {
                    const userPosition = [parseFloat(position.coords.latitude), parseFloat(position.coords.longitude)]; // User's position (latitude, longitude)
                    const positionsArray = result.response;
                    const nearestPosition = this.findNearestPosition(userPosition, positionsArray);
                    const userLocation = positionsArray.filter(data => data.latitude === nearestPosition[0] && data.longitude === nearestPosition[1])[0]
                    this.setCityCookie(userLocation)
                })
        })
    }
    findNearestPosition(userPosition, positionsArray) {
        let nearestPosition = null;
        let nearestDistance = Number.MAX_VALUE;
        for (const position of positionsArray) {
            const positionValue = [position.latitude, position.longitude]
            const distance = this.haversine(userPosition, positionValue);
            if (distance < nearestDistance) {
                nearestDistance = distance;
                nearestPosition = positionValue;
            }
        }
        return nearestPosition;
    }
    haversine(coords1, coords2) {
        const R = 6371; // Earth's radius in kilometers
        const lat1 = coords1[0] * (Math.PI / 180);
        const lon1 = coords1[1] * (Math.PI / 180);
        const lat2 = coords2[0] * (Math.PI / 180);
        const lon2 = coords2[1] * (Math.PI / 180);
        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        // Distance in kilometers
        return R * c;
    }

    chooseCity() {
        if (Object.keys(this.state.cityData).length === 0) {
            this.setState({zipcodeErr: i18next.t("enter_zipcode")})
        } else {
            this.setState({
                chooseCityModal: false,
                zipcodeSelected: [],
                zipcodeErr: "",
            })
            this.setCityCookie(this.state.cityData)
        }
    }

    setCityCookie(userLocation) {
        const selectedCityValue = '{' +
            '"contact_us_email":"' + userLocation.contact_us_email + '",' +
            '"contact_us_phone":"' + userLocation.contact_us_phone + '",' +
            '"latitude":"' + userLocation.latitude + '",' +
            '"longitude":"' + userLocation.longitude + '",' +
            '"name":"' + userLocation.name + '",' +
            '"uuid":"' + userLocation.uuid + '",' +
            '"zipcode":"' + userLocation.zipcode + '"' +
        '}';
        localStorage.setItem('selectedCity', selectedCityValue);
        window.location.reload()
    }

    render() {
        return (<>
            <div id="sticky-top">
                <header className="header-bar light container">
                    <div className="page-logo">
                        <button className="burger-menu-close" onClick={this.hideSidebar}>
                            <img src="/assets/web-images/burger-close-white.svg" alt="Burger Icon"
                                 className="white-icon"/>
                        </button>
                        <Link to={"/"} variant={"btn"} className="logo-image bg-transparent">
                            <img src="/assets/web-images/logo-dark.svg" alt="Header Logo" className="dark-logo"/>
                            <img src="/assets/web-images/logo-white.svg" alt="Header Logo" className="white-logo"/>
                        </Link>
                        <ul className="navigation-menu">
                            <li className="nav-item">
                                <NavLink to={"about"}
                                         className="about_advertising_format nav-link">{i18next.t("about_advertising")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"tariffs"} className="tariffs nav-link">{i18next.t("tariffs")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"promoter"}
                                         className="to_the_promoters nav-link">{i18next.t("home_promoters")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"contacts"} className="contacts nav-link">{i18next.t("contacts")}</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="header-right-nav">
                        <Link to={"/login"} target={"_blank"} className="user-login-icon mr-2">
                            <img src="/assets/web-images/icon-user-dark.svg" alt="User Login"
                                 className="dark-user-icon"/>
                            <img src="/assets/web-images/icon-user-white.svg" alt="User Login"
                                 className="white-user-icon"/>
                        </Link>
                        <Link to={"/login"} target={"_blank"}
                              className="personal-area">{i18next.t("personal_area")}</Link>
                        <span className="choose-zipcode-button" onClick={() => {
                            this.setState({
                                chooseCityModal: true,
                                zipcodeSelected: Object.keys(this.state.cityCookieValue).length > 0
                                    ? {label: this.state.cityCookieValue.name, value: this.state.cityCookieValue.uuid}
                                    : []
                            })
                        }}>
                            <i className="flaticon-381-location-2 mr-2" style={{fontSize: 20}}></i>
                            {Object.keys(this.state.cityCookieValue).length ? this.state.cityCookieValue.name : ""}
                        </span>
                        <span className="language-change-button">
                            <button
                                className={(i18next.language === "ru") ? "ru-button page-link active" : "ru-button page-link"}
                                onClick={() => {
                                    document.cookie = 'lang=ru; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                    window.location.reload()
                                }}>RU</button>
                            <button
                                className={(i18next.language === "en") ? "eng-button page-link active" : "eng-button page-link"}
                                onClick={() => {
                                    document.cookie = 'lang=en; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                    window.location.reload()
                                }}>ENG</button>
                        </span>
                    </div>
                </header>
                <div className="burger-menu">
                    <span className="mobile-zipcode-button" onClick={() => {
                        this.setState({
                            chooseCityModal: true,
                            zipcodeSelected: Object.keys(this.state.cityCookieValue).length > 0
                                ? {label: this.state.cityCookieValue.name, value: this.state.cityCookieValue.uuid}
                                : []
                        })
                    }}>
                        <i className="flaticon-381-location-2 mr-2" style={{fontSize: 20}}></i>
                        {Object.keys(this.state.cityCookieValue).length ? this.state.cityCookieValue.name : ""}
                    </span>
                    <button className="burger-menu-button" onClick={this.openSidebar}>
                        <img src="/assets/web-images/icon-burger-dark.svg" alt="Burger Icon" className="dark-icon"/>
                        <img src="/assets/web-images/icon-burger-white.svg" alt="Burger Icon" className="white-icon"/>
                    </button>
                </div>
            </div>
            <Modal show={this.state.chooseCityModal} centered backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{i18next.t("please_choose_your_city")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="zipcode">
                        <Select defaultValue={this.state.zipcodeSelected}
                                onChange={(zipcodeSelected) => {
                                    this.setState({
                                        zipcodeSelected: zipcodeSelected,
                                        cityData: this.state.allCities.filter(data => data.uuid === zipcodeSelected.value)[0]
                                    })
                                }}
                                options={this.state.zipcodeOptions}
                        />
                        {this.state.zipcodeErr &&
                            <div className="text-danger pl-3 pt-1">{this.state.zipcodeErr}</div>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        this.setState({chooseCityModal: false, zipcode: "", zipcodeErr: "",})
                    }}>{i18next.t("close")}</Button>
                    <Button variant={"primary"} onClick={this.chooseCity}>{i18next.t("confirm")}</Button>
                </Modal.Footer>
            </Modal>

            <div id="main-sections" className="main-sections">
                <Outlet/>
            </div>
        </>)
    }
}

export default Website;