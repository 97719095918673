import React from 'react';
import LeftHeaderLogo from '../LeftHeaderLogo/LeftHeaderLogo';
import {Alert, Button, Dropdown, Form, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import i18next from "i18next";
import moment from "moment/moment";
import 'moment/locale/ru';
import {PostData} from "../../api_key/PostData";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            loginUsername: "",
            loginUserUUID: "",
            newNotification: false,
            allNotifications: [],
            changePasswordModal: false,
            commonAlert: {show: false, variant: "", message: "",},
            changePasswordAlert: {show: false, variant: "", message: "",},
            oldPassword: "",
            oldPasswordErr: "",
            newPassword: "",
            newPasswordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
            backpackChangePassword: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.showHidePassword = this.showHidePassword.bind(this)
        this.changePassword = this.changePassword.bind(this)
    }

    componentDidMount() {
        if (document.cookie.split('; ').find((row) => row.startsWith('backpackChangePassword='))) {
            const backpackChangePassword = document.cookie.split('; ').find((row) => row.startsWith('backpackChangePassword='))
            let backpackChangePasswordSessionData = JSON.parse(backpackChangePassword.replace('backpackChangePassword=', ''));
            if (backpackChangePasswordSessionData[0].change_password === true) {
                this.setState({
                    changePasswordModal: true,
                    backpackChangePassword: true
                });
            }
        }

        if (localStorage.getItem('loginDetails') !== null) {
            let sessionData = JSON.parse(localStorage.getItem('loginDetails'));
            this.setState({
                loginUsername: sessionData.full_name,
                loginUserUUID: sessionData.uuid
            });
        }
       const channel = new BroadcastChannel('backpack-media-fcm-push');
        // Listen for messages from the service worker
        channel.onmessage = (event) => {
            let payload = event.data;
            console.log('Received payload:', payload);
            let currentNotifications = this.state.allNotifications;
            currentNotifications.push(payload);
            this.setState({ newNotification: true, allNotifications: currentNotifications });
        }
        moment.locale(i18next.language);
    }

    changePassword() {
        if (this.state.oldPassword === "") {
            this.setState({oldPasswordErr: i18next.t("enter") + " " + i18next.t("old_password")})
        } else {
            this.setState({oldPasswordErr: ""})
        }
        if (this.state.newPassword === "") {
            this.setState({newPasswordErr: i18next.t("enter") + " " + i18next.t("new_password")})
        } else {
            this.setState({newPasswordErr: ""})
        }
        if (this.state.confirmPassword === "") {
            this.setState({confirmPasswordErr: i18next.t("enter") + " " + i18next.t("confirm_password")})
        } else if (this.state.confirmPassword !== this.state.newPassword) {
            this.setState({confirmPasswordErr: i18next.t("new_and_confirm_are_same")})
        } else {
            this.setState({confirmPasswordErr: ""})
        }

        let formData = {
            current_password: this.state.oldPassword,
            new_password: this.state.newPassword,
        }
        if (formData.current_password !== "" && formData.new_password !== "" && this.state.confirmPassword === formData.new_password) {
            PostData("/frontCall/change_password", formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            changePasswordAlert: {show: true, variant: "success", message: i18next.t("password_updated_successfully")},
                            backpackChangePassword: false,
                        })
                        document.cookie = "backpackChangePassword=;max-age=0;";
                        setTimeout(() => {
                            this.handleClose()
                        }, 2000);
                    } else if (result.status === 400) {
                        this.setState({
                            changePasswordAlert: {show: true, variant: "danger", message: result.messages.message}
                        })
                    } else {
                        this.setState({
                            changePasswordAlert: {show: true, variant: "danger", message: result.message}
                        })
                    }
                })
        }
    }

    showHidePassword(controlId) {
        let password = document.querySelector(controlId)
        let iconId = document.querySelector("[data-iconId='"+controlId+"']")
        if(password.type === "password") {
            password.type = "text"
            iconId.classList.remove("flaticon-381-view-2")
            iconId.classList.add("flaticon-381-hide")
        } else {
            password.type = "password"
            iconId.classList.remove("flaticon-381-hide")
            iconId.classList.add("flaticon-381-view-2")
        }
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleClose() {
        this.setState({
            changePasswordModal: false,
            changePasswordAlert: {
                show: false,
                variant: "",
                message: "",
            },
            oldPassword: "",
            oldPasswordErr: "",
            newPassword: "",
            newPasswordErr: "",
            confirmPassword: "",
            confirmPasswordErr: "",
        })
    }

    render() {
        return (
            <>
                <LeftHeaderLogo/>
                <header className="header" id="header">
                    <div className="header-content">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse justify-content-between">
                                <div className="header-left">
                                    <div className="dashboard_bar">
                                        {this.props.headerTitlePage}
                                    </div>
                                </div>
                                <ListGroup as={"ul"} className="navbar-nav header-right">
                                    <Dropdown as={"li"} className="nav-item dropdown notification_dropdown">
                                        <Dropdown.Toggle as={"a"}
                                                         className="nav-link  ai-icon i-false dropdown-toggle btn">
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
                                                    fill="#3B4CB8"></path>
                                            </svg>
                                            {this.state.newNotification && <div className="pulse-css"></div>}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu widget-media rounded dropdown-menu-right p-3">
                                            <PerfectScrollbar>
                                                <ListGroup as={"ul"} className={"timeline"} style={{maxHeight: "250px"}}>
                                                    {(this.state.allNotifications.length > 0) ? this.state.allNotifications.reverse().map((payload, index) => (
                                                        <ListGroupItem as="li" className="border-0 p-0" key={index}>
                                                            <div className="timeline-panel">
                                                                <div
                                                                    className={`media mr-2 media-${payload.status === 'online' ? 'success' : 'danger'}`}>
                                                                    {parseInt(payload.device_type) === 1 ?
                                                                        <svg id="Слой_1" data-name="Слой 1"
                                                                             xmlns="http://www.w3.org/2000/svg"
                                                                             viewBox="0 0 300 200">
                                                                            <path
                                                                                d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z"
                                                                                transform="translate(-93 -126.49)"/>
                                                                            <path
                                                                                d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z"
                                                                                transform="translate(-93 -126.49)"/>
                                                                            <path className="cls-2"
                                                                                  style={{fill: "#ffffff"}}
                                                                                  d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z"
                                                                                  transform="translate(-93 -126.49)"/>
                                                                            <circle className="cls-2"
                                                                                    style={{fill: "#ffffff"}} cx="168.47"
                                                                                    cy="102.83"
                                                                                    r="51.59"/>
                                                                            <path
                                                                                d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z"
                                                                                transform="translate(-93 -126.49)"/>
                                                                            <circle cx="168.47" cy="91.49" r="27.78"/>
                                                                            <circle className="cls-2"
                                                                                    style={{fill: "#ffffff"}} cx="157.7"
                                                                                    cy="70.51"
                                                                                    r="9.07"/>
                                                                            <circle className="cls-1"
                                                                                    style={{fill: "#ff1d25"}} cx="178.11"
                                                                                    cy="76.18"
                                                                                    r="4.54"/>
                                                                        </svg> :
                                                                        <em className="flaticon-381-smartphone-4"></em>}
                                                                </div>
                                                                <div className="media-body">
                                                                    <h6 className="mb-1">{payload.title}</h6>
                                                                    <small className="d-block">{payload.timestamp}</small>
                                                                </div>
                                                            </div>
                                                        </ListGroupItem>)) :
                                                        <ListGroupItem as="li" className="border-0 p-0">
                                                            <div className="timeline-panel text-center">
                                                                <div className="media-body">
                                                                    <h6 className="m-0">{i18next.t("no_new_notifications")}</h6>
                                                                </div>
                                                            </div>
                                                        </ListGroupItem>
                                                    }
                                                </ListGroup>
                                            </PerfectScrollbar>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={"li"} className="nav-item dropdown header-profile">
                                        <Dropdown.Toggle as={"a"} className="nav-link">
                                            <i className="fa fa-language"></i>
                                            <div className="header-info">
                                                <span className="text-black">
                                                    <strong>{i18next.language}</strong>
                                                </span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={"button"} onClick={() => {
                                                document.cookie = 'lang=en; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                                window.location.reload()
                                            }}>
                                                <span className="ml-2">{i18next.t("english")}</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item as={"button"} onClick={() => {
                                                document.cookie = 'lang=ru; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                                window.location.reload()
                                            }}>
                                                <span className="ml-2">{i18next.t("russian")}</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={"li"} className="nav-item dropdown header-profile">
                                        <Dropdown.Toggle as={"a"} className="nav-link">
                                            <i className="flaticon-381-user-4"></i>
                                            <div className="header-info">
                                                <span className="text-black"><strong>{this.state.loginUsername}</strong></span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={"button"} onClick={() => {
                                                this.setState({changePasswordModal: true})
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 50">
                                                    <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                                                </svg>
                                                <span className="ml-2">{i18next.t("change_password")}</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item as={"button"} onClick={this.props.logoutUser}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-danger"
                                                     width="18" height="18" viewBox="0 0 24 24"
                                                     fill="none" stroke="currentColor" strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                                    <polyline points="16 17 21 12 16 7"></polyline>
                                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                                </svg>
                                                <span className="ml-2">{i18next.t("logout")}</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ListGroup>
                            </div>
                        </nav>
                    </div>
                </header>
                <Modal show={this.state.changePasswordModal} onHide={this.handleClose}
                       centered backdrop="static" keyboard={false}>
                    <Modal.Header className="modal-header">
                        <Modal.Title>{i18next.t("change_password")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={this.state.changePasswordAlert.show} variant={this.state.changePasswordAlert.variant} className="rounded-lg">
                            <h6 className="m-0 font-weight-bold">{this.state.changePasswordAlert.message}</h6>
                        </Alert>
                        <Form>
                            <Form.Group className="mb-3" controlId="oldPassword">
                                <Form.Label>{i18next.t("old_password")}</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type="password" name="oldPassword"
                                                  value={this.state.oldPassword}
                                                  onChange={this.onChangeInput}
                                                  placeholder={i18next.t("old_password")}/>
                                    <Button variant={"primary"} className={"ml-1"} onClick={() => this.showHidePassword("#oldPassword")}>
                                        <i data-iconId={"#oldPassword"} className="flaticon-381-view-2"></i>
                                    </Button>
                                </div>
                                {this.state.oldPasswordErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.oldPasswordErr}</span>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>{i18next.t("new_password")}</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type="password" name="newPassword"
                                                  value={this.state.newPassword}
                                                  onChange={this.onChangeInput}
                                                  placeholder={i18next.t("new_password")}/>
                                    <Button variant={"primary"} className={"ml-1"} onClick={() => this.showHidePassword("#newPassword")}>
                                        <i data-iconId={"#newPassword"} className="flaticon-381-view-2"></i>
                                    </Button>
                                </div>
                                {this.state.newPasswordErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.newPasswordErr}</span>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label>{i18next.t("confirm_password")}</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type="password" name="confirmPassword"
                                                  value={this.state.confirmPassword}
                                                  onChange={this.onChangeInput}
                                                  placeholder={i18next.t("confirm_password")}/>
                                    <Button variant={"primary"} className={"ml-1"} onClick={() => this.showHidePassword("#confirmPassword")}>
                                        <i data-iconId={"#confirmPassword"} className="flaticon-381-view-2"></i>
                                    </Button>
                                </div>
                                {this.state.confirmPasswordErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.confirmPasswordErr}</span>}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {!this.state.backpackChangePassword &&
                            <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>}
                        <Button variant={"primary"} onClick={() => {
                            this.changePassword()
                        }}>{i18next.t("save_changes")}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default Header;