import React from 'react';
import {NavLink} from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import i18next from 'i18next'

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            listSidebarMenu: props.permissionList.map(item => item.permission_key)
        }
        this.hideSidebar = this.hideSidebar.bind(this)
    }

    hideSidebar() {
        if (window.innerWidth < 1300) {
            document.getElementById("main-wrapper").classList.toggle("menu-toggle")
            document.getElementById("hamburger").classList.toggle("is-active")
        }
    }

    render() {
        return (
            <div className="deznav">
                <div className="deznav-scroll">
                    <PerfectScrollbar>
                        <ListGroup as={"ul"} className="metismenu" id="menu">
                            {(this.state.listSidebarMenu.includes('manage_dashboard')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/dashboard" aria-expanded="false">
                                    <i className="flaticon-381-settings-6"></i>
                                    <span className="nav-text">{i18next.t("dashboard")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_devices')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/devices" aria-expanded="false">
                                    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 300 200">
                                        <path
                                            d="M309.38,129.71h-66c-2.73,0-5.93,0-9.48,0-54.29,5.86-81.81,34.92-81.81,86.44v25.67c0,36.51,14.56,61.58,43.3,74.51,24,10.83,52.28,10.8,67.45,10.8h56.61c23.2-5.41,40-28.92,40-56.11V187C359.44,155.41,337,129.71,309.38,129.71Z"
                                            transform="translate(-93 -126.49)"/>
                                        <path
                                            d="M143,241.87V216.2c0-46.29,21.15-76.87,61.56-89.71-35.69,3.83-81.3,18.61-88.24,75.44C102.9,205.13,93,216.08,93,229s9.78,23.79,23.08,27.06c6.75,63.51,63.28,74.53,100.19,76.42a120.08,120.08,0,0,1-24.63-7.82C159.34,310.15,143,282.28,143,241.87ZM105.84,229c0-5.23,3.9-9.87,9.54-12.34v24.69C109.74,238.91,105.84,234.26,105.84,229Z"
                                            transform="translate(-93 -126.49)"/>
                                        <path className="cls-2" style={{fill: "#ffffff"}}
                                              d="M335.89,236c-.95-13.7-1.47-29.89-5.65-45.68-4.48-16.94-13.76-26.23-29.62-26.48-28.85-.47-57.28,3.92-85.16,11.08-15.71,4-28.45,12.37-28.27,31.14.2,21.85,3.69,43.27,12,63.57,7.29,17.87,22.41,26.05,41.81,24.4a519.88,519.88,0,0,0,55.29-7.86C325.66,280.43,336,268.11,335.89,236Z"
                                              transform="translate(-93 -126.49)"/>
                                        <circle className="cls-2" style={{fill: "#ffffff"}} cx="168.47" cy="102.83"
                                                r="51.59"/>
                                        <path
                                            d="M261.47,286.43a57.11,57.11,0,1,1,57.11-57.11A57.18,57.18,0,0,1,261.47,286.43Zm0-103.18a46.08,46.08,0,1,0,46.08,46.07A46.13,46.13,0,0,0,261.47,183.25Z"
                                            transform="translate(-93 -126.49)"/>
                                        <circle cx="168.47" cy="91.49" r="27.78"/>
                                        <circle className="cls-2" style={{fill: "#ffffff"}} cx="157.7" cy="70.51"
                                                r="9.07"/>
                                        <circle className="cls-1" style={{fill: "#ff1d25"}} cx="178.11" cy="76.18"
                                                r="4.54"/>
                                    </svg>
                                    <span className="nav-text">{i18next.t("devices")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_bookings')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/bookings" aria-expanded="false">
                                    <i className="flaticon-381-calendar-6"></i>
                                    <span className="nav-text">{i18next.t("bookings")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_clients')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/clients" aria-expanded="false">
                                    <i className="flaticon-381-user-4"></i>
                                    <span className="nav-text">{i18next.t("clients")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_partners')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/partners" aria-expanded="false">
                                    <i className="flaticon-381-share"></i>
                                    <span className="nav-text">{i18next.t("partners")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_campaigns')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/ad_campaigns" aria-expanded="false">
                                    <i className="flaticon-381-promotion"></i>
                                    <span className="nav-text">{i18next.t("ad_campaigns")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_promotions')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/promotions" aria-expanded="false">
                                    <i className="flaticon-381-smartphone-3"></i>
                                    <span className="nav-text">{i18next.t("promotions")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_cities')) &&
                                <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                    <NavLink className="has-arrow ai-icon" to="/cities" aria-expanded="false">
                                        <i className="flaticon-381-locations"></i>
                                        <span className="nav-text">{i18next.t("cities")}</span>
                                    </NavLink>
                                </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_promoters')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/promoters" aria-expanded="false">
                                    <i className="fa fa-users"></i>
                                    <span className="nav-text">{i18next.t("promoters")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_promoter_routes')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/promoter_route" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            d="M3,4V12.5L6,9.5L9,13C10,14 10,15 10,15V21H14V14C14,14 14,13 13.47,12C12.94,11 12,10 12,10L9,6.58L11.5,4M18,4L13.54,8.47L14,9C14,9 14.93,10 15.47,11C15.68,11.4 15.8,11.79 15.87,12.13L21,7"/>
                                    </svg>
                                    <span className="nav-text">{i18next.t("promoter_route")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_live_tracking')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/live_tracking" aria-expanded="false">
                                    <i className="flaticon-381-location-2"></i>
                                    <span className="nav-text">{i18next.t("live_tracking")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_users')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/users" aria-expanded="false">
                                    <i className="flaticon-381-user"></i>
                                    <span className="nav-text">{i18next.t("users")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_roles')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/roles" aria-expanded="false">
                                    <i className="fa fa-shield"></i>
                                    <span className="nav-text">{i18next.t("roles")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                            {(this.state.listSidebarMenu.includes('manage_apk')) &&
                            <ListGroup.Item as={"li"} className="border-0" onClick={this.hideSidebar}>
                                <NavLink className="has-arrow ai-icon" to="/apk_upload" aria-expanded="false">
                                    <i className="fa fa-upload"></i>
                                    <span className="nav-text">{i18next.t("apk_upload")}</span>
                                </NavLink>
                            </ListGroup.Item>}
                        </ListGroup>
                    </PerfectScrollbar>
                </div>
            </div>
        )
    }
}

export default Sidebar;