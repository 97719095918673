import React from 'react';
import i18next from "i18next";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer" id="footer">
                <div className="copyright">
                    <p>{i18next.t("copyright_backpack_media")}</p>
                </div>
            </footer>
        )
    }
}

export default Footer;