import React from "react";
import {LoginData} from "../../api_key/LoginData";
import {Alert, Dropdown, ListGroup, Spinner, Button, Modal, Form} from "react-bootstrap";
import i18next from "i18next";
import {Navigate} from "react-router-dom";
import {PostData} from "../../api_key/PostData";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail: "",
            loginEmailErr: "",
            loginPassword: "",
            loginPasswordErr: "",
            loginUserAlert: false,
            loginUserAlertVariant: "",
            loginUserAlertMessage: "",
            loginRedirect: false,
            dataLoader: true,
            forgotPasswordModal: false,
            forgotEmailAddress: "",
            forgotEmailAddressErr: "",
            forgotPasswordSuccess: false,
            commonAlert: {
                show: false,
                variant: "",
                message: "",
            },
            clientsLogin: true,
            managementLogin: false,
            partnersLogin: false,
            inlinePreloader: false,
        }
        let html = document.querySelector("html")
        let body = document.querySelector("body")
        let main = document.querySelector("#main-wrapper")
        html.classList.add("h-100")
        body.classList.add("h-100")
        main.classList.add("h-100")

        document.title = this.props.pageTitle
        this.submitLogin = this.submitLogin.bind(this)
        this.submitForgotPassword = this.submitForgotPassword.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    onChangeInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitLogin() {
        this.setState({loginUserAlert: false})
        if (this.state.loginEmail === "") {
            this.setState({loginEmailErr: i18next.t("enter_email")})
        } else if (!this.state.loginEmail.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({loginEmailErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({loginEmailErr: ""})
        }
        if (this.state.loginPassword === "") {
            this.setState({loginPasswordErr: i18next.t("enter_password")})
        } else {
            this.setState({loginPasswordErr: ""})
        }

        let loginUserData = {
            email: this.state.loginEmail,
            password: this.state.loginPassword
        }
        let loginApi = this.state.clientsLogin ? "/frontCall/login" : (this.state.managementLogin ? "/frontCall/users/login" : "/frontCall/partners/login")
        if (loginUserData.email !== "" && loginUserData.password !== "") {
            LoginData(loginApi, loginUserData)
                .then((result) => {
                    if (result.status === true) {
                        let loginUserName = "";
                        loginUserName = result.response.full_name;
                        const loginDetailsValue = '{' +
                            '"full_name":"' + loginUserName + '",' +
                            '"uuid":"' + result.response.uuid + '",' +
                            '"email":"' + result.response.email + '"' +
                        '}';
                        localStorage.setItem('loginDetails', loginDetailsValue);

                        let sessionData = {}
                        if (localStorage.getItem('loginDetails') !== null) {
                            sessionData = JSON.parse(localStorage.getItem('loginDetails'));
                        }
                        this.setState({
                            loginRedirect: true,
                            fullName: sessionData.full_name,
                            loginUserAlert: false,
                            loginUserAlertVariant: "success",
                            loginUserAlertMessage: i18next.t("success"),
                        });
                        window.location = '/dashboard'
                        if (result.response.change_password === true) {
                            document.cookie = 'backpackChangePassword=[{"change_password": ' + result.response.change_password + '}]; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                        }
                    } else if (result.status === 400) {
                        this.setState({
                            loginRedirect: false,
                            loginUserAlert: true,
                            loginUserAlertVariant: "danger",
                            loginUserAlertMessage: result.messages.message,
                        });
                    } else if (result.status === false) {
                        this.setState({
                            loginRedirect: false,
                            loginUserAlert: true,
                            loginUserAlertVariant: "danger",
                            loginUserAlertMessage: i18next.t("invalid_login_or_password"),
                        });
                    }
                })
        }
    }

    submitForgotPassword() {
        this.setState({inlinePreloader: true})
        if (this.state.forgotEmailAddress === "") {
            this.setState({forgotEmailAddressErr: i18next.t("enter_email")})
        } else if (!this.state.forgotEmailAddress.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({forgotEmailAddressErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({forgotEmailAddressErr: ""})
        }

        if (this.state.forgotEmailAddress !== "" && this.state.forgotEmailAddress.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            PostData("/frontCall/forgot_password", {email: this.state.forgotEmailAddress, isUser: this.state.managementLogin})
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            forgotPasswordSuccess: true,
                            commonAlert: {
                                show: true,
                                variant: "white",
                                message: <>
                                    <i className="fa fa-check bg-success text-white rounded-pill text-center fs-32" style={{
                                        width: "60px",
                                        height: "60px",
                                        lineHeight: "60px",
                                    }}></i>
                                    <p className="font-weight-bold mt-2">{i18next.t("email_sent_successfully")}</p>
                                </>,
                            },
                        })
                    } else if (result.status === 400) {
                        this.setState({
                            commonAlert: {
                                show: true,
                                variant: "danger",
                                message: i18next.t("email_not_exists_system"),
                            },
                        })
                    } else {
                        this.setState({
                            commonAlert: {
                                show: true,
                                variant: "danger",
                                message: result.message,
                            },
                        })
                    }
                    this.setState({inlinePreloader: false})
                })
        } else {
            this.setState({inlinePreloader: false})
        }
    }

    render() {
        let cookieDataReplace = localStorage.getItem('loginDetails')
        let requiresLogin = true;
        if (cookieDataReplace !== null) {
            let sessionData = JSON.parse(localStorage.getItem('loginDetails'));
            requiresLogin = !sessionData;
        }
        return (
            <>
                {(!requiresLogin) && <Navigate to="/dashboard" replace={true}/>}
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}
                <div className="authincation h-100">
                    <div className="container h-100">
                        <Modal show={this.state.forgotPasswordModal} centered backdrop="static" keyboard={false}>
                            {!this.state.forgotPasswordSuccess && <>
                                <Modal.Header className="modal-header">
                                    <Modal.Title>{i18next.t("forgot_password")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                    </Alert>
                                    {this.state.inlinePreloader
                                        ? <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/>
                                        : <Form>
                                            <div className="card-tabs mb-3 text-center">
                                                <ul className="nav nav-tabs d-inline-flex" role="tablist">
                                                    <li className="nav-item">
                                                        <Button variant={"transparent"} className={(this.state.clientsLogin && "active") + " nav-link py-2 px-3"}
                                                                onClick={(e) => {
                                                                    document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                        tabMenu.classList.remove("active")
                                                                    })
                                                                    e.currentTarget.classList.add("active")
                                                                    this.setState({clientsLogin: true, managementLogin: false, partnersLogin: false})
                                                                }}>{i18next.t("clients")}</Button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Button variant={"transparent"} className={(this.state.managementLogin && "active") + " nav-link py-2 px-3"}
                                                                onClick={(e) => {
                                                                    document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                        tabMenu.classList.remove("active")
                                                                    })
                                                                    e.currentTarget.classList.add("active")
                                                                    this.setState({clientsLogin: false, managementLogin: true, partnersLogin: false})
                                                                }}>{i18next.t("management")}</Button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Button variant={"transparent"} className={(this.state.partnersLogin && "active") + " nav-link py-2 px-3"}
                                                                onClick={(e) => {
                                                                    document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                        tabMenu.classList.remove("active")
                                                                    })
                                                                    e.currentTarget.classList.add("active")
                                                                    this.setState({clientsLogin: false, managementLogin: false, partnersLogin: true})
                                                                }}>{i18next.t("partners")}</Button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Form.Group className="mb-3" controlId="forgotEmailAddress">
                                                <Form.Label>{i18next.t("e_mail")}</Form.Label>
                                                <Form.Control type="text" name="forgotEmailAddress"
                                                              value={this.state.forgotEmailAddress}
                                                              onChange={this.onChangeInput}
                                                              placeholder={i18next.t("e_mail")}/>
                                                {this.state.forgotEmailAddressErr &&
                                                    <span className="text-danger pl-3 pt-1">{this.state.forgotEmailAddressErr}</span>}
                                            </Form.Group>
                                        </Form>}
                                </Modal.Body>
                            </>}
                            {this.state.forgotPasswordSuccess && <Modal.Body>
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg p-0 m-0">
                                    <h6 className="m-0 font-weight-bold text-center">{this.state.commonAlert.message}</h6>
                                </Alert>
                                <p>{i18next.t("new_temporary_password_sent_to_your_email_address")}</p>
                            </Modal.Body>}
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({
                                        forgotPasswordModal: false,
                                        forgotEmailAddress: "",
                                        forgotEmailAddressErr: "",
                                        forgotPasswordSuccess: false,
                                        inlinePreloader: false,
                                        commonAlert: {show: false, variant: "", message: "",},
                                    })
                                }}>{i18next.t("close")}</Button>
                                {!this.state.forgotPasswordSuccess && <Button onClick={this.submitForgotPassword} className="btn bg-black text-white">
                                    {i18next.t("submit")}
                                </Button>}
                            </Modal.Footer>
                        </Modal>
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <img src="/assets/images/logo-full.svg" alt="" style={{maxWidth: "260px"}}/>
                                                </div>
                                                <h4 className="text-center mb-4 text_primary">{i18next.t("sign_in")}</h4>
                                                <div className="card-tabs mb-3 text-center">
                                                    <ul className="nav nav-tabs d-inline-flex" role="tablist">
                                                        <li className="nav-item">
                                                            <Button variant={"transparent"} className={(this.state.clientsLogin && "active") + " nav-link py-2 px-3"}
                                                               onClick={(e) => {
                                                                   document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                       tabMenu.classList.remove("active")
                                                                   })
                                                                   e.currentTarget.classList.add("active")
                                                                   this.setState({clientsLogin: true, managementLogin: false, partnersLogin: false})
                                                               }}>{i18next.t("clients")}</Button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Button variant={"transparent"} className={(this.state.managementLogin && "active") + " nav-link py-2 px-3"}
                                                               onClick={(e) => {
                                                                   document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                       tabMenu.classList.remove("active")
                                                                   })
                                                                   e.currentTarget.classList.add("active")
                                                                   this.setState({clientsLogin: false, managementLogin: true, partnersLogin: false})
                                                               }}>{i18next.t("management")}</Button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Button variant={"transparent"} className={(this.state.partnersLogin && "active") + " nav-link py-2 px-3"}
                                                               onClick={(e) => {
                                                                   document.querySelectorAll(".nav-link").forEach(tabMenu => {
                                                                       tabMenu.classList.remove("active")
                                                                   })
                                                                   e.currentTarget.classList.add("active")
                                                                   this.setState({clientsLogin: false, managementLogin: false, partnersLogin: true})
                                                               }}>{i18next.t("partners")}</Button>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <Alert show={this.state.loginUserAlert} variant={this.state.loginUserAlertVariant} className="rounded-lg">
                                                    <h6 className="m-0 font-weight-bold">{this.state.loginUserAlertMessage}</h6>
                                                </Alert>
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Label className="mb-1 text_primary font-weight-bold">{i18next.t("e_mail")}</Form.Label>
                                                        <Form.Control type="email" onChange={this.onChangeInput}
                                                                      name="loginEmail" placeholder={i18next.t("e_mail")}/>
                                                        {this.state.loginEmailErr &&
                                                            <div className="text-danger pl-3 pt-1">{this.state.loginEmailErr}</div>}
                                                    </Form.Group>
                                                    <Form.Group controlId={"loginPassword"}>
                                                        <Form.Label className="mb-1 text_primary font-weight-bold">{i18next.t("password")}</Form.Label>
                                                        <div className="d-flex">
                                                            <Form.Control type="password" name="loginPassword"
                                                                          onKeyUp={(e) => {
                                                                              if (e.key === 'Enter') {
                                                                                  e.preventDefault(); // Prevent the default form submission
                                                                                  this.submitLogin();
                                                                              }
                                                                          }}
                                                                          onChange={this.onChangeInput} placeholder={i18next.t("password")}/>
                                                            <Button variant={"primary"} className={"ml-1"} onClick={() => {
                                                                let loginPassword = document.querySelector("#loginPassword")
                                                                let iconId = document.querySelector("[data-iconId='#loginPassword']")
                                                                if(loginPassword.type === "password") {
                                                                    loginPassword.type = "text"
                                                                    iconId.classList.remove("flaticon-381-view-2")
                                                                    iconId.classList.add("flaticon-381-hide")
                                                                } else {
                                                                    loginPassword.type = "password"
                                                                    iconId.classList.remove("flaticon-381-hide")
                                                                    iconId.classList.add("flaticon-381-view-2")
                                                                }
                                                            }}><i data-iconId={"#loginPassword"} className="flaticon-381-view-2"></i></Button>
                                                        </div>
                                                        {this.state.loginPasswordErr &&
                                                            <div className="text-danger pl-3 pt-1">{this.state.loginPasswordErr}</div>}
                                                    </Form.Group>
                                                    <div className="text-right">
                                                        <p>
                                                            <Button variant={"link"} className="p-0" onClick={() => {
                                                                this.setState({forgotPasswordModal: true})
                                                            }} style={{boxShadow: "none"}}>
                                                                {i18next.t("forgot_password")}?
                                                            </Button>
                                                        </p>
                                                    </div>
                                                    <div className="text-center">
                                                        <Button onClick={this.submitLogin} className="btn bg-black text-white btn-block">
                                                            {i18next.t("sign_in")}
                                                        </Button>
                                                    </div>
                                                </Form>
                                                <ListGroup as={"ul"} className="navbar-nav header-right align-items-center mt-3">
                                                    <Dropdown as={"li"} className="nav-item dropdown header-profile">
                                                        <Dropdown.Toggle as={"a"} className="nav-link">
                                                            <i className="fa fa-language"></i>
                                                            <div className="header-info">
                                                                <span className="text-black">
                                                                    <strong>{i18next.language}</strong>
                                                                </span>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={"button"} onClick={() => {
                                                                document.cookie = 'lang=en; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                                                window.location.reload()
                                                            }}>
                                                                <span className="ml-2">{i18next.t("english")}</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={"button"} onClick={() => {
                                                                document.cookie = 'lang=ru; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                                                                window.location.reload()
                                                            }}>
                                                                <span className="ml-2">{i18next.t("russian")}</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;